import styled from "@emotion/styled";
import "@fontsource/roboto";
import "@fontsource/righteous";

export const SectionNotifications = styled.div`
  // position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
  justify-content: center;
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }

  //SCROLL
  max-height: 85vh; /* Defina a altura máxima para ocupar o espaço disponível */
  overflow-y: auto; /* Adiciona rolagem vertical quando necessário */
`;

export const NotificationsFrame = styled.div`
  display: flex;
  width: 80%;
  height: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #fafafa;
  filter: drop-shadow(.5px .5px .5px rgba(0, 0, 0, 0.25));
  border-radius: 15px;
  align-items: center;
  justify-content: start;
`;

export const NotificationImage = styled.img`
  width: 61px;
  height: 61px;
  object-fit: scale-down;
  padding: 10px;
`;

export const NotificationFrameTitleDescription = styled.div`
  padding-left: 25px;
  text-align: left;
`;

export const NotificationTitle = styled.p`
  font-family: "Roboto", sans-serif;
  font-size: 25px;
`;

export const NotificationDescription = styled.p`
  font-family: "Roboto", sans-serif;
  color: grey;
`;
export const NotificationHour = styled.p`
  font-size: 12px;
  color: grey;
  font-family: "Roboto", thin 100;
  padding-top: 65px;
  text-align: right;
  position: absolute;
  right: 0;
  padding-right: 20px;
`;