import React, { useState } from "react";
import Menu from "../../../Menu";
import HeaderTitle from "../../../Header/HeaderTitulo";
import StatusData from "./statusData.json";
import MenuIcon from "@mui/icons-material/Menu";

import { Card1, Title1, StatusText } from "./styles";

const QuadroExaustao = () => {
  

  const CloseMenu = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        height: 80,
      }}
    >
      <button
      
        style={{
          display: "flex",
          width: "100%",
          border: "none",
          boxShadow: "0px 10px 10px -5px rgba(0, 0, 0, 0.15)",
          alignItems: "center",
          backgroundColor: "transparent",
          cursor: "pointer",
        }}
      >
        <MenuIcon />
      </button>
    </div>
  );

  return (
    <div className="main" style={{ display: "flex", flexBasis: "100%" }}>
 
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flexBasis: "100%",
          gap: "2rem",
        }}
      >
        <HeaderTitle title="Quadro de Exaustão" />
        <div
          style={{
            display: "flex",
            gap: "1rem",
            flexDirection: "row",
            padding: "1rem",
          }}
        >
          <Card1>
            <Title1>Status das Ventoinhas Instaladas</Title1>
            <div style={{ padding: "1rem", paddingTop: "2rem" }}>
              <StatusText>
                {StatusData.ventoinha.title}{" "}
                <span style={{ color: StatusData.ventoinha.color }}>
                  {StatusData.ventoinha.status}
                </span>
              </StatusText>
              <StatusText>
                {StatusData.defeito.title}{" "}
                <span style={{ color: StatusData.defeito.color }}>
                  {StatusData.defeito.status}
                </span>
              </StatusText>
              <StatusText>
                {StatusData.fluxo.title}{" "}
                <span style={{ color: StatusData.fluxo.color }}>
                  {StatusData.fluxo.status}
                </span>
              </StatusText>
              <StatusText>
                {StatusData.falhas.title}{" "}
                <span style={{ color: StatusData.falhas.color }}>
                  {StatusData.falhas.status}
                </span>
              </StatusText>
              <StatusText>
                {StatusData.velocidade.title}{" "}
                <span style={{ color: StatusData.velocidade.color }}>
                  {StatusData.velocidade.status}
                </span>
              </StatusText>
            </div>
          </Card1>
        </div>
      </div>
    </div>
  );
};

export default QuadroExaustao;
