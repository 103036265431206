// import logo from './logo.svg';
import "./App.css";
import Menu from "./pages/Menu";
import { BrowserRouter as Router } from "react-router-dom";
import RoutesApp from "./pages/Routes";
import { useState } from "react";

function App() {
  const [openMenu, setOpenMenu] = useState(false);

  const openAndCloseMenu = () => {
    setOpenMenu(!openMenu);
  };

  return (
    <Router>
      <div
        style={{ display: "flex", maxHeight: '100vh', minHeight: '100vh', maxWidth: '100vw', minWidth: '100vw', background: "#F1F1F1" }}
      >
        <Menu />

        <RoutesApp />
      </div>
    </Router>
  );
}

export default App;
