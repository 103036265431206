import React, { useState, useEffect } from "react";
import Menu from "../../../Menu";
import HeaderHome from "../../../Header/HeaderHome";
import ReactApexChart from "react-apexcharts";
import nivelCalor from "./%nivelCalor.json";
import logData from "./logData.json";
import MenuIcon from "@mui/icons-material/Menu";
import {
  StatusCard,
  StatusDot,
  StatusDot2,
  StatusTitle,
  NivelCard,
  NivelTitle,
  ChartWrapper,
  TextWrapper,
  InnerCircle,
  ThirdCircle,
  Card1,
  LogText,
  StatusText,
  LogTitle,
  SwitchCard,
  SwitchContainer,
  SwitchInput,
  SwitchSlider,
  SwitchText,
  TitleSwitch1,
  TitleSwitch2,
  CardChart,
  GraphTitle,
  ChartContainer,
} from "./styles";

const QuadroIncendio = () => {


  const CloseMenu = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        height: 80,
      }}
    >
      <button

        style={{
          display: "flex",
          width: "100%",
          border: "none",
          boxShadow: "0px 10px 10px -5px rgba(0, 0, 0, 0.15)",
          alignItems: "center",
          backgroundColor: "transparent",
          cursor: "pointer",
        }}
      >
        <MenuIcon />
      </button>
    </div>
  );

  const CircleWithNumber = () => {
    const [number, setNumber] = useState(nivelCalor.percent);

    useEffect(() => {
      // Atualize o estado do número quando o valor do arquivo JSON mudar
      setNumber(nivelCalor.percent);
    }, [nivelCalor.percent]);

    const getCircleColor = () => {
      // Verifique o valor do número e retorne a cor correta
      if (parseFloat(number) > 75) {
        return "red";
      }
      if (parseFloat(number) >= 50) {
        return "orange";
      }
      return "#8ef78e";
    };

    return (
      <InnerCircle style={{ background: getCircleColor() }}>
        <ThirdCircle
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "5px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h2
            style={{
              fontFamily: "Roboto",
              fontSize: "20px",
              fontWeight: "bold",
              color: "black",
              paddingBottom: "1rem",
            }}
          >
            Nível de Calor
          </h2>
          <TextWrapper>{number}</TextWrapper>
        </ThirdCircle>
      </InnerCircle>
    );
  };

  const handleAlertSignal = () => {
    setAlert(!alert);
    // Aqui você pode adicionar lógica adicional, como exibir uma mensagem de alerta ou executar outras ações necessárias
  };
  const [alert, setAlert] = useState(false);
  const alertColor = alert ? "#FF0000" : "#d74747";
  const normalColor = alert ? "#58d958" : "#8ef78e";

  const series = [
    {
      name: "Temperaturas Médias",
      data: [26, 30, 25, 33, 22, 35, 29, 28],
      color: "#d60000",
    },
  ];

  const options = {
    chart: {
      height: 245,
      type: "line",
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0.5,
      },
    },
    markers: {
      size: 1,
    },
    xaxis: {
      categories: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago"],
    },
    yaxis: {
      min: 5,
      max: 50,
    },
  };

  const ApexChart = () => {
    const series = [60];
    const options = {
      chart: {
        height: 200,
        width: 200,
        type: "radialBar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 225,
          hollow: {
            margin: 0,
            size: "75%",
            background: "#fff",
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: "front",
            dropShadow: {
              enabled: true,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24,
            },
          },
          track: {
            background: "#fff",
            strokeWidth: "67%",
            margin: 0,
            dropShadow: {
              enabled: true,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35,
            },
          },
          dataLabels: {
            show: true,
            name: {
              offsetY: -10,
              show: true,
              color: "black",
              fontSize: "20px",
            },
            value: {
              formatter: function (val) {
                return parseInt(val);
              },
              color: "#111",
              fontSize: "30px",
              show: true,
            },
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.5,
          gradientToColors: ["#0077B6"],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 80],
        },
      },
      stroke: {
        lineCap: "round",
      },
      labels: ["Nível de O²"],
    };

    return (
      <div id="card">
        <div id="chart">
          <ReactApexChart
            options={options}
            series={series}
            type="radialBar"
            height={220}
            width={200}
          />
        </div>
      </div>
    );
  };
  const ApexChart2 = () => {
    const series = [40];
    const options = {
      chart: {
        height: 220,
        width: 200,
        type: "radialBar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 225,
          hollow: {
            margin: 0,
            size: "75%",
            background: "#fff",
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: "front",
            dropShadow: {
              enabled: true,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24,
            },
          },
          track: {
            background: "#fff",
            strokeWidth: "67%",
            margin: 0,
            dropShadow: {
              enabled: true,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35,
            },
          },
          dataLabels: {
            show: true,
            name: {
              offsetY: -10,
              show: true,
              color: "black",
              fontSize: "20px",
            },
            value: {
              formatter: function (val) {
                return parseInt(val);
              },
              color: "#111",
              fontSize: "30px",
              show: true,
            },
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.5,
          gradientToColors: ["#0C98E1"],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 80],
        },
      },
      stroke: {
        lineCap: "round",
      },
      labels: ["Nível de CO"],
    };

    return (
      <div id="card">
        <div id="chart">
          <ReactApexChart
            options={options}
            series={series}
            type="radialBar"
            height={220}
            width={200}
          />
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
      }}
    >
      <HeaderHome />
      <div
        style={{
          padding: "1rem",
          display: "flex",
          marginBottom: "20px",
          marginTop: "15px",
        }}
      >
        <StatusCard>
          <StatusTitle>Status:</StatusTitle>
          <div>
            <StatusDot color={alertColor} label="Alerta" />
            <StatusDot color={normalColor} label="Normal" />
            <button onClick={handleAlertSignal}>
              {alert ? "Alerta0" : "Alerta1"}
            </button>
          </div>
        </StatusCard>
        <NivelCard>
          <NivelTitle>Nível de detecção de incêndio:</NivelTitle>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ApexChart />
            <ApexChart2 />
            <ChartWrapper>
              {/* <GraphPercentualCo /> */}
              <CircleWithNumber />
            </ChartWrapper>
          </div>
        </NivelCard>
        <Card1>
          <LogTitle>Log:</LogTitle>
          <LogText>
            <StatusText>
              {logData.sistema.title}{" "}
              <span style={{ color: logData.sistema.color }}>
                {logData.sistema.status}
              </span>
            </StatusText>
            <StatusText>
              {logData.alarme.title}{" "}
              <span style={{ color: logData.alarme.color }}>
                {logData.alarme.status}
              </span>
            </StatusText>
            <StatusText>
              {logData.calor.title}{" "}
              <span style={{ color: logData.calor.color }}>
                {logData.calor.status}
              </span>
            </StatusText>
            <StatusText>
              {logData.presencaco.title}{" "}
              <span style={{ color: logData.presencaco.color }}>
                {logData.presencaco.status}
              </span>
            </StatusText>
            <StatusText>
              {logData.ausenciaco.title}{" "}
              <span style={{ color: logData.ausenciaco.color }}>
                {logData.ausenciaco.status}
              </span>
            </StatusText>
            <StatusText>
              {logData.falha.title}{" "}
              <span style={{ color: logData.falha.color }}>
                {logData.falha.status}
              </span>
            </StatusText>
          </LogText>
        </Card1>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "2rem",
          margin: "1rem"
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <SwitchCard>
            <TitleSwitch1>Sistema de Ventilação de Fumaça</TitleSwitch1>
            <SwitchContainer>
              <SwitchInput
                type="checkbox"
                checked={alert}
                onChange={handleAlertSignal}
              />
              <SwitchSlider />
              <SwitchText>{alert ? "Desativar" : "Ativar"}</SwitchText>
            </SwitchContainer>
          </SwitchCard>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <SwitchCard>
            <TitleSwitch2>
              Sistema de Supressão de Incêndio por Gás
            </TitleSwitch2>
            <SwitchContainer>
              <SwitchInput
                type="checkbox"
                checked={alert}
                onChange={handleAlertSignal}
              />
              <SwitchSlider />
              <SwitchText>{alert ? "Desativar" : "Ativar"}</SwitchText>
            </SwitchContainer>
          </SwitchCard>
        </div>
      </div>
      <div>
        <CardChart>
          <GraphTitle>Médias de Temperaturas:</GraphTitle>
          <ChartContainer>
            <ReactApexChart
              options={options}
              series={series}
              type="line"
              height={230}
              width={1300}
            />
          </ChartContainer>
        </CardChart>
      </div>
    </div>
  );
};

export default QuadroIncendio;
