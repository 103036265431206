import React from "react";
import HeaderNotification from "../Header/HeaderNotifications";
import Menu from "../Menu";
import { data } from "./notifications";
import {
  SectionNotifications,
  NotificationsFrame,
  NotificationImage,
  NotificationTitle,
  NotificationDescription,
  NotificationFrameTitleDescription,
  NotificationHour,
} from "./style";

const Notification = () => {
  return (
      <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <HeaderNotification />
        <div style={{ display: "flex", flexDirection: "column"}}>
          <SectionNotifications id="notifications">
            {data.map((notification, index) => (
              <NotificationsFrame key={index}>
                <NotificationImage src={notification.image} />
                <NotificationFrameTitleDescription>
                  <NotificationTitle>{notification.title}</NotificationTitle>
                  <NotificationDescription>
                    {notification.description}
                  </NotificationDescription>
                </NotificationFrameTitleDescription>
                <NotificationHour>{notification.dateHours}</NotificationHour>
              </NotificationsFrame>
            ))}
          </SectionNotifications>
        </div>
      </div>
  );
};

export default Notification;
