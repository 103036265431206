import React, { useState, useEffect } from "react";
import HeaderAddDispositivo from "../../Header/HeaderAddDispositivo";
import Menu from "../../Menu";
import dataSensores from "./sensores";
import statusData from "./statusData.json";
import ReactApexChart from "react-apexcharts";
import GraphPercentualCo from "./GraphPercentualCo";
import percentData from "./%nivelCO.json";
import MenuIcon from "@mui/icons-material/Menu";
import {
  DeviceCard,
  DevicesArea,
  Title,
  StatusCard,
  StatusCard2,
  StatusDot,
  StatusDot2,
  SwitchContainer,
  SwitchInput,
  SwitchSlider,
  SwitchCard,
  SwitchText,
  Card1,
  LogText,
  StatusText,
  Card2,
  ChartWrapper,
  ReportTitle,
  AlertCount,
  TextWrapper,
  InnerCircle,
  ThirdCircle,
  RowDot,
  DotWrapper,
  DotTitle,
  RowDot2,
  DotWrapper2,
  DotTitle2,
} from "./styles";

const CircleWithNumber = () => {
  const [number, setNumber] = useState(percentData.percent);

  useEffect(() => {
    // Atualize o estado do número quando o valor do arquivo JSON mudar
    setNumber(percentData.percent);
  }, [percentData.percent]);

  const getCircleColor = () => {
    // Verifique o valor do número e retorne a cor correta
    if (parseFloat(number) > 0.99) {
      return "#ff4b27";
    }
    return "#00b4d8";
  };

  return (
    <InnerCircle style={{ background: getCircleColor() }}>
      <ThirdCircle>
        <TextWrapper>{number}</TextWrapper>
      </ThirdCircle>
    </InnerCircle>
  );
};

const SensorCo = () => {
  const [hoveredMonth, setHoveredMonth] = useState(null);
  const [alert, setAlert] = useState(false);

  const handleAlertSignal = () => {
    setAlert(!alert);
    // Aqui você pode adicionar lógica adicional, como exibir uma mensagem de alerta ou executar outras ações necessárias
  };

  const [selectedDevice, setSelectedDevice] = useState(null);
  // Lógica para definir as cores com base no estado de alerta
  const alertColor = alert ? "#ff0000" : "#d74747";
  const normalColor = alert ? "#58d958" : "#8ef78e";

  const handleDeviceClick = (device) => {
    setSelectedDevice(device);
  };





  return (
    <div className="main custom-scroll" style={{ display: "flex", flex: 1 }}>
      <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <HeaderAddDispositivo />
        <div style={{ display: "flex", overflow: "auto", padding: '1rem' , gap: '2rem'}}>
          <DevicesArea>
            {dataSensores.map((sensor) => (
              <DeviceCard
                className={selectedDevice === sensor ? "selected" : ""}
                onClick={() => handleDeviceClick(sensor)}
              >
                <Title>{sensor.name}</Title>
                <img src={sensor.image} alt={sensor.name} style={{ maxWidth: "65px", maxHeight: "65px", minHeight: "65px", minWidth: "65px", padding: ".5rem" }} />
              </DeviceCard>
            ))}
          </DevicesArea>

          <div style={{ display: "flex", flex: 1, justifyContent: "space-between" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                flex: .4
              }}
            >
              <StatusCard>
                <RowDot>
                  <DotWrapper
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <DotTitle>Interrupções</DotTitle>
                    <StatusDot color={alertColor} label="Alerta" />
                  </DotWrapper>
                  <DotWrapper
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <DotTitle>Normal</DotTitle>
                    <StatusDot color={normalColor} label="Normal" />
                  </DotWrapper>
                </RowDot>
              </StatusCard>

              <StatusCard2>
                <RowDot2>
                  <DotWrapper2
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <DotTitle2>Desativado</DotTitle2>
                    <StatusDot2 color={alertColor} label="Alerta" />
                  </DotWrapper2>
                  <DotWrapper2
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <DotTitle2>Ativado</DotTitle2>
                    <StatusDot2 color={normalColor} label="Normal" />
                  </DotWrapper2>
                </RowDot2>
              </StatusCard2>

              <SwitchCard>
                <SwitchContainer>
                  <SwitchInput
                    type="checkbox"
                    checked={alert}
                    onChange={handleAlertSignal}
                  />
                  <SwitchSlider />
                  <SwitchText>{alert ? "Desativar" : "Ativar"}</SwitchText>
                </SwitchContainer>
              </SwitchCard>
              <Card1>
                <LogText>
                  Log:
                  <StatusText>
                    {statusData.sistema.title}{" "}
                    <span style={{ color: statusData.sistema.color }}>
                      {statusData.sistema.status}
                    </span>
                  </StatusText>
                  <StatusText>
                    {statusData.alarme.title}{" "}
                    <span style={{ color: statusData.alarme.color }}>
                      {statusData.alarme.status}
                    </span>
                  </StatusText>
                  <StatusText>
                    {statusData.intruso.title}{" "}
                    <span style={{ color: statusData.intruso.color }}>
                      {statusData.intruso.status}
                    </span>
                  </StatusText>
                  <StatusText>
                    {statusData.falha.title}{" "}
                    <span style={{ color: statusData.falha.color }}>
                      {statusData.falha.status}
                    </span>
                  </StatusText>
                </LogText>
              </Card1>
            </div>

            <Card2>
              <ReportTitle>Percentual de CO</ReportTitle>
              <CircleWithNumber />
            </Card2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SensorCo;
