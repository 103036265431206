import React from "react";
import styled from "styled-components";
import LogoNewin from "../../assets/img/logo/logonewincinza.png";

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-image: url(${LogoNewin});
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
  align-items: center;
  justify-content: center;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 1145px;
  height: 60px;
  background: #f9f9f9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  margin-bottom: 20px;
  padding-left: 20px;
`;

export const TextInput = styled.input`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 10px;
  padding: 10px;
  border: none;
  outline: none;
  background: transparent;
  font-size: 20px;
  font-family: "Righteous";
  color: #797979;
`;

export const Button = styled.button`
  width: 50%;
  height: 44px;
  margin-top: 10rem;
  background: #0056a3;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  color: white;
  font-size: 20px;
  font-family: "Righteous", sans-serif;
  border: none;
  cursor: pointer;

  &:hover {
    background: #007ff1;
  }
`;
