import { } from "@mui/material";
import styled from "styled-components";
import {  } from "../../SensorNivelAgua/style";


export const Container= styled.div`
border-radius: 10px;
width: 870px;
height: 442px;
background-color: white;
box-shadow: 0px 1px 9px -7px rgb(49 49 49);
`;

export const InnerCircle = styled.div`
  display: flex;
  width: 120px;
  height: 120px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
   margin-top:17px; 
   margin-right: 10px;
   margin-left: 30px;
   
`;
export const ThirdCircle = styled.div`
  display: flex;
  width: 90px;
  height: 90px;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
 
  
`;
export const TextWrapper = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  
  color: black;
`;
export const ChartWrapper = styled.div`
  width: 870px;
  height: 350px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 1px 9px -7px rgb(49 49 49);
`;
export const InnerCircle2 = styled.div`
  display: flex;
  width: 120px;
  height: 120px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
   margin-top:17px; 
   margin-left: 60px;
   
`;
export const ThirdCircle2 = styled.div`
  display: flex;
  width: 90px;
  height: 90px;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
`;

export const InnerCircle3 = styled.div`
  display: flex;
  width: 120px;
  height: 120px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
   margin-top:17px; 
   margin-left: 60px;
   
`;
export const ThirdCircle3 = styled.div`
  display: flex;
  width: 90px;
  height: 90px;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
`;

export const InnerCircle4 = styled.div`
  display: flex;
  width: 120px;
  height: 120px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
   margin-top:17px; 
   margin-left: 90px;
  
   
`;
export const ThirdCircle4 = styled.div`
  display: flex;
  width: 90px;
  height: 90px;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
`;


export const ChartDispositivos = styled.div`

  width: 700px;
  height: 442px;
  box-shadow: 0px 1px 9px -7px rgb(49 49 49);
  border-radius: 10px;
  background: #FFFFFF;
  margin-left: 10px;
  
`;
export const VerTodosContainer = styled.div`
  align-self: flex-end;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
 
  
   
`;
export const VerTodosText = styled.span`
  margin-right: 0;
`;
export const Container2= styled.div`
border-radius: 10px;
width: 700px;
height: 350px;
background-color: white;
box-shadow: 0px 1px 9px -7px rgb(49 49 49);
margin-left: 10px;
justify-content:space-around;
`;

export const Reports = styled.div`
text-align:left;
padding-left:1rem;
line-height:2rem;
font-size:20px;
color:red;
font-family:"Roboto";
`;

export const Title1 = styled.div`
  display: flex;
  justify-content: left;
  /* padding: 1rem; */
  padding:1rem;
  font-size: 23px;
  font-family:"Roboto";
  font-weight:bold;
  width:90%;
`;

export const PTytle=styled.div`
display: flex;
justify-content: left;
line-height:1;
font-size:18px;
color:black;
font-family:"Roboto";

`;

export const Circle = styled.div`
width:100px;
height:100px;
color: ${props => props.active ? 'red' : 'black'};
border-radius:50%;
border: 10px solid #00B4D8 ; 
display:flex;
align-items:center;
justify-content:center;
cursor: pointer;
`;