import styled, { keyframes, css } from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";

export const LogoSeparator = styled.hr`
  border: none;
  border-top: 1px solid #d2d2d2;
`;

export const SideMenu = styled.div`
  background-color: #fff;
  transition: ease 400ms;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 2rem 0;
  gap: 3rem;
  border-right: 1px solid #eee;
  transition: ease 400ms;
  box-shadow: 3px 0 7px -13px rgb(49 49 49);

  ${({ openMenu }) => css`
    min-width: ${openMenu ? "12rem" : "6rem"};
  `}
`;

export const ContainerImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
`;

export const LinkRoute = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-left-color: transparent;
  border-left-style: solid;
  border-right-color: transparent;
  border-right-style: solid;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  display: flex;
  text-decoration: none;
  color: #808080;

  ${({ routeSelected }) =>
    routeSelected
      ? css`
          color: #0056a3;
          background-color: #0056a308;
          border-left-color: #0056a3;
        `
      : ""};

  ${({ openMenu }) =>
    openMenu
      ? css`
          justify-content: center;
        `
      : ""};
`;

export const ContainerRoutes = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex: 1;

  div.containerIcons {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
`;

export const ImageLogo = styled.img`
  width: 40px;
  height: 40px;
`;

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #f0f0f0;
  }
`;

export const MenuItemText = styled.span`
  margin-left: 8px;

  font-family: Roboto, sans-serif;
  font-style: normal;
  font-size: 18px;

  ${({ darkMode }) =>
    darkMode &&
    css`
      color: #9f9f9f;
    `}
`;

export const ExpandIcon = styled.div`
  margin-left: auto;
  color: #9f9f9f;
`;

export const SubMenu = styled.div`
  margin-left: 16px;
  padding-top: 8px;
`;

export const SubMenuItem = styled.div`
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
  color: ${({ clicked }) => (clicked ? "#004D93" : "#161616")};
  text-decoration: none;

  &:hover {
    background-color: #f0f0f0;
  }

  ${({ darkMode }) =>
    darkMode &&
    css`
      color: #9f9f9f;
    `}
`;

export const BottomIconsContainer = styled.div`
  position: fixed;
  bottom: 20px;
  left: 20px;
  display: flex;
  flex-direction: column;
`;

export const BottomIcon = styled.div`
  margin-top: 10px;
  cursor: pointer;

  svg {
    color: #333333;
    font-size: 24px;
  }
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
  width: 100%;
  padding: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: end;
  color: ${(props) => (props.darkMode ? "#CCCCCC" : "#B8B8B8")};

  &:hover {
    color: #161616;
  }
`;

// export const styles = {
//   image: { width: 45, height: 45 },

//   containerIcons: {

//   },

//   link: { textDecoration: none, color: #808080 },

//   containerRouterItems: {
//     display: flex,
//     flexDirection: column,
//     gap: .5rem,
//   },

//   iconSelectedContainerFechado: {
//     width: 100%,
//     height: 40,
//     display: flex,
//     alignItems: center,
//     justifyContent: center,
//     borderLeftColor: #0056a3,
//     borderLeftStyle: solid,
//     borderRightColor: transparent,
//     borderRightStyle: solid,
//     backgroundColor: #0056a308,
//     gap: .5rem,
//     padding: 0 0 0 1rem,
//   },

// };
