import "@fontsource/roboto";
import styled from "styled-components";


export const StatusCard = styled.div`
  display: flex;
  background: #fff;
  box-shadow: 0px 1px 9px -7px rgb(49 49 49);
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  margin-right:30px;

`;

export const StatusTitle = styled.h2`
    font-family:"Roboto";
    padding-bottom:5rem;
    padding-left:1rem;
    text-align:left;
`;

export const StatusDot = styled.span`
  font-family: "Roboto";
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
  margin-left: 30px;
  box-shadow: 0 0 8px -5px black;
  text-align: center;
  font-size: 30px;
  position: relative;

  &::before {
    content: "${(props) => props.label}";
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 20px;
    font-weight: 300;
  }
`;
export const StatusDot2 = styled.span`
  font-family: "Roboto";
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom:40px;
  border: 5px solid #353535;
  text-align: center;
  font-size: 20px;
  position: relative;

  &::before {
    content: "${(props) => props.label}";
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 20px;
    font-weight: 300;
  }
`;

export const NivelCard = styled.div`
   width:700px;
   height: 350px;
   background: #fff;
   box-shadow: 0px 1px 9px -7px rgb(49 49 49);
   border-radius: 10px;
   padding: 10px;
   margin-right:30px;

`;

export const NivelTitle = styled.h2`
    font-family:"Roboto";
    padding-bottom:2rem;
    padding-left:1rem;
    text-align:left;
`;


export const ChartWrapper = styled.div`
  /* display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0px 1px 9px -7px rgb(49 49 49);
  border: 1px solid red; */
`;


export const InnerCircle = styled.div`
  display: flex;
  width: 158px;
  height: 158px;
  align-items: center;
  justify-content: center;
  // background: #00b4d8;
  border-radius: 50%;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  margin-left:20px;
  /* margin-top:17px; */
`;

export const ThirdCircle = styled.div`
  display: flex;
  width: 130px;
  height: 130px;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
`;

export const TextWrapper = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 20px;
  text-align: center;
  color: black;
`;

// Card 1
export const Card1 = styled.div`
  width: 450px;
  height: 370px;
  background: white;
  box-shadow: 0px 1px 9px -7px rgb(49 49 49);
  border-radius: 10px;
`;

export const LogText = styled.div`
  font-family: "Roboto";
  font-size: 23px;
  line-height: 27px;
  text-align: center;
  color: #000000;
  padding-left:15px;
`;

export const LogTitle = styled.h2`
    font-family:"Roboto";
    padding-bottom:2rem;
    padding-left:1rem;
    text-align:left;
    font-size:26px;
`;

export const StatusText = styled.div`
  width: 270px;
  height: 15px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 15px;
  text-align: left;
  color: #000000;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const TitleSwitch1 = styled.h2`
    font-family:"Roboto";
    padding: 1rem;
    margin-bottom: 5rem;
    text-align:left;
    font-size:20px;
`;


export const TitleSwitch2 = styled.h2`
    font-family:"Roboto";
    padding: 1rem;
    margin-bottom: 5rem;
    text-align:left;
    font-size:20px;
`;

export const SwitchCard = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: white;
  box-shadow: 0px 1px 9px -7px rgb(49 49 49);
  border-radius: 10px;

`;

export const SwitchContainer = styled.label`
  position: fixed;
  display: flex;
  justify-content:space-around;
  align-items:center;
  width: 82px;
  height: 36px;
`;

export const SwitchInput = styled.input`
  display: none;

  &:checked + span:before {
    transform: translateX(46px);
    background-color: #8ef78e;
  }
`;

export const SwitchSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #353535;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 36px;

  &:before {
    position: absolute;
    content: "";
    height: 28px;
    width: 28px;
    left: 4px;
    bottom: 4px;
    background-color: #d74747;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }

  &:hover:before {
    background-color: #d74747;
  }
`;

export const SwitchText = styled.span`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 15px;
  text-align: left;
  color: #000000;
  margin-left: -200px;
`;

export const CardChart = styled.div`
  width: 1500px;
  height: 290px;
  background: white;
  box-shadow: 0px 1px 9px -7px rgb(49 49 49);
  border-radius: 10px;
  margin-left:15px;

`;

export const GraphTitle = styled.h1`
  padding-top: 10px;
  padding-left: 35px;
  display: flex;
  align-items: left;
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  font-size: 23px;
  line-height: 27px;
  color: #000000;
`;

export const ChartContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 65%;
`;
