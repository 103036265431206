import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import variablePie from "highcharts/modules/variable-pie";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  ChartWrapper,
  Chart,
  CardDispositivos,
  ChartDispositivos,
  VerTodosContainer,
  VerTodosText,
  StatusCard,
  StatusTitle,
  StatusDot,
  RowDot,
  DotTitle,
  DotWrapper,
  ChartTitle,
} from "./styles";

variablePie(Highcharts);

const DashboardApartamentos = () => {
  const [hoveredMonth, setHoveredMonth] = useState(null);
  const [alert, setAlert] = useState(false);
  const [isReady, setIsReady] = useState(false);

  const handleAlertSignal = () => {
    setAlert(!alert);
    // Aqui você pode adicionar lógica adicional, como exibir uma mensagem de alerta ou executar outras ações necessárias
  };

  // Lógica para definir as cores com base no estado de alerta
  const alertColor = alert ? "#FF0000" : "#d74747";
  const normalColor = alert ? "#58d958" : "#8ef78e";

  useEffect(() => {
    Highcharts.setOptions({
      lang: {
        thousandsSep: ",",
      },
    });
  }, []);

  const data = [
    { month: "Jan", alerts: 37 },
    { month: "Feb", alerts: 31 },
    { month: "Mar", alerts: 27 },
    { month: "Apr", alerts: 22 },
    { month: "May", alerts: 21 },
    { month: "Jun", alerts: 21 },
    { month: "Jul", alerts: 21 },
    { month: "Aug", alerts: 1 },
    { month: "Sep", alerts: 20 },
    { month: "Oct", alerts: 19 },
    { month: "Nov", alerts: 16 },
    { month: "Dec", alerts: 16 },
  ];

  const monthNames = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  const optionsApartamentos = {
    chart: {
      type: "column",
      borderRadius: 8,
      style: {
        width: "100%",
      },
    },
    title: {
      text: "",
      style: {
        fontFamily: "Roboto, sans-serif",
        fontStyle: "normal",
        fontWeight: 550,
        fontSize: "23px",
      },
    },
    credits: {
      enabled: false, // Remover a marca "highcharts.com"
    },
    xAxis: {
      type: "category",
      lineWidth: 0,
      tickLength: 0,
      labels: {
        rotation: 0,
        style: {
          fontSize: "13px",
          fontFamily: "Roboto, sans-serif",
        },
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "Alertas Mensais",
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      formatter: function () {
        const { point } = this;
        const monthData = data[point.index];
        const monthName = monthNames[point.index];
        return `Alertas de dispositivos no mês de ${monthName}: <b>${monthData.alerts}</b>`;
      },
    },
    plotOptions: {
      series: {
        point: {
          events: {
            mouseOver: function () {
              setHoveredMonth(monthNames[this.index]);
            },
            mouseOut: function () {
              setHoveredMonth(null);
            },
          },
        },
        borderRadius: 8,
      },
    },
    series: [
      {
        name: "Alertas Mensais",
        colors: data.map((item) =>
          item.alerts === Math.max(...data.map((item) => item.alerts))
            ? "#0077B6"
            : "#E9E9E9"
        ),
        colorByPoint: true,
        groupPadding: 0,
        data: data.map((item) => [item.month, item.alerts]),
        dataLabels: {
          enabled: true,
          rotation: -90,
          color: "#FFFFFF",
          align: "right",
          format: "{point.y:.0f}",
          y: 10,
          style: {
            fontSize: "13px",
            fontFamily: "Roboto, sans-serif",
          },
        },
      },
    ],
  };

  const optionsPopulation = {
    chart: {
      type: "pie",
    },
    title: {
      text: "",
      align: "left",
      style: {
        fontFamily: "Roboto, sans-serif",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "23px",
      },
    },
    tooltip: {
      headerFormat: "",
      pointFormat:
        '<span style="color:{point.color}">\u25CF</span> <b> {point.name}</b><br/>' +
        "Número de dispositivos: <b>{point.y}</b><br/>" +
        "Porcentagem: <b>{point.percentage:.1f}%</b><br/>",
    },
    series: [
      {
        minPointSize: 10,
        innerSize: "20%",
        zMin: 0,
        name: "countries",
        borderRadius: 5,
        data: [
          { name: "HID Porta", y: 598 },
          { name: "Sensores de Gás", y: 255 },
          { name: "Teclado de Porta", y: 600 },
          { name: "Hidrômetros", y: 497 },
        ],
        colors: ["#2C73D2", "#008E9B", "#0089BA", "#0081CF"],
      },
    ],
    credits: {
      enabled: false, // Remover a marca "highcharts.com"
    },
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexBasis: "100%",
        gap: "1rem",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "40%",
          zIndex: "1",
          gap: "1rem",
        }}
      >
        <ChartDispositivos>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h3 className="weight--500">Percentual de Dispositivos dos Apartamentos</h3>
            <VerTodosContainer>
              <h5 className="nowrap">Ver todos</h5>
              <ArrowForwardIosIcon style={{ height: "15px" }}>
                &rarr;
              </ArrowForwardIosIcon>
            </VerTodosContainer>
          </div>

          <div style={{ flexBasis: "100%" }}>
            <HighchartsReact
              highcharts={Highcharts}
              options={optionsPopulation}
            />
          </div>
        </ChartDispositivos>
        <StatusCard>
          <h3 className="weight--500">Status dos Dispositivos</h3>
          <RowDot>
            <DotWrapper style={{ display: "flex", flexDirection: "column" }}>
              <h5 className="color--grey text--center">Alerta</h5>
              <StatusDot color={alertColor} label="Alerta" />
            </DotWrapper>
            <DotWrapper style={{ display: "flex", flexDirection: "column" }}>
              <h5 className="color--grey text--center">Normal</h5>
              <StatusDot color={normalColor} label="Normal" />
            </DotWrapper>
            <button
              onClick={handleAlertSignal}
              style={{ border: "1px solid #eee", background: "whitesmoke", padding: ".5rem 1rem", borderRadius: "1rem", position: "absolute", right: "0", bottom: "0", cursor: "pointer" }}
            >
              {"Alerta"}
            </button>
          </RowDot>
        </StatusCard>
      </div>
      <ChartWrapper>
        <h3 className="weight--500">Percentual de Alertas Mensais</h3>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            flexBasis: "100%",
          }}
        >
          <HighchartsReact
            highcharts={Highcharts}
            options={optionsApartamentos}
            width={"100%"}
          />
        </div>
      </ChartWrapper>
    </div>
  );
};

export default DashboardApartamentos;
