import "@fontsource/roboto";
import styled from "styled-components";


export const DivPai = styled.div`
width:1675px;
margin-left:240px;
`;

export const DivSuperior = styled.div`
  margin-top:120px;
  display:flex;
  align-items:center;
  justify-content:center;
`;

export const DivInferior = styled.div`
  display:flex;
  align-items:center;
  justify-content:center;
  margin-top:35px;
`;

export const ChartWrapper = styled.div`
  /* position: absolute;
  top: 159px;
  right: 20px;
  */
  width: 630px;
  height: 410px; 
  background: white;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

`;

export const Chart = styled.div`
  width: 600px;
`;


// Card 
export const Card = styled.div`
  width: 700px;
  height: 410px;
  background: white;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  text-align:left;
  margin-left:35px;
`;

/* Relatório */
export const ReportTitle = styled.div`

  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  font-size: 23px;
  line-height: 27px;
  color: #000000;
  padding-bottom:10px;
`;

/*Alarme Ativado: senha correta */
export const ReportText1 = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 200;
  font-size: 20px;
  line-height: 23px;
  color: grey;
  padding-bottom:5px;
  `;

/*Alarme Ativado: senha correta */
export const ReportText2 = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 200;
  font-size: 20px;
  line-height: 23px;
  color: grey;
  padding-bottom:5px;
`;
/*Alarme Ativado: senha correta */
export const ReportText3 = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 200;
  font-size: 20px;
  line-height: 23px;
  color: grey;
  padding-bottom:5px;
`;

export const ChartContainer = styled.div`
    background-color:white;
    width:420px;
    border-radius:15px;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.25);
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    margin-left:30px;

`;

export const ChartTitle = styled.p`
font-size:23px;
text-align:center;
color:#333333;

`;