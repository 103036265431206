export const data = {
  "co": {
    "name": "Sensores de CO",
    "description": "578",
    "image": require("../../../assets/img/devices/sensorCO.png"),
  },
  "din": {
    "name": "DIN com Expensão",
    "description": "728",
    "image": require("../../../assets/img/devices/DIN.png"),
  },
  "perimetro": {
    "name": "Sensores Perimetrais",
    "description": "578",
    "image": require("../../../assets/img/devices/sensorPerimetral.png"),
  },
  "reservatorio": {
    "name": "Sensores de Reservatórios",
    "description": "578",
    "image": require("../../../assets/img/devices/sensorReserv.png"),
  },
}