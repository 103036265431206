export const dataDevices = {
  "hid": {
    "name": "HID Porta",
    "info": "598",
    "imagem": require("../../../assets/img/devices/HID.png")
  },
  "sensorgas": {
    "name": "Sensor de Gás ",
    "info": "255",
    "imagem": require("../../../assets/img/devices/SensorGas.png"),
  },
  "tecladoporta": {
    "name": "Teclado de Porta",
    "info": "600",
    "imagem": require("../../../assets/img/devices/tecladoporta.png"),
  },
  "hidrometro": {
    "name": "Hidrômetros",
    "info": "497",
    "imagem": require("../../../assets/img/devices/hidrometros.png"),
  },
}