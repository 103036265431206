import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HeaderAddDispositivo from "../../Header/HeaderAddDispositivo";
import Menu from "../../Menu";
import MenuIcon from "@mui/icons-material/Menu";
import {
  DeviceCard,
  DevicesArea,
  Title,
  StatusCard,
  StatusDot,
  StatusCard2,
  StatusDot2,
  Chart,
  ChartWrapper,
  ReportTitle,
  ReportText1,
  ReportText2,
  ReportText3,
  Card3,
  AlertCount,
  GraphTitle,
  Card4,
  SwitchContainer,
  SwitchInput,
  SwitchSlider,
  SwitchCard,
  SwitchText,
  ChartContainer,
  RowDot,
  DotWrapper,
  DotTitle,
  RowDot2,
  DotWrapper2,
  DotTitle2,
} from "./styles";
import ReactApexChart from "react-apexcharts";
import reportData from "./reportData.json";
import { Height } from "@mui/icons-material";

function BotaoPNE() {
  const [hoveredMonth, setHoveredMonth] = useState(null);
  const [alert, setAlert] = useState(false);

  const handleAlertSignal = () => {
    setAlert(!alert);
    // Aqui você pode adicionar lógica adicional, como exibir uma mensagem de alerta ou executar outras ações necessárias
  };

  const [selectedDevice, setSelectedDevice] = useState(null);

  // Lógica para definir as cores com base no estado de alerta
  const alertColor = alert ? "#FF0000" : "#d74747";
  const normalColor = alert ? "#58d958" : "#7cf77c";

  const handleDeviceClick = (sensor) => {
    setSelectedDevice(sensor);
  };

  useEffect(() => {
    Highcharts.setOptions({
      lang: {
        thousandsSep: ",",
      },
    });
  }, []);

  const data = [
    { month: "Jan", alerts: 37 },
    { month: "Feb", alerts: 31 },
    { month: "Mar", alerts: 27 },
    { month: "Apr", alerts: 22 },
    { month: "May", alerts: 21 },
    { month: "Jun", alerts: 21 },
    { month: "Jul", alerts: 21 },
    { month: "Aug", alerts: 1 },
    { month: "Sep", alerts: 20 },
    { month: "Oct", alerts: 19 },
    { month: "Nov", alerts: 16 },
    { month: "Dec", alerts: 16 },
  ];

  const monthNames = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  const optionsApartamentos = {
    chart: {
      type: "column",
      borderRadius: 8,
      height: "358px",
      position: null,
    },
    title: {
      text: "",
      style: {
        fontFamily: "Roboto, sans-serif",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "23px",
      },
      margin: 100,
    },
    credits: {
      enabled: false, // Remover a marca "highcharts.com"
    },
    xAxis: {
      type: "category",
      lineWidth: 0,
      tickLength: 0,
      labels: {
        rotation: 0,
        style: {
          fontSize: "13px",
          fontFamily: "Roboto, sans-serif",
        },
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "Alertas Mensais",
        enabled: false,
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      formatter: function () {
        const { point } = this;
        const monthData = data[point.index];
        const monthName = monthNames[point.index];
        return `Alertas de dispositivos no mês de ${monthName}: <b>${monthData.alerts}</b>`;
      },
    },
    plotOptions: {
      series: {
        point: {
          events: {
            mouseOver: function () {
              setHoveredMonth(monthNames[this.index]);
            },
            mouseOut: function () {
              setHoveredMonth(null);
            },
          },
        },
        borderRadius: 8,
      },
    },
    series: [
      {
        name: "Alertas Mensais",
        colors: data.map((item) =>
          item.alerts === Math.max(...data.map((item) => item.alerts))
            ? "#DB3030"
            : "#E9E9E9"
        ),
        colorByPoint: true,
        groupPadding: 0,
        data: data.map((item) => [item.month, item.alerts]),
        dataLabels: {
          enabled: true,
          rotation: -90,
          color: "#FFFFFF",
          align: "right",
          format: "{point.y:.0f}",
          y: 10,
          style: {
            fontSize: "13px",
            fontFamily: "Roboto, sans-serif",
          },
        },
      },
    ],
  };

  const series = [
    {
      name: "Alarmes",
      data: [16, 18, 20, 22, 19, 17, 25],
      color: "#DB3030",
    },
  ];

  const options = {
    chart: {
      height: 245,
      type: "line",
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0.5,
      },
    },
    markers: {
      size: 1,
    },
    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
    },
    yaxis: {
      min: 5,
      max: 40,
    },
  };

  const CloseMenu = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        height: 80,
      }}
    >
      <button
        style={{
          display: "flex",
          width: "100%",
          border: "none",
          boxShadow: "0px 10px 10px -5px rgba(0, 0, 0, 0.15)",
          alignItems: "center",
          backgroundColor: "transparent",
          cursor: "pointer",
        }}
      >
        <MenuIcon />
      </button>
    </div>
  );

  return (
    <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
      <HeaderAddDispositivo />
      <div
        style={{
          display: "flex",
          flex: "1",
          padding: "2rem",
          overflow: "auto",
          gap: "2rem",
          flexDirection: "column",
        }}
      >
        <div className="flex gap--2 basis--5">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              flex: "1",
            }}
          >
            <div className="back--white flex gap--2 justify--center padding--1-2 border-radius--05 shadow--card border--light basis--10">
              <div
                className="flex column gap--25 align--center"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <DotTitle>Interrupções</DotTitle>
                <StatusDot color={alertColor} label="Alerta" />
              </div>
              <div
                className="flex column gap--25 align--center"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <DotTitle>Normal</DotTitle>
                <StatusDot color={normalColor} label="Normal" />
              </div>
            </div>
            <div className="back--white flex gap--2 justify--center padding--1-2 border-radius--05 shadow--card border--light basis--10">
              <div
                className="flex column gap--25 align--center"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <DotTitle2>Desativado</DotTitle2>
                <StatusDot2 color={alertColor} label="Alerta" />
              </div>
              <div
                className="flex column gap--25 align--center"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <DotTitle2>Ativado</DotTitle2>
                <StatusDot2 color={normalColor} label="Normal" />
              </div>
            </div>
            <SwitchCard>
              <SwitchContainer>
                <SwitchInput
                  type="checkbox"
                  checked={alert}
                  onChange={handleAlertSignal}
                />
                <SwitchSlider />
                <SwitchText>{alert ? "Desativar" : "Ativar"}</SwitchText>
              </SwitchContainer>
            </SwitchCard>
          </div>
          <Card4>
            <h3 className="weight--500">
              Quantidade de estados Alarme mensalmente:
            </h3>
            <ChartContainer>
              <ReactApexChart
                options={options}
                series={series}
                type="line"
                height={350}
                width={400}
              />
            </ChartContainer>
          </Card4>
        </div>
        <div className="flex gap--2 basis--5">
          <div className="flex column gap--1 basis--30 back--white border-radius--05 padding--1-2">
            <h3 className="weight--500">
              Quantidade de Alertas Gerais Mensais
            </h3>
            <HighchartsReact
              highcharts={Highcharts}
              options={optionsApartamentos}
            />
          </div>
          <Card3>
            <div className="flex justify--between align--center">
              <h3 className="weight--500">Relatório:</h3>
              <AlertCount>{reportData.alertCount}</AlertCount>
            </div>
            <div className="flex column gap--05">
              <p>{reportData.reportText1}</p>
              <p>{reportData.reportText2}</p>
              <p>{reportData.reportText3}</p>
            </div>
          </Card3>
        </div>
      </div>
    </div>
  );
}

export default BotaoPNE;
