import { } from "@mui/material";
import styled from "styled-components";


export const Container = styled.div`
border-radius: 15px;
display: flex;
align-items: center;
justify-content:center;
background-color: white;
scrollbar-width: none;
background-color: #FAFAFA;
`;

export const DivSearch = styled.div`
background-color:#fff;
width:180px;
margin-left: 1200px;
border-radius: 15px;
height: 80%;
border: 0.5px solid grey;
cursor: text;
`;

export const DivDispositivos = styled.div`
border-radius: 15px;
box-shadow: 0px 1px 9px -7px rgb(49 49 49);
display: flex;
justify-content:space-around;
background-color: white;
scrollbar-width: none;
width: 1611px;
height: 430px;

background-color: #FAFAFA;

`;

export const Div1Table = styled.div`
width: 100%;
display:flex;
align-items:center;
justify-content: space-around;
background-color:#EFEFEF;
margin-left: auto;
color: #000000;
font-family: 'Roboto';
font-size: 20px;
line-height: 40px;

`;


export const TablePesquisa = styled.table`
width: 100%;
border-radius: 10px;

`;
