const dataTeclado = [
  {
    name: "Teclado de Porta",
    description: "600",
    image: require("../../../assets/img/devices/tecladoporta.png"),
  },
  {
    name: "Teclado de Porta",
    description: "600",
    image: require("../../../assets/img/devices/tecladoporta.png"),
  },
  {
    name: "Teclado de Porta",
    description: "600",
    image: require("../../../assets/img/devices/tecladoporta.png"),
  },
  {
    name: "Teclado de Porta",
    description: "600",
    image: require("../../../assets/img/devices/tecladoporta.png"),
  },
  {
    name: "Teclado de Porta",
    description: "600",
    image: require("../../../assets/img/devices/tecladoporta.png"),
  },
  {
    name: "Teclado de Porta",
    description: "600",
    image: require("../../../assets/img/devices/tecladoporta.png"),
  },
  {
    name: "Teclado de Porta",
    description: "600",
    image: require("../../../assets/img/devices/tecladoporta.png"),
  },
  {
    name: "Teclado de Porta",
    description: "600",
    image: require("../../../assets/img/devices/tecladoporta.png"),
  },
  {
    name: "Teclado de Porta",
    description: "600",
    image: require("../../../assets/img/devices/tecladoporta.png"),
  },
  {
    name: "Teclado de Porta",
    description: "600",
    image: require("../../../assets/img/devices/tecladoporta.png"),
  },
  {
    name: "Teclado de Porta",
    description: "600",
    image: require("../../../assets/img/devices/tecladoporta.png"),
  },
]

export default dataTeclado;