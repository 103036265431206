import React, { useState } from "react";
import HeaderAddDispositivo from "../../Header/HeaderAddDispositivo";
import {
  DeviceCard,
  DevicesArea,
  Title,
  StatusCard,
  StatusDot,
  StatusCard2,
  DeactivateCard3,
  StatusDot2,
  Chart,
  ChartWrapper,
  ReportTitle,
  ReportText1,
  ReportText2,
  ReportText3,
  Card3,
  AlertCount,
  SwitchContainer,
  SwitchInput,
  SwitchSlider,
  SwitchCard,
  SwitchText,
  RowDot,
  DotWrapper,
  DotTitle,
  StatusTitle,
  StatusTitle2,
  RowDot2,
  DotTitle2,
  DotWrapper2,
} from "./style";
import dataSensores from "./sensores";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import reportDataSensor from "./reportDataSensor.json";

const SensorGas = () => {
  const [hoveredMonth, setHoveredMonth] = useState(null);
  const [alert, setAlert] = useState(false);

  const handleAlertSignal = () => {
    setAlert(!alert);
    // Aqui você pode adicionar lógica adicional, como exibir uma mensagem de alerta ou executar outras ações necessárias
  };
  const [selectedDevice, setSelectedDevice] = useState(null);
  // Lógica para definir as cores com base no estado de alerta
  const alertColor = alert ? "#FF0000" : "#d74747";
  const normalColor = alert ? "#58d958" : "#7cf77c";

  const handleDeviceClick = (sensor) => {
    setSelectedDevice(sensor);
  };

  const data = [
    { month: "Jan", alerts: 37 },
    { month: "Fev", alerts: 31 },
    { month: "Mar", alerts: 27 },
    { month: "Abr", alerts: 22 },
    { month: "Mai", alerts: 21 },
    { month: "Jun", alerts: 21 },
    { month: "Jul", alerts: 21 },
    { month: "Ago", alerts: 1 },
    { month: "Set", alerts: 20 },
    { month: "Out", alerts: 19 },
    { month: "Nov", alerts: 16 },
    { month: "Dez", alerts: 16 },
  ];

  const monthNames = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  const optionsApartamentos = {
    chart: {
      type: "column",
      borderRadius: 8,
    },
    title: {
      text: "",
      style: {
        fontFamily: "Roboto, sans-serif",
        fontStyle: "normal",
        fontWeight: 550,
        fontSize: "23px",
      },
    },
    credits: {
      enabled: false, // Remover a marca "highcharts.com"
    },
    xAxis: {
      type: "category",
      lineWidth: 0,
      tickLength: 0,
      labels: {
        rotation: 0,
        style: {
          fontSize: "13px",
          fontFamily: "Roboto, sans-serif",
        },
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "Porcentagem de gás vazado",
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      formatter: function () {
        const { point } = this;
        const monthData = data[point.index];
        const monthName = monthNames[point.index];
        return `Alertas de vazamento de gás no mês de ${monthName}: <b>${monthData.alerts}</b>`;
      },
    },
    plotOptions: {
      series: {
        point: {
          events: {
            mouseOver: function () {
              setHoveredMonth(monthNames[this.index]);
            },
            mouseOut: function () {
              setHoveredMonth(null);
            },
          },
        },
        borderRadius: 8,
      },
    },
    series: [
      {
        name: "Alertas Mensais",
        colors: data.map((item) =>
          item.alerts === Math.max(...data.map((item) => item.alerts))
            ? "#0077B6"
            : "#E9E9E9"
        ),
        colorByPoint: true,
        groupPadding: 0,
        data: data.map((item) => [item.month, item.alerts]),
        dataLabels: {
          enabled: true,
          rotation: -90,
          color: "#FFFFFF",
          align: "right",
          format: "{point.y:.0f}",
          y: 10,
          style: {
            fontSize: "13px",
            fontFamily: "Roboto, sans-serif",
          },
        },
      },
    ],
  };

  return (
    <div
      className="main custom-scroll"
      style={{ display: "flex", flexDirection: "column", flex: "1" }}
    >
      <HeaderAddDispositivo />
      <div
        style={{
          display: "flex",
          flex: "1",
          padding: "2rem",
          overflow: "auto",
          gap: "2rem",
        }}
      >
        <DevicesArea>
          {dataSensores.map((sensor) => (
            <DeviceCard
              className={selectedDevice === sensor ? "selected" : ""}
              onClick={() => handleDeviceClick(sensor)}
            >
              <p className="nowrap">{sensor.name}</p>
              <img src={sensor.image} alt={sensor.name} />
            </DeviceCard>
          ))}
        </DevicesArea>
        <div style={{ display: "flex", flexDirection: "column", flex: 1, gap: "2rem" }}>
          <div style={{ display: "flex", flexDirection: "row", gap: "2rem" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                justifyContent: "space-between",
              }}
            >
              <StatusCard>
                <DotWrapper>
                  <p className="color--grey">Interrupções</p>
                  <StatusDot color={alertColor} label="Alerta" />
                </DotWrapper>
                <DotWrapper
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <p className="color--grey">Normal</p>
                  <StatusDot color={normalColor} label="Normal" />
                </DotWrapper>
              </StatusCard>
              <StatusCard>
                <DotWrapper
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <p className="color--grey">Desativado</p>
                  <StatusDot color={alertColor} label="Alerta" />
                </DotWrapper>
                <DotWrapper
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <p className="color--grey">Ativado</p>
                  <StatusDot color={normalColor} label="Normal" />
                </DotWrapper>
              </StatusCard>
              <SwitchCard>
                <SwitchContainer>
                  <SwitchInput
                    type="checkbox"
                    checked={alert}
                    onChange={handleAlertSignal}
                  />
                  <SwitchSlider />
                </SwitchContainer>
              </SwitchCard>
            </div>
            <ChartWrapper>
              <h3 className="basis--1">Quantidade de gás liberado</h3>
              <div className="basis--9">
                <HighchartsReact
                  highcharts={Highcharts}
                  options={optionsApartamentos}
                />
              </div>
            </ChartWrapper>
          </div>
          <Card3>
            <ReportTitle>
              <h3>Relatório:</h3>
              <AlertCount>{reportDataSensor.alertCount}</AlertCount>
            </ReportTitle>
            <div className="flex column basis--25">
              <p>{reportDataSensor.reportText1}</p>
              <p className="color--red">{reportDataSensor.reportText2}</p>
              <p className="color--red">{reportDataSensor.reportText3}</p>
            </div>
          </Card3>
        </div>
      </div>
    </div>
  );
};

export default SensorGas;
