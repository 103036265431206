import styled, { css } from "styled-components";
import '@fontsource/righteous';
import '@fontsource/roboto';


export const Title1 = styled.div`
  display: flex;
  padding-top: 1rem;
  padding-left:1rem;
  color: #000;
  font-size: 23px;
  font-family: "Roboto";
  font-weight: bold;
  width:100%;
`;

export const Card1 = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 40%;
  margin-left: 2rem;
  border-radius: 10px;
  background: white;
  box-shadow: 10px 10px 8px 0px rgba(219, 219, 219, 1);
  height:350px;
`;

export const StatusText = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  text-align: left;
  /* color: white; */
`;