import {
  Grid,
  Paper,
  Typography,
  TextField,
  InputAdornment,
} from "@mui/material";
import styled from "styled-components";

export const Container = styled(Grid)`
  height: 100%;
  width: 100%;
  background: linear-gradient(180deg, #172880, #2235a0, #2e43c1, #3a52e3);
`;

export const Content = styled(Grid)`
  padding: 16px;
`;

export const LogoImage = styled("img")`
  position: absolute;
  width: 137px;
  height: 135px;
  left: 406px;
  top: 66px;
  filter: brightness(0) invert(1);
`;

export const LogoText = styled(Typography)`
  text-align: center;
  color: white;
`;

export const PaperCustom = styled(Paper)`
  position: absolute;
  width: 100%;
  height: 100vh;
  left: 0px;
  top: 0px;
  background: linear-gradient(
    180deg,
    #003564 0%,
    #004d93 33.33%,
    #0062ba 64.58%,
    #9cc9f1 100%
  );
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
`;

export const BoxLogin = styled(Grid)`
  position: absolute;
  width: 532px;
  height: 65%;
  left: 210px;
  top: 248px;
  background: #ffffff;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.25);
  border-radius: 45px;
  padding: 16px;
`;

export const Welcome = styled.h1`
  width: 262px;
  height: 40px;
  margin-left: 25%;
  font-family: "Righteous";
  font-style: "normal;
  font-weight: bold;
  font-size: 45px;
  line-height: 20px;
  text-align: center;
  color: #0056a3;
  margin-top: 22%;
`;

export const CustomTextFieldWrapper = styled.div`
  position: relative;
  margin-bottom: 20px;

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background-image: url(${(props) => props.imageUrl});
  }
`;

export const CustomTextField = styled.input`
  width: 340px;
  height: 50px;
  padding: 10px 16px;
  border-radius: 7px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: "Righteous";
  font-size: 20px;
  border: none;
  outline: none;
  background-color: #f9f9f9;
  color: #757575;
  background-image: ${(props) => `url(${props.imageUrl})`};
  background-position: 10px center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  padding-left: 40px;

  &::placeholder {
    font-family: "Righteous";
  }
  &:last-child {
    margin-bottom: 20px;
  }
`;

export const IconWrapper = styled.div`
  height: 100%;
  background-image: url(${(props) => props.imageUrl});
  background-repeat: no-repeat;
  background-position: left;
  background-size: 1.3em;
  padding-left: 1.3em;
`;

export const PasswordIconWrapper = styled(IconWrapper)`
  // display: inline-flex;
  // align-items: right;
  // cursor: pointer;
`;

export const AdornmentWrapper = styled(InputAdornment)`
  display: flex;
  align-items: center;
  color: #757575;
`;

export const ImageWrapper = styled("img")`
  position: absolute;
  width: 968px;
  height: 100%;
  left: 952px;
  image-rendering: auto;
  background: rgba(0, 0, 0, 0.2);
`;

export const SecondImageWrapper = styled("img")`
  position: absolute;
  width: 150px;
  height: 150px;
  right: 20px;
  bottom: 0;
  object-fit: contain;
`;

export const IconFaq = styled.div`
  position: absolute;
  top: 26px;
  right: 30px;
  width: 77px;
  height: 77px;
  background: #0156A4;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;