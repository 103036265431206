export const data = [

    {
        title: "Alerta de Disparo do Sensor Perimetral",
        description: "Sensor 2 - Área Externa da  piscina ",
        dateHours: "Hoje às 18:30",
        image: require("../../assets/img/devices/sensorPerimetral.png"),

    },
    {
        title: "Alerta de CO alto",
        description: "Área Externa Garagem",
        dateHours: "Hoje às 17:50",
        image: require("../../assets/img/devices/sensorCO.png"),

    },
    {
        title: "Alerta de Disparo do Sensor Perimetral",
        description: "Sensor 2 - Área Externa da  piscina ",
        dateHours: "Hoje às 16:45",
        image: require("../../assets/img/devices/sensorPerimetral.png"),

    },
    {
        title: "Alerta de Nível d'água",
        description: "Sensor 1 - Área Externa Reservatório 2 ",
        dateHours: "Hoje às 14:30",
        image: require("../../assets/img/devices/sensorReserv.png"),

    },
    {
        title: "Alerta de Consumo alto d'água",
        description: "Sensor 4 - Área Interna, Dispositivo: H12345678  ",
        dateHours: "Hoje às 12:39",
        image: require("../../assets/img/devices/hidrometros.png"),

    },
    {
        title: "Alerta de Disparo do Sensor Perimetral",
        description: "Sensor 5 - Área Externa da  piscina ",
        dateHours: "Ontem às 08:18",
        image: require("../../assets/img/devices/sensorPerimetral.png"),

    },
    {
        title: "Alerta de Disparo do Sensor Perimetral",
        description: "Sensor 5 - Área Externa da  piscina ",
        dateHours: "Ontem às 08:18",
        image: require("../../assets/img/devices/sensorPerimetral.png"),

    },
    {
        title: "Alerta de Disparo do Sensor Perimetral",
        description: "Sensor 5 - Área Externa da  piscina ",
        dateHours: "Ontem às 08:18",
        image: require("../../assets/img/devices/sensorPerimetral.png"),

    },
    {
        title: "Alerta de Disparo do Sensor Perimetral",
        description: "Sensor 5 - Área Externa da  piscina ",
        dateHours: "Ontem às 08:18",
        image: require("../../assets/img/devices/sensorPerimetral.png"),

    },
    {
        title: "Alerta de Disparo do Sensor Perimetral",
        description: "Sensor 5 - Área Externa da  piscina ",
        dateHours: "Ontem às 08:18",
        image: require("../../assets/img/devices/sensorPerimetral.png"),

    },
    {
        title: "Alerta de Disparo do Sensor Perimetral",
        description: "Sensor 5 - Área Externa da  piscina ",
        dateHours: "Ontem às 08:18",
        image: require("../../assets/img/devices/sensorPerimetral.png"),

    },

]