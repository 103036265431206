import { } from "@mui/material";
import styled from "styled-components";


export const Container = styled.div`
border-radius: 15px;
filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.25));
display: flex;
align-items: center;
justify-content:center;
background-color: white;
scrollbar-width: none;
width: 1611px;
height: 70px;
left: 274px;
top: 130px;
position: absolute;
background-color: #FAFAFA;
`;

export const DivSearch = styled.div`
background-color:#ffff;
width:180px;
margin-left: 1200px;
border-radius: 15px;
height: 80%;
border: 0.5px solid grey;
cursor: text;
background-color: #FAFAFA;
`;

export const DivDispositivos = styled.div`
border-radius: 15px;
box-shadow:10px 10px 8px 0px rgba(219, 219, 219, 1);
display: flex;
justify-content:space-around;
background-color: white;
scrollbar-width: none;
width: 1611px;
height: 665px;
left: 274px;
top: 250px;
position: absolute;
background-color: #FAFAFA;

`;

export const Div1Table = styled.div`
width: 100%;
display:flex;
align-items:center;
justify-content: space-around;
background-color:#EFEFEF;
margin-left: auto;
color: #000000;
font-family: 'Roboto';
font-size: 20px;
line-height: 40px;

`;


export const TablePesquisa = styled.table`
width: 100%;
border-radius: 10px;

`;
