import "@fontsource/roboto";
import styled from "styled-components";

// Estilos para a área de dispositivos
export const DevicesArea = styled.div`
  box-shadow: inset 0 0 10px -7px black;
  background: whitesmoke;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.25rem 2rem;
  overflow-y: scroll;
  border-radius: .5rem;
`;

// Estilos para cada card de dispositivo
export const DeviceCard = styled.div`

  background: #fafafa;
  box-shadow: 0 1px 11px -7px #404040;
  border-radius: .5rem;
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .5rem;

  // Estilos da imagem
  img {
    width: 89.24px;
    height: 82px;
  }

  // Estilos quando o card é selecionado
  &.selected {
    border-color: #7cabe5;
  }
`;

export const Title = styled.h3`
  font-family: "Roboto";
  font-size: 15px;
  font-weight: 400;
`;

export const StatusCard = styled.div`
  background: white;
  box-shadow: 0 1px 11px -7px #404040;
  border-radius: .5rem;
  padding: 2rem 2rem 2.5rem;
  display: flex;
  justify-content: space-around;
  gap: 1rem;
  align-items: center;
  flex: 1;
`;

export const StatusTitle = styled.h3`
  font-family: "Roboto";
  font-weight: 100;
  font-size: 23px;
  text-align: left;
  /* margin-top: -20px; */
  margin: 0;
`;

export const StatusCard2 = styled.div`
  /* width: 655px;
  height: 150px; */
  /* margin-left: 260px;
  margin-top: 30px; */
  background: white;
  box-shadow: 0 1px 11px -7px #404040;
  border-radius: 10px;
  padding: 1rem;
  flex-basis: 20%;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const StatusTitle2 = styled.h3`
  font-family: "Roboto";
  font-weight: 100;
  font-size: 23px;
  text-align: left;
  /* margin-top: -20px; */
  margin: 0;
`;

export const RowDot2 = styled.div`
  display: flex;
  position: relative;
  justify-content: space-evenly;
`;

export const StatusDot2 = styled.span`
  font-family: "Roboto";
  width: 72px;
  height: 72px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  border: 5px solid #353535;
  text-align: center;
  font-size: 30px;
  position: relative;
`;

export const DotTitle2 = styled.p`
  /* position: absolute;
    top: -30px; */
  font-size: 20px;
  font-weight: 400;
  margin: 0;
  align-self: center;
`;

export const DotWrapper2 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const DeactivateCard3 = styled.div`
  background: white;
  box-shadow: 0 1px 11px -7px #404040;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const SwitchCard = styled.div`
  display: flex;
  align-items: center;
  background: white;
  box-shadow: 0 1px 11px -7px #404040;
  border-radius: .5rem;
  justify-content: center;
  padding: 1rem;
`;

export const SwitchContainer = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  width: 82px;
  height: 36px;
`;

export const SwitchInput = styled.input`
  display: none;

  &:checked + span:before {
    transform: translateX(46px);
    background-color: #7cf77c;
  }
`;

export const SwitchSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #353535;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 36px;

  &:before {
    position: absolute;
    content: "";
    height: 28px;
    width: 28px;
    left: 4px;
    bottom: 4px;
    background-color: #d74747;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }

  &:hover:before {
    background-color: #d74747;
  }
`;

export const SwitchText = styled.span`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 15px;
  text-align: center;
  color: #000000;
  margin-left: -90px;
`;

export const ChartWrapper = styled.div`
  background: white;
  box-shadow: 0 1px 11px -7px #404040;
  border-radius: .5rem;
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  gap: 1rem;
  flex: 1
`;

export const Chart = styled.div`
  width: 100%;
`;

export const Card3 = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  gap: 1rem;
  padding: 1rem 2rem;
  box-shadow: 0 1px 11px -7px #404040;
  border-radius: .5rem;
  flex: 1;
`;

export const ReportTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

/*Quinta, dia 14/05/2022 - Aumento no nível de gases no local */
export const ReportText1 = styled.div`
  display: flex;
  text-align: left;
  padding: 0.5rem;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 200;
  font-size: 20px;
  line-height: 23px;
  color: #000000;
`;

/*Quarta, dia 13/05/2022 - Sensor Desligado */
export const ReportText2 = styled.div`
  display: flex;
  text-align: left;
  padding: 0.5rem;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 200;
  font-size: 20px;
  line-height: 23px;
  color: #db3030;
`;

/*Quarta, dia 13/05/2022 - Sensor com nível de bateria baixa */
export const ReportText3 = styled.div`
  display: flex;
  text-align: left;
  padding: 0.5rem;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 200;
  font-size: 20px;
  line-height: 23px;
  color: #db3030;
`;

/* Alertas */
export const AlertCount = styled.div`
  display: flex;
  text-align: right;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: red;
`;



export const RowDot = styled.div`
  display: flex;
  position: relative;
  justify-content: space-evenly;
`;

export const StatusDot = styled.span`
  font-family: "Roboto";
  width: 72px;
  height: 72px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  box-shadow: 0 0 8px -5px black;
  text-align: center;
  font-size: 30px;
  position: relative;
  `;

export const DotTitle = styled.p`
    /* position: absolute;
    top: -30px; */
    font-size: 20px;
    font-weight: 400;
    margin: 0;
    align-self: center;
`;

export const DotWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: .25rem;
    align-items: center;
    flex: .5;
`;
