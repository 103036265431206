import React, { } from "react";
import Menu from "../Menu";
import HeaderHome from "../Header/HeaderHome";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {
    DivRegra, PageWrapper
} from "./style";
import HeaderAddDispositivo from "../Header/HeaderDipositivosHidrometro";


const Configuracao = () => {

    return (
            <div style={{ display:"flex",flexDirection:"column",flexBasis:"100%", fontSize:"20px", }}>
               <HeaderAddDispositivo title={"Configurações"}/>
               
            
                    <PageWrapper>
                    <DivRegra>
                    <h2 style={{fontFamily: "Roboto",fontSize:"30px", }}>Regras</h2>
                    </DivRegra>
                    <DivRegra>
                    <h2 style={{fontFamily: "Roboto",fontSize:"30px", }}>Localidade</h2>    
                    </DivRegra>
                    <DivRegra>
                    <h2 style={{fontFamily: "Roboto",fontSize:"30px", }}>Dispositivos</h2>
                    </DivRegra>
                    </PageWrapper>
               
            </div>               







    );
}
export default Configuracao;