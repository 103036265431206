import React, { useState } from "react";
import {
    ContainerConta,
    Nav,
    Notification,
    Title,
} from "./styles";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Link } from "react-router-dom";

const HeaderTitle = ({ title }) => {

    const [showNotifications, setShowNotifications] = useState(false);

    const handleNotificationClick = () => {
        setShowNotifications(!showNotifications);
    };

    return (
        <Nav>
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
                <Title>{title}</Title>
            </div>
            <div style={{display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
                <ContainerConta>
                    <p>Andrei</p>
                    <h5 style={{ color: "#c0c0c0", fontWeight: "400" }}>Administrador</h5>
                </ContainerConta>
                <Link to="/notificacoes">
                    <Notification onClick={handleNotificationClick}>
                        <NotificationsIcon
                            style={{
                                fontSize: "20px",
                                color: "white",
                                position: "unset",
                            }}
                        />
                    </Notification>
                </Link>
            </div>
        </Nav>
    );
};

export default HeaderTitle;
