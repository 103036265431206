import styled, { css } from "styled-components";
import '@fontsource/righteous';


export const Card1 = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 30%;
  margin-left: 2rem;
  border-radius: 10px;
  background: white;
  box-shadow: 10px 10px 8px 0px rgba(219, 219, 219, 1);
`;

export const Card2 = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 80%;
  margin-left: 2rem;
  border-radius: 10px;
  background: white;
  box-shadow: 10px 10px 8px 0px rgba(219, 219, 219, 1);
`;

export const Card3 = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  margin-left: 3rem;
  margin-right: 1rem;
  border-radius: 10px;
  background: white;
  box-shadow: 10px 10px 8px 0px rgba(219, 219, 219, 1);
`;

export const Title1 = styled.div`
  display: flex;
  padding-top: 1rem;
  padding-left:1rem;
  color: #000;
  font-size: 23px;
  font-family: "Roboto";
  font-weight: bold;
  width:100%;
`;

export const DotWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  align-items:center;
`;

export const DotTitle = styled.p`
  /* position: absolute;
    top: -30px; */
  font-size: 15px;
  font-weight: 400;
  margin: 0;
`;

export const StatusDot = styled.span`
  font-family: "Roboto";
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  box-shadow: 0 0 8px -5px black;
  text-align: center;
`;

export const StatusText = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  text-align: left;
  /* color: white; */
`;

export const TitleGraphs = styled.h2`
font-size: 25px;
  font-family: "Roboto";
`;

export const CircleTemp = styled.div`
  background: linear-gradient(180deg, #CB5513 0%, rgba(195, 93, 35, 0.85) 34.90%, rgba(209, 28, 17, 0.80) 67.71%, rgba(255, 0, 0, 0.74) 100%);
  border:6px solid #242424;
  border-radius:50%;
  width:150px;
  height:150px;
  display:flex;
  align-items:center;
  justify-content:center;
`;
export const CircleUmi = styled.div`
  ${({ theme }) => css``
}
  background: linear-gradient(180deg, #0596FF 0%, rgba(34, 97, 218, 0.85) 34.90%, rgba(29, 69, 171, 0.80) 67.71%, rgba(1, 17, 56, 0.74) 100%);
  border:6px solid #242424;
  border-radius:50%;
  width:150px;
  height:150px;
  display:flex;
  align-items:center;
  justify-content:center;
  text-align:center;
`;
export const CircleOx = styled.div`
  background: linear-gradient(180deg, #525252 0%, #9B9B9C 34.90%, #ACADAE 67.71%, #FBFBFD 100%);
  border:6px solid #242424;
  border-radius:50%;
  width:150px;
  height:150px;
  display:flex;
  align-items:center;
  justify-content:center;
  text-align:center;
`;

export const CircleNumber = styled.div`
  background-color:#242424;
  border-radius:50%;
  width:100px;
  height:100px;
  font-family:"righteous";
  color:white;
  font-size:30px;
  text-align:center;
  display:flex;
  align-items:center;
  justify-content:center;

`;