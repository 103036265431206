import React, { useRef, useState } from "react";
import {
  Devices,
  CardDevice,
  TitleDevice,
  InfoDevice,
  ImgDevice,
} from "./styles";
import { data } from "./dispositivos";

const DispositivosApartamentos = () => {
  const devicesRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [dragStartX, setDragStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = (event) => {
    setIsDragging(true);
    setDragStartX(event.clientX - devicesRef.current.offsetLeft);
    setScrollLeft(devicesRef.current.scrollLeft);
  };

  const handleMouseMove = (event) => {
    if (!isDragging) return;
    const x = event.clientX - devicesRef.current.offsetLeft;
    const scrollX = x - dragStartX;
    devicesRef.current.scrollLeft = scrollLeft - scrollX;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
  };

  return (
    <Devices
      ref={devicesRef}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseLeave}
    >
      <CardDevice>
        <div style={{ display: "flex", gap: "2rem", alignItems: "center", padding: ".3rem .5rem" }}>
          <ImgDevice src={data.co.image} />
          <TitleDevice>{data.co.name}</TitleDevice>
        </div>
        <InfoDevice>Dispositivos: {data.co.description}</InfoDevice>
      </CardDevice>

      <CardDevice>
        <div style={{ display: "flex", gap: "2rem", alignItems: "center", padding: ".3rem .5rem" }}>
          <ImgDevice src={data.din.image} />
          <TitleDevice>{data.din.name}</TitleDevice>
        </div>
        <InfoDevice>Dispositivos: {data.din.description}</InfoDevice>
      </CardDevice>
      <CardDevice>
        <div style={{ display: "flex", gap: "2rem", alignItems: "center", padding: ".3rem .5rem" }}>
          <ImgDevice src={data.perimetro.image} />
          <TitleDevice>{data.perimetro.name}</TitleDevice>
        </div>
        <InfoDevice>Dispositivos: {data.perimetro.description}</InfoDevice>
      </CardDevice>
      <CardDevice>
        <div style={{ display: "flex", gap: "2rem", alignItems: "center", padding: ".3rem .5rem" }}>
          <ImgDevice src={data.reservatorio.image} />
          <TitleDevice>{data.reservatorio.name}</TitleDevice>
        </div>
        <InfoDevice>Dispositivos: {data.reservatorio.description}</InfoDevice>
      </CardDevice>

    </Devices>
  );
};

export default DispositivosApartamentos;
