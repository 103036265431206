import "@fontsource/roboto";
import styled from "styled-components";

// Estilos para a área de dispositivos
export const DevicesArea = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
  overflow-y: scroll;
  min-width: max-content;
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

// Estilos para cada card de dispositivo
export const DeviceCard = styled.div`
  width: 171px;
  height: 165px;
  background: #fafafa;
  box-shadow: 0px 1px 9px -7px rgb(49 49 49);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;

  // Estilos da imagem
  img {
    width: 89.24px;
    height: 82px;
  }

  // Estilos quando o card é selecionado
  &.selected {
    border-color: #7cabe5;
  }
`;

export const Title = styled.h3`
  font-family: "Roboto";
  font-size: 15px;
  font-weight: 400;
`;

// Card 1
export const Card1 = styled.div`
  display: flex;
  gap: 1rem;
  padding: 1rem 2rem;
  background: white;
  box-shadow: 0px 1px 9px -7px rgb(49 49 49);
  border-radius: 10px;
  flex: .3;
`;

export const LogText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const StatusText = styled.p`
  color: #404040;
`;

export const AlarmText = styled.div`
  width: 212px;
  height: 15px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 15px;
  text-align: right;
`;

export const IntruderText = styled.div`
  width: 232px;
  height: 15px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 15px;
  text-align: left;
`;

export const FailureText = styled.div`
  width: 212px;
  height: 15px;
  left: 724px;
  top: 343px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 15px;
  text-align: left;
`;

// Card 2
export const Card2 = styled.div`
  display: flex;
  flex-direction: column;
  flex: .3;
  background: #fafafa;
  box-shadow: 0px 1px 9px -7px rgb(49 49 49);
  border-radius: 10px;
  background: white;
  padding: 1rem 2rem;
  gap: 1rem;
`;

export const LocalCard2 = styled.div`
  width: 372px;
  height: 27px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 200;
  font-size: 23px;
  line-height: 27px;
  text-align: center;

  color: #000000;
`;

export const Card2Title = styled.h2`
  height: 27px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  font-size: 23px;
  line-height: 27px;
  text-align: left;
  padding: .5rem;
  padding-left: 1rem;
  color: #000000;
`;

// Card 3
export const Card3 = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 1rem;
  padding: 1rem 2rem;
  background: white;
  box-shadow: 0px 1px 9px -7px rgb(49 49 49);
  border-radius: 10px;
  flex: .7
`;

/* Relatório */
export const ReportTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

/*Tentativa de “alarme desativado”: senha errada */
export const ReportRed = styled.p`
  color: #db3030;
`;

/* Alertas */
export const AlertCount = styled.h5`
  color: #db3030;
`;

// Card 4
export const Card4 = styled.div`
  background: white;
  box-shadow: 0px 1px 9px -7px rgb(49 49 49);
  border-radius: 10px;
  padding: 1rem 2rem;
  flex: .7;
  gap: 1rem
`;
