import React, { useState } from "react";
import ApartmentIcon from "@mui/icons-material/Apartment";
import {
  SideMenu,
  ImageLogo,
  ContainerImage,
  ContainerRoutes,
  LinkRoute,
} from "./styles";
import { Images } from "../../assets/img/logo";
import { useNavigate } from "react-router-dom";

const Menu = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [routeSelected, setRouteSelected] = useState(0);
  const [openSubMenuIndex, setOpenSubMenuIndex] = useState(-1);
  const navigate = useNavigate();

  const changeRoute = (item, index) => {
    if (item.title !== "Dashboard") {
      setOpenMenu(true);
      setRouteSelected(index);
      setOpenSubMenuIndex(index);
    } else {
      setOpenMenu(false);
      setOpenSubMenuIndex(index);
      setRouteSelected(index);
    }
  };

  const routes = [
    {
      title: "Dashboard",
      icon: <span class="material-symbols-outlined">home</span>,
      route: "/",
    },
    {
      title: "Apartamentos",
      icon: <ApartmentIcon />,
      subMenu: [
        { title: "Apartamentos", route: "/apartamentos" },
        { title: "HID porta", route: "/hid" },
        { title: "Sensor de Gás", route: "/sensorgas" },
        { title: "Teclado de Porta", route: "/tecladoporta" },
        { title: "Hidrômetro", route: "/hidrometro" },
      ],
    },
    {
      title: "Areas Comuns",
      icon: <span class="material-symbols-outlined">nature</span>,
      subMenu: [
        { title: "Areas Comuns", route: "/areascomuns" },
        { title: "Sensor de CO", route: "/sensorco" },
        { title: "DIN Quadrinhos Elétricos", route: "/quadroeletrico" },
        { title: "Sensor Perimetral", route: "/sensorperimetro" },
        { title: "Sensor de Nível de Água", route: "/sensornivelagua" },
        { title: "Botão PNE", route: "/botaopne" },
      ],
    },
  ];

  const Submenu = ({ subMenuItens }) => (
    <>
      {subMenuItens.map((item) => (
        <div
          onClick={() => navigate(item.route)}
          key={item.id}
          style={{
            margin: 10,
            paddingLeft: "1rem",
            borderBottom: "1px solid #f9f9f9",
            paddingBottom: ".5rem",
            cursor:"pointer"
          }}
        >
          <div>{item.title}</div>
        </div>
      ))}
    </>
  );

  return (
    <SideMenu openMenu={openMenu}>
      <ContainerImage onClick={() => setOpenMenu(!openMenu)}>
        <ImageLogo src={Images.logo} />
      </ContainerImage>

      <ContainerRoutes>
        <div className="containerIcons">
          {routes.map((item, index) => {
            return (
              <div>
                {item.route ? (
                  <LinkRoute
                    to={item.route}
                    openMenu={!openMenu}
                    routeSelected={routeSelected == index}
                    key={index}
                    onClick={() => changeRoute(item, index)}
                  >
                    {item.icon}
                    {openMenu && item.title}
                  </LinkRoute>
                ) : (
                  <LinkRoute
                    openMenu={!openMenu}
                    routeSelected={routeSelected == index}
                    key={index}
                    onClick={() => changeRoute(item, index)}
                  >
                    {item.icon}
                    {openMenu && item.title}
                  </LinkRoute>
                )}

                {item.subMenu && openMenu && openSubMenuIndex == index && (
                  <Submenu
                    subMenuItens={item.subMenu}
                    open={openSubMenuIndex === index}
                  />
                )}
              </div>
            );
          })}
        </div>

        <div>
          <LinkRoute to="//Configuracao" openMenu={!openMenu}>
            <span class="material-symbols-outlined">settings</span>
            <p>{openMenu && "Configurações"}</p>
          </LinkRoute>
          <LinkRoute to="/" openMenu={!openMenu}>
            <span class="material-symbols-outlined">brightness_6</span>
            <p>{openMenu && "Alterar tema"}</p>
          </LinkRoute>
          <LinkRoute to="/login" openMenu={!openMenu}>
            <span class="material-symbols-outlined">logout</span>
            <p>{openMenu && "Sair"}</p>
          </LinkRoute>
        </div>
      </ContainerRoutes>
    </SideMenu>
  );
};

export default Menu;
