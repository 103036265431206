const dataHID = [
    {
      name: "HID Porta",
      description: "598",
      image: require("../../../assets/img/devices/HID.png"),
    },
    {
      name: "HID Porta",
      description: "598",
      image: require("../../../assets/img/devices/HID.png"),
    },
    {
      name: "HID Porta",
      description: "598",
      image: require("../../../assets/img/devices/HID.png"),
    },
    {
      name: "HID Porta",
      description: "598",
      image: require("../../../assets/img/devices/HID.png"),
    },
    {
      name: "HID Porta",
      description: "598",
      image: require("../../../assets/img/devices/HID.png"),
    },
    {
      name: "HID Porta",
      description: "598",
      image: require("../../../assets/img/devices/HID.png"),
    },
    {
      name: "HID Porta",
      description: "598",
      image: require("../../../assets/img/devices/HID.png"),
    },
    {
      name: "HID Porta",
      description: "598",
      image: require("../../../assets/img/devices/HID.png"),
    },
    {
      name: "HID Porta",
      description: "598",
      image: require("../../../assets/img/devices/HID.png"),
    },
    {
      name: "HID Porta",
      description: "598",
      image: require("../../../assets/img/devices/HID.png"),
    },
]

export default dataHID;