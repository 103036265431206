import React, { useState, useEffect } from "react";
import Menu from "../../../Menu";
import HeaderHome from "../../../Header/HeaderHome";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Container,
  Circle,
  InnerCircle,
  TextWrapper,
  ChartWrapper,
  ThirdCircle,
  InnerCircle2,
  ThirdCircle2,
  InnerCircle3,
  ThirdCircle3,
  InnerCircle4,
  ThirdCircle4,
  ChartDispositivos,
  VerTodosContainer,
  VerTodosText,
  Container2,
  Title1,
  div,
  Reports,
  PTytle,
} from "./style";

import { Margin, Padding } from "@mui/icons-material";
import { color } from "highcharts";
import ReactApexChart from "react-apexcharts";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ReportData from "./reportData.json";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
const QuadroIluminacao = () => {
  const data = {
    temperaturas: ["99", "105", "133"],
    titles: ["Bomba n°1", "Bomba n°2", "Bomba n°3"],
  };

  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive(!isActive);
  };

  const CircleWithNumber = () => {
    // const [number, setNumber] = useState(nivelCalor.percent);

    // useEffect(() => {
    //     // Atualize o estado do número quando o valor do arquivo JSON mudar
    //     setNumber(nivelCalor.percent);
    // }, [nivelCalor.percent]);

    // const getCircleColor = () => {
    //     // Verifique o valor do número e retorne a cor correta
    //     if (parseFloat(number) > 75) {
    //         return "red";
    //     }
    //     if (parseFloat(number) >= 50) {
    //         return "orange"
    //     }
    //     return "#8ef78e";
    // };

    return (
      <InnerCircle style={{ backgroundColor: "#00B4D8" }}>
        <ThirdCircle
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "5px",
            alignItems: "center",
            justifyContent: "revert",
          }}
        >
          <TextWrapper>
            <PowerSettingsNewIcon
              style={{ fontSize: "50px", marginTop: "50%" }}
            />
          </TextWrapper>
        </ThirdCircle>
      </InnerCircle>
    );
  };

  const CircleWithNumber2 = () => {
    // const [number, setNumber] = useState(nivelCalor.percent);

    // useEffect(() => {
    //     // Atualize o estado do número quando o valor do arquivo JSON mudar
    //     setNumber(nivelCalor.percent);
    // }, [nivelCalor.percent]);

    // const getCircleColor = () => {
    //     // Verifique o valor do número e retorne a cor correta
    //     if (parseFloat(number) > 75) {
    //         return "red";
    //     }
    //     if (parseFloat(number) >= 50) {
    //         return "orange"
    //     }
    //     return "#8ef78e";
    // };

    return (
      <InnerCircle2 style={{ backgroundColor: "#00B4D8" }}>
        <ThirdCircle2
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "5px",
            alignItems: "center",
            justifyContent: "revert",
          }}
        >
          <TextWrapper>
            <PowerSettingsNewIcon
              style={{ fontSize: "50px", marginTop: "50%" }}
            />
          </TextWrapper>
        </ThirdCircle2>
      </InnerCircle2>
    );
  };
  const CircleWithNumber3 = () => {
    // const [number, setNumber] = useState(nivelCalor.percent);

    // useEffect(() => {
    //     // Atualize o estado do número quando o valor do arquivo JSON mudar
    //     setNumber(nivelCalor.percent);
    // }, [nivelCalor.percent]);

    // const getCircleColor = () => {
    //     // Verifique o valor do número e retorne a cor correta
    //     if (parseFloat(number) > 75) {
    //         return "red";
    //     }
    //     if (parseFloat(number) >= 50) {
    //         return "orange"
    //     }
    //     return "#8ef78e";
    // };

    return (
      <InnerCircle3 style={{ backgroundColor: "#00B4D8" }}>
        <ThirdCircle3
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "5px",
            alignItems: "center",
            justifyContent: "revert",
          }}
        >
          <TextWrapper>
            <PowerSettingsNewIcon
              style={{ fontSize: "50px", marginTop: "50%" }}
            />
          </TextWrapper>
        </ThirdCircle3>
      </InnerCircle3>
    );
  };

  const CircleWithNumber4 = () => {
    // const [number, setNumber] = useState(nivelCalor.percent);

    // useEffect(() => {
    //     // Atualize o estado do número quando o valor do arquivo JSON mudar
    //     setNumber(nivelCalor.percent);
    // }, [nivelCalor.percent]);

    // const getCircleColor = () => {
    //     // Verifique o valor do número e retorne a cor correta
    //     if (parseFloat(number) > 75) {
    //         return "red";
    //     }
    //     if (parseFloat(number) >= 50) {
    //         return "orange"
    //     }
    //     return "#8ef78e";
    // };

    return (
      <InnerCircle4 style={{ backgroundColor: "#00B4D8" }}>
        <ThirdCircle4
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "5px",
            alignItems: "center",
            justifyContent: "revert",
          }}
        >
          <TextWrapper>
            <PowerSettingsNewIcon
              style={{ fontSize: "50px", marginTop: "50%" }}
            />
          </TextWrapper>
        </ThirdCircle4>
      </InnerCircle4>
    );
  };

  const optionsPopulation = {
    chart: {
      type: "pie",
    },
    title: {
      text: "Distribuição elétrica nas áreas comuns",
      align: "left",
      style: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "23px",
      },
    },
    tooltip: {
      headerFormat: "",
      pointFormat: "Porcentagem: <b>{point.percentage:.1f}%</b><br/>",
    },
    series: [
      {
        minPointSize: 10,
        innerSize: "20%",
        zMin: 0,
        name: "countries",
        borderRadius: 5,
        data: [
          { name: "Churrasqueira", y: 598 },
          { name: "Postes de iluminação ", y: 255 },
          { name: "Salão de festa", y: 600 },
          { name: "Piscinas", y: 497 },
          { name: "Quadra de esportes", y: 300 },
        ],
        colors: ["#2C73D2", "#008E9B", "#0089BA", "#0081CF", "#1A2F2B"],
      },
    ],
    credits: {
      enabled: false, // Remover a marca "highcharts.com"
    },
  };



  const CloseMenu = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        height: 80,
      }}
    >
      <button

        style={{
          display: "flex",
          width: "100%",
          border: "none",
          boxShadow: "0px 10px 10px -5px rgba(0, 0, 0, 0.15)",
          alignItems: "center",
          backgroundColor: "transparent",
          cursor: "pointer",
        }}
      >
        <MenuIcon />
      </button>
    </div>
  );

  return (
    <div className="main" style={{ display: "flex", flexDirection: "column", flex: 1 }}>
      <HeaderHome />
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <ChartDispositivos>
          <div>
            <HighchartsReact
              highcharts={Highcharts}
              options={optionsPopulation}
            />
          </div>
        </ChartDispositivos>
        <Container>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              padding: "10px",
            }}
          >
            <Title1>Relatório:</Title1>
            <p style={{ color: ReportData.alertcount }}>
              {ReportData.alertcount.erro}
            </p>
          </div>
          <div>
            {ReportData.reports.map((reports, index) => (
              <Reports key={index}>{reports}</Reports>
            ))}
          </div>
        </Container>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          marginTop: "20px",
        }}
      >
        <Container2>
          <h1
            style={{
              fontSize: "23px",
              fontFamily: "Roboto",
              textAlign: "left",
              marginTop: "35px",
              marginLeft: "20px",
            }}
          >
            Temporizador das áreas comuns
          </h1>
          <div style={{ paddingLeft: "40px", paddingTop: "1rem" }}>
            <PTytle>
              Insira abaixo os horários para que as luzes sejam ligadas
              automaticamente e também para que sejam desligadas
              automaticamente
            </PTytle>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: "30px",
            }}
          >
            <div>
              <h3>Horário início</h3>
              <h3 style={{ fontSize: "35px" }}>
                <RemoveCircleOutlineIcon
                  style={{ fontSize: "30px", paddingRight: "7px" }}
                />
                06:00
                <AddCircleOutlineIcon
                  style={{ fontSize: "30px", paddingLeft: "7px" }}
                />
              </h3>
            </div>
            <div>
              <h3 style={{ justifyContent: "right" }}>Horário fim:</h3>
              <h3 style={{ fontSize: "35px" }}>
                <RemoveCircleOutlineIcon
                  style={{ fontSize: "30px", paddingRight: "7px" }}
                />
                18:00
                <AddCircleOutlineIcon
                  style={{ fontSize: "30px", paddingLeft: "7px" }}
                />
              </h3>
            </div>
          </div>
        </Container2>

        <ChartWrapper>
          {/* <GraphPercentualCo /> */}
          <div>
            <Title1>Iluminação</Title1>
          </div>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around", paddingTop: "3rem" }}>
            <div>
              <Circle active={isActive} >
                <PowerSettingsNewIcon
                  style={{ fontSize: "50px" }}
                  onClick={handleClick}
                />
              </Circle>
              <h2 style={{ fontSize: "20px" }}>Churrasqueira</h2>
            </div>
            <div>
              <Circle active={isActive} >
                <PowerSettingsNewIcon
                  style={{ fontSize: "50px" }}
                  onClick={handleClick}
                />
              </Circle>
              <h2 style={{ fontSize: "20px" }}>Salão de festa</h2>
            </div>
            <div>
              <Circle active={isActive} >
                <PowerSettingsNewIcon
                  style={{ fontSize: "50px" }}
                  onClick={handleClick}
                />
              </Circle>
              <h2 style={{ fontSize: "20px" }}>Piscinas</h2>
            </div>
            <div>
              <Circle active={isActive} >
                <PowerSettingsNewIcon
                  style={{ fontSize: "50px" }}
                  onClick={handleClick}
                />
              </Circle>
              <h2 style={{ fontSize: "20px" }}>Quadra de Esportes</h2>
            </div>
          </div>
        </ChartWrapper>
      </div>

    </div>
  );
};

export default QuadroIluminacao;
