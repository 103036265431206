import React, { useState } from "react";
import HeaderHome from "../Header/HeaderHome";
import Menu from "../Menu";
import Dispositivos from "../Dispositivos";
import ConsumoEletrico from "../Dashboard/ConsumoEletrico";
import DashboardHome from "../Dashboard/PercentGraphScreen";
import MenuIcon from "@mui/icons-material/Menu";
import { Container } from "./styles";

function Home() {
  return (
    <div style={{display:'flex', flexDirection:'column', overflow: 'auto', flex:1}}>
      <HeaderHome />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "2rem",
          flex: "1",
          gap: "2rem"
        }}
      >
        <Dispositivos />
        <DashboardHome />
      </div>
    </div>
  );
}

export default Home;
