import React, { useState } from "react";
import HeaderAddDispositivo from "../../Header/HeaderAddDispositivo";
import Menu from "../../Menu";
import dataSensores from "./sensores";
import ReactApexChart from "react-apexcharts";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Card4,
  ChartContainer,
  GraphTitle,
  DeviceCard,
  DevicesArea,
  Title,
  StatusCard,
  StatusCard2,
  StatusDot,
  StatusDot2,
  SwitchContainer,
  SwitchInput,
  SwitchSlider,
  SwitchCard,
  SwitchText,
  ApexChartCard,
  ApexChartTitle,
  RowDot,
  DotWrapper,
  DotTitle,
  RowDot2,
  DotWrapper2,
  DotTitle2,
} from "./style";

const SensorNivelAgua = () => {
  const [hoveredMonth, setHoveredMonth] = useState(null);
  const [alert, setAlert] = useState(false);

  const handleAlertSignal = () => {
    setAlert(!alert);
    // Aqui você pode adicionar lógica adicional, como exibir uma mensagem de alerta ou executar outras ações necessárias
  };
  const [selectedDevice, setSelectedDevice] = useState(null);
  // Lógica para definir as cores com base no estado de alerta
  const alertColor = alert ? "#FF0000" : "#d74747";
  const normalColor = alert ? "#58d958" : "#8ef78e";

  const handleDeviceClick = (device) => {
    setSelectedDevice(device);
  };

  const series = [
    {
      name: "Porcentagem%",
      data: [19, 30, 25, 29, 15, 38, 31, 28],
      color: "#1b75bb",
    },
  ];

  const options = {
    chart: {
      height: 245,
      type: "line",
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0.5,
      },
    },
    markers: {
      size: 1,
    },
    xaxis: {
      categories: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago"],
    },
    yaxis: {
      min: 5,
      max: 40,
    },
  };

  const ApexChart = () => {
    const series = [30];
    const options = {
      chart: {
        height: 300,
        type: "radialBar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 225,
          hollow: {
            margin: 0,
            size: "70%",
            background: "#fff",
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: "front",
            dropShadow: {
              enabled: true,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24,
            },
          },
          track: {
            background: "#fff",
            strokeWidth: "67%",
            margin: 0,
            dropShadow: {
              enabled: true,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35,
            },
          },
          dataLabels: {
            show: true,
            name: {
              offsetY: -10,
              show: true,
              color: "#666",
              fontSize: "25px",
            },
            value: {
              formatter: function (val) {
                return parseInt(val);
              },
              color: "#111",
              fontSize: "36px",
              show: true,
            },
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.5,
          gradientToColors: ["#004d93"],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 80],
        },
      },
      stroke: {
        lineCap: "round",
      },
      labels: ["Volume %"],
    };

    return (
      <div id="card">
        <div id="chart">
          <ReactApexChart
            options={options}
            series={series}
            type="radialBar"
            height={350}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="main custom-scroll" style={{ display: "flex", flex: 1 }}>
      <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <HeaderAddDispositivo />
        <div
          style={{
            display: "flex",
            flex: 1,
          }}
        >
          <DevicesArea>
            {dataSensores.map((sensor) => (
              <DeviceCard
                className={selectedDevice === sensor ? "selected" : ""}
                onClick={() => handleDeviceClick(sensor)}
              >
                <Title>{sensor.name}</Title>
                <img
                  src={sensor.image}
                  alt={sensor.name}
                  style={{
                    maxWidth: "65px",
                    maxHeight: "65px",
                    minHeight: "65px",
                    minWidth: "65px",
                    padding: ".5rem",
                  }}
                />
              </DeviceCard>
            ))}
          </DevicesArea>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              gap: "1rem",
              padding: "2rem",
              height: "600px", // Defina uma altura fixa aqui
              overflow: "auto",
            }}
          >
            <div style={{ display: "flex", gap: "1rem" }}>
              <div
                style={{
                  flex: 0.3,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: 450,
                }}
              >
                <StatusCard>
                  <RowDot>
                    <DotWrapper
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <DotTitle>Interrupções</DotTitle>
                      <StatusDot color={alertColor} label="Alerta" />
                    </DotWrapper>
                    <DotWrapper
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <DotTitle>Normal</DotTitle>
                      <StatusDot color={normalColor} label="Normal" />
                    </DotWrapper>
                  </RowDot>
                </StatusCard>
                <StatusCard2>
                  <RowDot2>
                    <DotWrapper2
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <DotTitle2>Desativado</DotTitle2>
                      <StatusDot2 color={alertColor} label="Alerta" />
                    </DotWrapper2>
                    <DotWrapper2
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <DotTitle2>Ativado</DotTitle2>
                      <StatusDot2 color={normalColor} label="Normal" />
                    </DotWrapper2>
                  </RowDot2>
                </StatusCard2>
                <SwitchCard>
                  <SwitchContainer>
                    <SwitchInput
                      type="checkbox"
                      checked={alert}
                      onChange={handleAlertSignal}
                    />
                    <SwitchSlider />
                    <SwitchText>{alert ? "Desativar" : "Ativar"}</SwitchText>
                  </SwitchContainer>
                </SwitchCard>
              </div>
              <div style={{ flex: 0.7 }}>
                <ApexChartCard>
                  <ApexChartTitle>Volume total do reservatório</ApexChartTitle>
                  <ApexChart />
                </ApexChartCard>
              </div>
            </div>

            <div style={{ display: "flex", gap: "1rem" }}>
              <div style={{ flex: 1 , backgroundColor:"#fff", borderRadius:10}}>
                <GraphTitle>Nível dos reservatórios:</GraphTitle>
    
                <ReactApexChart
                  options={options}
                  series={series}
                  type="line"
                  height={200}
                  width={"100%"}
  
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SensorNivelAgua;
