const dataSensores = [

    {
        name: "Sensor de Gás 1",
        description: "598",
        image: require("../../../assets/img/devices/SensorGas.png"),

    },
    {
        name: "Sensor de Gás 2",
        description: "599",
        image: require("../../../assets/img/devices/SensorGas.png"),

    },
    {
        name: "Sensor de Gás 3",
        description: "600",
        image: require("../../../assets/img/devices/SensorGas.png"),

    },
    {
        name: "Sensor de Gás 4",
        description: "601",
        image: require("../../../assets/img/devices/SensorGas.png"),

    },
    {
        name: "Sensor de Gás 5",
        description: "602",
        image: require("../../../assets/img/devices/SensorGas.png"),

    },
    {
        name: "Sensor de Gás 6",
        description: "603",
        image: require("../../../assets/img/devices/SensorGas.png"),

    },
    {
        name: "Sensor de Gás 7",
        description: "604",
        image: require("../../../assets/img/devices/SensorGas.png"),

    },
    {
        name: "Sensor de Gás 8",
        description: "605",
        image: require("../../../assets/img/devices/SensorGas.png"),

    },
    {
        name: "Sensor de Gás 9",
        description: "606",
        image: require("../../../assets/img/devices/SensorGas.png"),

    },
  
]

export default dataSensores;