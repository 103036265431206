import styled from "styled-components";
import "@fontsource/roboto";

export const Chart = styled.div`
  width: 643px;
  height: 258px;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
  background-color: white;
`;

export const Title = styled.h3`
  font-family: "Roboto";
  font-size: 15px;
  font-weight: 400;
`;

export const StatusCard = styled.div`
  background: white;
  box-shadow: 0px 1px 9px -7px rgb(49 49 49);
  border-radius: 10px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const StatusTitle = styled.h3`
  font-family: "Roboto";
  font-weight: 100;
  font-size: 23px;
  text-align: left;
  /* margin-top: -20px; */
  margin: 0;
`;

export const RowDot = styled.div`
  display: flex;
  position: relative;
  justify-content: space-evenly;
`;

export const StatusDot = styled.span`
  font-family: "Roboto";
  width: 72px;
  height: 72px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  /* display: flex;
  flex-direction: column; */
  /* justify-content: center;
  align-items: center; */
  /* margin-right: 80px;
  margin-left: 80px; */
  box-shadow: 0 0 8px -5px black;
  text-align: center;
  font-size: 30px;
  position: relative;
`;

export const DotTitle = styled.p`
  /* position: absolute;
    top: -30px; */
  font-size: 20px;
  font-weight: 400;
  margin: 0;
  align-self: center;
`;

export const DotWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const StatusCard2 = styled.div`
  background: white;
  box-shadow: 0px 1px 9px -7px rgb(49 49 49);
  border-radius: 10px;
  padding: 1rem;
  margin-top: 1rem;
  flex-basis: 20%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const StatusTitle2 = styled.h3`
  font-family: "Roboto";
  font-weight: 100;
  font-size: 23px;
  text-align: left;
  /* margin-top: -20px; */
  margin: 0;
`;

export const RowDot2 = styled.div`
  display: flex;
  position: relative;
  justify-content: space-evenly;
`;

export const StatusDot2 = styled.span`
  font-family: "Roboto";
  width: 72px;
  height: 72px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  /* display: flex;
  flex-direction: column; */
  /* justify-content: center;
  align-items: center; */
  /* margin-right: 80px;
  margin-left: 80px; */
  box-shadow: 0 0 8px -5px black;
  text-align: center;
  font-size: 30px;
  position: relative;
`;

export const DotTitle2 = styled.p`
  /* position: absolute;
    top: -30px; */
  font-size: 20px;
  font-weight: 400;
  margin: 0;
  align-self: center;
`;

export const DotWrapper2 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const SwitchCard = styled.div`
  width: 400px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  box-shadow: 0px 1px 9px -7px rgb(49 49 49);
  border-radius: 10px;
`;

export const SwitchContainer = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  width: 82px;
  height: 36px;
`;

export const SwitchInput = styled.input`
  display: none;

  &:checked + span:before {
    transform: translateX(46px);
    background-color: #8ef78e;
  }
`;

export const SwitchSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #353535;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 36px;

  &:before {
    position: absolute;
    content: "";
    height: 28px;
    width: 28px;
    left: 4px;
    bottom: 4px;
    background-color: #ff0000;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }

  &:hover:before {
    background-color: #ff0000;
  }
`;

export const SwitchText = styled.span`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 15px;
  text-align: center;
  color: #000000;
  margin-left: -90px;
`;

export const ChartWrapper = styled.div`
  background: white;
  width: 100%;
  box-shadow: 0px 1px 9px -7px rgb(49 49 49);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Card3 = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  gap: 1rem;
  background: white;
  box-shadow: 0px 1px 9px -7px rgb(49 49 49);
  border-radius: 10px;
`;

export const ReportTitle = styled.div`
  display: flex;
  height: 27px;
  padding: 1rem;
  justify-content: space-between;
  align-items: flex-end;
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  font-size: 23px;
  line-height: 27px;
  color: #000000;
`;

/*Alarme Ativado: senha correta */
export const ReportText1 = styled.div`
  display:flex;
  text-align: left;
  margin-left: 1rem;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 200;
  font-size: 20px;
  line-height: 23px;
  color: #000000;
`;

/*Alarme Ativado: senha correta */
export const ReportText2 = styled.div`
  display: flex;
  text-align: left;
  margin-left: 1rem;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 200;
  font-size: 20px;
  line-height: 23px;
  color: #db3030;
`;

/*Tentativa de “alarme desativado”: senha errada */
export const ReportText3 = styled.div`
  display: flex;
  text-align: left;
  margin-left: 1rem;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 200;
  font-size: 20px;
  line-height: 23px;
  color: #000000;
`;

/* Alertas */
export const AlertCount = styled.div`
  display: flex;
  text-align: right;
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  color: #db3030;
`;

export const Card4 = styled.div`  
  flex: 4;
  background: white;
  box-shadow: 0px 1px 9px -7px rgb(49 49 49);
  border-radius: 10px;
  padding: 1rem 2rem;
`;

export const GraphTitle = styled.h1`
  width: 440px;
  height: 27px;
  padding-top: 15px;
  text-align: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  color: #000000;
`;

export const ChartContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
