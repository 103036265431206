import "@fontsource/roboto";
import styled from "styled-components";

export const DivSuperior = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  /* padding: 0.8rem; */
  gap: 2rem;
`;
export const DivInferior = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
`;

export const Container2 = styled.section`
  margin-left: 25px;
`;

// CardDispositivos
export const CardDispositivos = styled.div`
  flex: 0.5;
  background: white;
  border-radius: 10px;
  text-align: left;
  padding: 20px;
  border: 1px solid #eee;
`;

// Card
export const Card = styled.div`
  background: white;
  flex: 0.5;
  border-radius: 10px;
  text-align: left;
  padding: 20px;
  border: 1px solid #eee;
`;

/* Relatório */
export const ReportTitle = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  font-size: 23px;
  line-height: 27px;
  color: #000000;
`;

/*Alarme Ativado: senha correta */
export const ReportText1 = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 200;
  font-size: 20px;
  color: red;
`;

/*Alarme Ativado: senha correta */
export const ReportText2 = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 200;
  font-size: 20px;
  color: red;
`;

/* Alertas */
export const AlertCount = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  color: #db3030;
`;

export const Dispositivo = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 100;
  font-size: 20px;
  color: grey;
`;

export const ChartWrapper = styled.div`
  flex: 0.5;
  background: white;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border: 1px solid #eee;
`;

export const Chart = styled.div`
  width: 600px;
`;
