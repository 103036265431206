const dataSensores = [

    {
        name: "Sensor 1",
        description: "598",
        image: require("../../../assets/img/devices/sensorReserv.png"),

    },
    {
        name: "Sensor 2",
        description: "599",
        image: require("../../../assets/img/devices/sensorReserv.png"),

    },
    {
        name: "Sensor 3",
        description: "600",
        image: require("../../../assets/img/devices/sensorReserv.png"),

    },
    {
        name: "Sensor 4",
        description: "601",
        image: require("../../../assets/img/devices/sensorReserv.png"),

    },
    {
        name: "Sensor 5",
        description: "602",
        image: require("../../../assets/img/devices/sensorReserv.png"),

    },
    {
        name: "Sensor 6",
        description: "603",
        image: require("../../../assets/img/devices/sensorReserv.png"),

    },
    {
        name: "Sensor 7",
        description: "604",
        image: require("../../../assets/img/devices/sensorReserv.png"),

    },
    {
        name: "Sensor 8",
        description: "605",
        image: require("../../../assets/img/devices/sensorReserv.png"),

    },
    {
        name: "Sensor 9",
        description: "606",
        image: require("../../../assets/img/devices/sensorReserv.png"),

    },
  
]

export default dataSensores;