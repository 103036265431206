import styled from "styled-components";
import '@fontsource/righteous';
import '@fontsource/roboto';

export const Devices = styled.div`
  /* padding: 10px; */
  padding: 2rem 0;
  gap: 1rem;
  height: 130px;
  /* margin-top: 10px; */
  /* margin-left: 13.5%; */
  /* border-radius: 15px; */
  /* box-shadow: 0px 3px 13px -13px rgb(49 49 49); */
  overflow-x: hidden;
  overflow-y:hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* background-color: white; */

  scrollbar-width: none;
  border: 1px solid #eee;
  -ms-overflow-style: none;

  ::-webkit-scrollbar {
    display: none;
  }
  cursor: grab;
  &:active {
    cursor: grabbing;
  }

  .devices-inner {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const DeviceFrame = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DeviceImage = styled.img`
  /* width: 120px;
  height: 120px; */
  object-fit: scale-down;
`;

export const DeviceInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
`;

export const DeviceName = styled.span`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-style: normal;
  font-size: 15px;
  color: #bdbdbd;
  text-align: center;
`;

export const DeviceDescription = styled.span`
  font-family: 'Righteous', sans-serif;
  font-weight: bold;
  font-size: 33px;
  color: #000000;
`;

export const CardDevice = styled.div`
  background-color:white;
  border-radius:20px;
  padding: 1rem ;
  display:flex;
  flex-direction:column;
  align-items:left;
  height:120px;
  gap: 1rem;
  min-width:15%;
  box-shadow: 0px 3px 13px -13px rgb(49 49 49);
`;

export const TitleDevice = styled.h3`
  font-family:'Roboto', sans-serif;
  
  color:#3f4655;
`;

export const InfoDevice = styled.h5`
font-weight: 500;
font-family:'Righteous', sans-serif;
color:gray;
padding: 0 1rem;
`;

export const ImgDevice = styled.img`
  max-width:45px;
  max-height:45px;
  min-width: 45px;
  min-height: 45px;
  background-color:#88ceeb;
  /* filter: brightness(1.2); */
  padding: 0.5rem;
  border-radius:100%;
  object-fit: scale-down;
`;

