import styled from "styled-components";

export const CardContainer = styled.div`
  
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 3px 13px -13px rgb(49 49 49);
  justify-content: center;
  position: relative;
  flex: .5;
  border: 1px solid #eee;
`;

export const ChartContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 65%;
`;