import "@fontsource/roboto";
import { style } from "@mui/system";
import styled from "styled-components";

// Estilos para a área de dispositivos
export const DevicesArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  margin-top: 0.5rem;
  height: 87vh;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

// Estilos para cada card de dispositivo
export const DeviceCard = styled.div`
  width: 171px;
  height: 165px;
  background: #fafafa;
  box-shadow: 0px 1px 9px -7px rgb(49 49 49);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;

  // Estilos da imagem
  img {
    width: 89.24px;
    height: 82px;
  }

  // Estilos quando o card é selecionado
  &.selected {
    border-color: #7cabe5;
  }
`;

export const Title = styled.h3`
  font-family: "Roboto";
  font-size: 15px;
  font-weight: 400;
`;

// Card 4
export const Card4 = styled.div`
  flex: 1;
  padding: 20px;
  margin-top: 10px;
  background: white;
  border-radius: 10px;
`;

export const ApexChartCard = styled.div`
  flex: .6;
  display: flex; 
  flex-direction: column;
  align-items: center;
  background: white;
  border-radius: 10px;
  height: 450px
`;

export const ApexChartTitle = styled.h1`
  padding-top: 10px;
  padding-left: 35px;
  display: flex;
  align-items: left;
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  font-size: 23px;
  line-height: 27px;
  color: #000000;
  text-align: center;
`;

export const GraphTitle = styled.h1`
  padding-top: 10px;
  padding-left: 35px;
  display: flex;
  align-items: left;
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  font-size: 23px;
  line-height: 27px;
  color: #000000;
`;

export const ChartContainer = styled.div`
  display: flex;
  justify-content: center;
  flex:1;
`;

export const StatusCard = styled.div`
  background: white;
  border-radius: 10px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const StatusTitle = styled.h3`
  font-family: "Roboto";
  font-weight: 100;
  font-size: 23px;
  text-align: left;
  margin: 0;
`;

export const RowDot = styled.div`
  display: flex;
  position: relative;
  justify-content: space-evenly;
`;

export const StatusDot = styled.span`
  font-family: "Roboto";
  width: 72px;
  height: 72px;
  border-radius: 50%;
  background-color: ${(props) => props.color};

  text-align: center;
  font-size: 30px;
  position: relative;
`;

export const DotTitle = styled.p`
  /* position: absolute;
    top: -30px; */
  font-size: 20px;
  font-weight: 400;
  margin: 0;
  align-self: center;
`;

export const DotWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StatusCard2 = styled.div`
  background: white;
  border-radius: 10px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const StatusTitle2 = styled.h3`
  font-family: "Roboto";
  font-weight: 100;
  font-size: 23px;
  text-align: left;
  /* margin-top: -20px; */
  margin: 0;
`;

export const RowDot2 = styled.div`
  display: flex;
  position: relative;
  justify-content: space-evenly;
`;

export const StatusDot2 = styled.span`
  font-family: "Roboto";
  width: 72px;
  height: 72px;
  border-radius: 50%;
  background-color: ${(props) => props.color};

  text-align: center;
  font-size: 30px;
  position: relative;
`;

export const DotTitle2 = styled.p`
  font-size: 20px;
  font-weight: 400;
  margin: 0;
  align-self: center;
`;

export const DotWrapper2 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const SwitchCard = styled.div`
  display: flex;
  align-items: center;
  background: white;
  justify-content: center;
  border-radius: 10px;
  padding: 10px;
`;

export const SwitchContainer = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  width: 82px;
  height: 36px;
`;

export const SwitchInput = styled.input`
  display: none;

  &:checked + span:before {
    transform: translateX(46px);
    background-color: #8ef78e;
  }
`;

export const SwitchSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #353535;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 36px;

  &:before {
    position: absolute;
    content: "";
    height: 28px;
    width: 28px;
    left: 4px;
    bottom: 4px;
    background-color: #ff0000;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }

  &:hover:before {
    background-color: #ff0000;
  }
`;

export const SwitchText = styled.span`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 15px;
  text-align: center;
  color: #000000;
  margin-left: -90px;
`;