import React, { useState } from "react";
import LogoNewin from "../../assets/img/logo/Logo_Newin-Cortada.png";
import LogoCompleta from "../../assets/img/logo/Logo_Newin_Preto.png";
import user from "../../assets/img/telalogin/user.svg";
import password from "../../assets/img/telalogin/password.svg";
import fundo from "../../assets/img/telalogin/fundo-tela-login.png";
import visibility from "../../assets/img/telalogin/visibilityon.svg";
import visibilityoff from "../../assets/img/telalogin/visibilityoff.svg";
import {
  Container,
  PaperCustom,
  BoxLogin,
  LogoText,
  LogoImage,
  Welcome,
  CustomTextFieldWrapper,
  CustomTextField,
  ImageWrapper,
  SecondImageWrapper,
  IconWrapper,
  AdornmentWrapper,
  IconFaq,
} from "./styles";
import { Grid, Paper, Button, Typography, SvgIcon } from "@mui/material";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";

const LoginScreen = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [isEmailFilled, setIsEmailFilled] = useState(false);
  const [isPasswordFilled, setIsPasswordFilled] = useState(false);
  const [email, setEmail] = useState("");

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleEmailFocus = () => {
    setIsEmailFilled(!!email);
  };

  const handleEmailBlur = () => {
    setIsEmailFilled(false);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordFocus = () => {
    setIsPasswordFilled(true);
  };

  const handlePasswordBlur = () => {
    setIsPasswordFilled(false);
  };

  return (
    <section id="login">
      <Container container>
        <PaperCustom>
          <LogoImage src={LogoNewin} />
          <BoxLogin item>
            <Welcome>Bem Vindo</Welcome>
            <div style={{ position: "relative" }}>
              <CustomTextFieldWrapper hasValue={isEmailFilled}>
                <CustomTextField
                  type="text"
                  placeholder="Usuário"
                  onFocus={handleEmailFocus}
                  onBlur={handleEmailBlur}
                  onChange={handleEmailChange}
                  imageUrl={user}
                />
              </CustomTextFieldWrapper>
            </div>
            <div style={{ position: "relative" }}>
              {/* <img
                src={showPassword ? visibility : visibilityoff}
                alt="Ícone de visibilidade"
                onClick={toggleShowPassword}
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  top: "50%",
                  right: "10px",
                  transform: "translateY(-50%)",
                  width: "20px",
                  height: "20px",
                }}
              /> */}
              <CustomTextFieldWrapper hasValue={isPasswordFilled}>
                <CustomTextField
                  type={showPassword ? "text" : "password"}
                  placeholder="Senha"
                  onFocus={handlePasswordFocus}
                  onBlur={handlePasswordBlur}
                  imageUrl={password}
                />
              </CustomTextFieldWrapper>
            </div>
            <Button
              variant="contained"
              style={{
                width: "400px",
                color: "#FFFFFF",
                fontFamily: "Righteous",
                borderRadius: "7px",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              }}
            >
              Entrar
            </Button>
            <Typography
              variant="body2"
              align="start"
              style={{
                marginTop: "25px",
                color: "#000000",
                fontSize: "15px",
                textDecoration: "underline",
                cursor: "pointer",
                fontFamily: "Roboto",
                paddingLeft: "50px",
              }}
            >
              Esqueceu sua senha?
            </Typography>
          </BoxLogin>
          <ImageWrapper src={fundo} />
        </PaperCustom>
        <SecondImageWrapper src={LogoCompleta} />
        <IconFaq>
          <ContactSupportIcon style={{ color: "#FFFFFF", fontSize: 70 }} />
        </IconFaq>
      </Container>
    </section>
  );
};

export default LoginScreen;
