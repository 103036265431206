import React, { useState } from "react";
import {
  ContainerConta,
  Nav,
  Notification,
} from "./style";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Link } from "react-router-dom";

const HeaderHome = () => {
  const [showNotifications, setShowNotifications] = useState(false);

  const handleNotificationClick = () => {
    setShowNotifications(!showNotifications);
  };

  return (
    <Nav>
      <ContainerConta>
        <p>Andrei</p>
        <h5 style={{color:"#c0c0c0", fontWeight:"400"}}>Administrador</h5>
      </ContainerConta>
      <Link to="/notificacoes">
        <Notification onClick={handleNotificationClick}>
          <NotificationsIcon
            style={{
              fontSize: "20px",
              color: "white",
              position: "unset",
            }}
          />
        </Notification>
      </Link>
    </Nav>
  );
};

export default HeaderHome;
