import {} from "@mui/material";
import styled from "styled-components";
import "@fontsource/roboto";

export const Title = styled.h3`
  font-family: "Roboto", sans-serif;
  font-size: 16px;
`;

export const AddDispositivos = styled.div`
  background-color: #004d93;
  border-radius: 50%;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, .2);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Nav = styled.div`
  position: relative;
  box-shadow: 0px 3px 9px -13px rgb(49 49 49);
  min-height: 80px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 1rem 0 0;
  background-color: white;
`;

export const Notification = styled.div`
  border-radius: 999px;
  background-color: #004d93;
  width: 35px;
  height: 35px;
  margin: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const NotificationCard = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  width: 300px;
  padding: 10px;
  margin-right: 15px;
  margin-top: 5px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  overflow-y: auto;
  max-height: 300px;
  z-index: 9999;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    background-color: transparent;
  }
`;

export const NotificationItem = styled.div`
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  cursor: pointer;
  font-family: Roboto, sans-serif;

  &:hover {
    background-color: #f0f0f0;
  }
`;

export const HoverableH4 = styled.h4`
  font-family: "Roboto", sans-serif;
  color: #0056a3;
  height: 60px;
  align-items: center;
  justify-content: center;
  display: flex;
  line-height: 60px;

  &:hover {
    cursor: pointer;
    border-radius: 7px;
    background-color: rgba(0, 86, 163, 0.2);
    backdrop-filter: blur(5px);
  }
`;
