// export const data = [







//   {
//     name: "Sensores de Reservatórios",
//     description: "578",
//     image: require("../../assets/img/devices/sensorReserv.png"),
//   },
// ];

export const dataDevices = {
  "hid":{
    "name":"HID Porta",
    "info":"598",
    "imagem": require("../../assets/img/devices/HID.png")
  },
  "sensorco":{
    "name":"Sensor CO ",
    "info":"578",
    "imagem": require("../../assets/img/devices/sensorCO.png")
  },
  "SensorGas":{
  "name": "Sensor de Gás ",
  "info":"255",
  "imagem": require("../../assets/img/devices/SensorGas.png"),
  },
  
  "TecladoPorta":{
  "name": "Teclado de Porta",
   "info": "600",
   "imagem": require("../../assets/img/devices/tecladoporta.png"),
  },
  "Hidrometro":{
    "name": "Hidrômetros",
    "info": "497",
     "imagem": require("../../assets/img/devices/hidrometros.png"),
  },
  "DIN":{
    "name": "DIN com Expensão",
    "info": "728",
   "imagem": require("../../assets/img/devices/DIN.png"),
  },
  "SensorPerimetral":{
    "name": "Sensores Perimetrais",
    "info": "578",
     "imagem": require("../../assets/img/devices/sensorPerimetral.png"),
  },
  "Reservatorios":{
    "name": "Sensores de Reservatórios",
    "info": "578",
    "imagem": require("../../assets/img/devices/sensorReserv.png"),
  },
}