import { Grid, Paper, Typography, TextField } from "@mui/material";
import styled from "styled-components";

export const Card = styled(Grid, Paper)`
  position: relative;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 3px 13px -13px rgb(49 49 49);
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  flex: .5;
  border: 1px solid #eee
`;

export const CardTitle = styled.h2`
  font-family: "Roboto";
  font-weight: 400;
  text-align: left;
  padding: 1rem 0px 0px 1.5rem;
  position: absolute;
  top: 0;
  left: 0;
`;
