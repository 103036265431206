import React, { useState } from "react";
import { Title, HoverableH4, Nav, Notification, NotificationCard, NotificationItem, AddDispositivos } from "./styles";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import notificationsData from "../notifications.json";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Link } from "react-router-dom";

const HeaderAddDispositivo = ({ title }) => {

    const [showNotifications, setShowNotifications] = useState(false);

    const handleNotificationClick = () => {
        setShowNotifications(!showNotifications);
    };

    return (
        <Nav >
            <div style={{ display: "flex", alignItems: "center" }}>
                <Link to={"/"} style={{color:"black"}}>
                    <ArrowBackIosNewIcon style={{ paddingTop: "5px", paddingRight: "10px", paddingLeft:"20px" }} />
                </Link>
                <Title>{title}</Title>
            </div>
            <Link to={"/notificacoes"}>
                <Notification>
                    <NotificationsIcon
                        style={{
                            fontSize: "35px",
                            color: "white",
                            padding: "10px",
                        }}
                    />
                    {showNotifications && (
                        <NotificationCard>
                            {notificationsData.map((notification) => (
                                <NotificationItem key={notification.title}>
                                    <h3>{notification.title}</h3>
                                    <p>{notification.description}</p>
                                </NotificationItem>
                            ))}
                            <HoverableH4>Ver mais...</HoverableH4>
                        </NotificationCard>
                    )}
                </Notification>
            </Link>
        </Nav>
    );
};

export default HeaderAddDispositivo;
