import React, { useState } from "react";
import dataHID from "./dispositivos";
import statusData from "./statusData.json";
import reportData from "./reportData.json";
import localidadeData from "./localidadeData.json";
import ReactApexChart from "react-apexcharts";
import HeaderAddDispositivo from "../../Header/HeaderAddDispositivo/index";

import { ChartContainer } from "../../../pages/Dashboard/ConsumoEletrico/styles";
import {
  DevicesArea,
  DeviceCard,
  Title,
  Card1,
  Card2,
  Card3,
  Card4,
  LogText,
  StatusText,
  AlarmText,
  IntruderText,
  FailureText,
  ReportTitle,
  ReportRed,
  AlertCount,
  LocalCard2,
  CustomButtonFromMui,
  Card2Title,
  GraphTitle,
} from "./styles";

const HID = () => {
  const [selectedDevice, setSelectedDevice] = useState(null);

  const handleDeviceClick = (device) => {
    setSelectedDevice(device);
  };

  const series = [
    {
      name: "Alarmes",
      data: [16, 18, 20, 22, 19, 17, 25],
      color: "#DB3030",
    },
  ];

  const options = {
    chart: {
      height: 245,
      type: "line",
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0.5,
      },
    },
    markers: {
      size: 1,
    },
    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
    },
    yaxis: {
      min: 5,
      max: 40,
    },
  };

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        maxHeight: "100vh",
        overflow: "hidden",
      }}
    >
      <HeaderAddDispositivo />
      <div
        style={{
          display: "flex",
          flex: 1,
          padding: "2rem",
          overflow: "auto",
          gap: "2rem",
        }}
      >
        <DevicesArea>
          {dataHID.map((device) => (
            <DeviceCard
              key={device.id}
              className={selectedDevice === device ? "selected" : ""}
              onClick={() => handleDeviceClick(device)}
            >
              <Title>{device.name}</Title>
              <img src={device.image} alt={device.name} style={{maxWidth:"65px", maxHeight:"65px", minHeight:"65px", minWidth:"65px", padding:".5rem"}}/>
            </DeviceCard>
          ))}
        </DevicesArea>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            gap: "2rem",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "2rem",
              flex: ".5",
            }}
          >
            <Card1>
              <LogText>
                <h3 className="weight--500">Log:</h3>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: ".25rem",
                  }}
                >
                  <StatusText>
                    {statusData.sistema.title}{" "}
                    <span style={{ color: statusData.sistema.color }}>
                      {statusData.sistema.status}
                    </span>
                  </StatusText>
                  <StatusText>
                    {statusData.alarme.title}{" "}
                    <span style={{ color: statusData.alarme.color }}>
                      {statusData.alarme.status}
                    </span>
                  </StatusText>
                  <StatusText>
                    {statusData.intruso.title}{" "}
                    <span style={{ color: statusData.intruso.color }}>
                      {statusData.intruso.status}
                    </span>
                  </StatusText>
                  <StatusText>
                    {statusData.falha.title}{" "}
                    <span style={{ color: statusData.falha.color }}>
                      {statusData.falha.status}
                    </span>
                  </StatusText>
                </div>
              </LogText>
            </Card1>
            <Card4>
              <h3 className="weight--500">
                Quantidade de estados Alarme mensalmente:
              </h3>
              <ChartContainer>
                <ReactApexChart
                  options={options}
                  series={series}
                  type="line"
                  height={245}
                  width={557}
                />
              </ChartContainer>
            </Card4>
          </div>
          <div
            style={{
              display: "flex",
              gap: "2rem",
              flex: ".5",
            }}
          >
            <Card3>
              <ReportTitle>
                <h3 className="weight--500">Relatório:</h3>
                <AlertCount>{reportData.alertCount}</AlertCount>
              </ReportTitle>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: ".25rem",
                }}
              >
                <p>{reportData.reportText1}</p>
                <p>{reportData.reportText2}</p>
                <ReportRed>{reportData.reportText3}</ReportRed>
                <ReportRed>{reportData.reportText4}</ReportRed>
              </div>
            </Card3>
            <Card2>
              <h3 className="weight--500">Localidade do Sensor:</h3>
              <p>{localidadeData.localidadeSensor}</p>
            </Card2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HID;
