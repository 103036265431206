import React from "react";
import ReactApexChart from "react-apexcharts";
import {
  Grid,
  Paper,
  TextField,
  Button,
  InputAdornment,
  Container,
} from "@mui/material";
import { Card, CardTitle } from "./style";
import { CardContainer, ChartContainer } from "../ConsumoEletrico/styles";
import { relativeLength } from "highcharts";

class ConsumoEletrico extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          name: "Residencial",
          data: [28, 29, 33, 36, 32, 32, 33],
          color: "#023E8A",
        },
        {
          name: "Geradores",
          data: [12, 11, 14, 18, 17, 13, 13],
          color: "#00A386",
        },
        {
          name: "Sensores",
          data: [16, 18, 20, 22, 19, 17, 16],
          color: "#48CAE4",
        },
        {
          name: "Total",
          data: [8, 10, 12, 14, 11, 9, 8],
          color: "#0CA6E8",
        },
      ],
      options: {
        chart: {
          height: 350,
          type: "line",
          dropShadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          toolbar: {
            show: false,
          },
        },
        colors: ["#023E8A", "#00A386", "#48CAE4", "#0CA6E8"],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: "smooth",
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["#f3f3f3", "transparent"],
            opacity: 0.5,
          },
        },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
          title: {
            text: "Mês",
          },
        },
        yaxis: {
          title: {
            text: "Consumo",
          },
          min: 5,
          max: 40,
        },
        legend: {
          position: "right",
          horizontalAlign: "right",
          style: {
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "15px",
            lineHeight: "40px",
          },
          markers: {
            fillColors: ["#023E8A", "#00A386", "#48CAE4", "#0CA6E8"],
          },
        },
      },
    };
  }

  render() {
    return (
      <CardContainer>
        <h2
          style={{
            fontFamily: "Roboto, sans-serif",
            fontStyle: "normal",
            fontWeight: 500,
            padding: '1rem 0px 0px 1.5rem',
            position: "absolute",
            left: "0",
            top: "0", 
          }}
        >
          Consumo de Energia Elétrica
        </h2>
            <ReactApexChart
              options={this.state.options}
              series={this.state.series}
              type="line"
              height={450}
              // width={600}
            />
      </CardContainer>
    );
  }
}

class PercentGraphic extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [20, 7, 8, 63, 82],
      options: {
        chart: {
          width: 500,
          type: "pie",
        },
        labels: [
          "Sensores de Gás",
          "Teclados de Porta",
          "Sensores de CO",
          "HID Porta",
          "ZBX DIN",
        ],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 800,
              },
              legend: {
                position: "center",
              },
            },
          },
        ],
        legend: {
          position: "bottom",
          horizontalAlign: "center",
          fontSize: "18px",
          fontFamily: "Roboto, sans-serif",
          top: "10px",
        },
      },
    };
  }

  render() {
    return (
        <Card>
          <CardTitle>Percentual de Dispositivos no condomínio</CardTitle>
            <ReactApexChart
              options={this.state.options}
              series={this.state.series}
              type="pie"
              // width={620}
              height={400}
              style={{ display: "flex", justifyContent: "center", position: "unset" }}
            />
        </Card>
    );
  }
}

const DashboardHome = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexBasis: "100%",
        justifyContent: "center",
        gap: "2rem"
      }}
    >
        <PercentGraphic />
        <ConsumoEletrico />
    </div>
  );
};

export default DashboardHome;
