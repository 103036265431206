import React from "react";
import { Container, DivSearch, DivDispositivos, TablePesquisa } from "./style";
import Menu from "../../Menu";
import HeaderHome from "../../Header/HeaderHome";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SettingsIcon from "@mui/icons-material/Settings";




const ConfigDispositivos= () => {
    return (
        <div className="main" style={{ display: "flex" }}>
          <div>
            <Menu />
          </div>
          <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
            <HeaderHome />
            <Container>
              <FilterAltIcon
                style={{
                  fontSize: "50px",
                  color: "black",
                  padding: "20px",
                  position: "fixed",
                  left: "40px",
                  cursor: "pointer",
                }}
              />
              <DivSearch>
                <SearchIcon
                  style={{
                    fontSize: "50px",
                    color: "black",
                    marginLeft: "90px",
                    marginTop: "5px",
                  }}
                />
              </DivSearch>
            </Container>
            <DivDispositivos>
              <TablePesquisa style={{ borderCollapse: "collapse", border: "none" }}>
                <tr>
                  <th
                    style={{
                      backgroundColor: "#EFEFEF",
                      border: "1px solid white",
                      borderRadius: "10px",
                    }}
                  >
                    Dispositivos
                  </th>
                  <th style={{
                    backgroundColor:"#EFEFEF",
                    border: "1px solid white",
                    borderRadius:"10px",
                  }}
                  > Total</th>
                  <th
                    style={{
                      backgroundColor: "#EFEFEF",
                      border: "1px solid white",
                      borderRadius: "10px",
                    }}
                  >
                    Área Específica 
                  </th>
                  <th
                    style={{
                      backgroundColor: "#EFEFEF",
                      border: "1px solid white",
                      borderRadius: "10px",
                    }}
                  >
                    Status
                  </th>
                  <th
                    style={{
                      backgroundColor: "#EFEFEF",
                      border: "1px solid white",
                      borderRadius: "10px",
                      
                    }}
                  >
                    Operação
                  </th>
                </tr>
                <tr style={{ borderBottom: "1px solid black" }}>
                  <td style={{ border: "none" }}>HID porta</td>
                  <td style= {{border:"none"}}>766</td>
                  <td style={{ border: "none" }}>Apartamentos</td>
                  <td style={{ border: "none",color:"green" }}>Normal</td>
                  <td style={{ border: "none", cursor: "pointer" }}>
                    <SettingsIcon />
                  </td>
                </tr>
                <tr style={{ borderBottom: "1px solid black" }}>
                  <td style={{ border: "none" }}>Sensor de Gás</td>
                  <td style={{border:"none"}}>345</td>
                  <td style={{ border: "none" }}>Apartamentos</td>
                  <td style={{ border: "none", color:"orange" }}>Pendências</td>
                  <td style={{ border: "none", cursor: "pointer" }}>
                    <SettingsIcon />
                  </td>
                </tr>
                <tr style={{ borderBottom: "1px solid black" }}>
                  <td style={{ border: "none" }}>Teclado Porta </td>
                  <td style={{border:"none"}}>654</td>
                  <td style={{ border: "none" }}>Apartamentos</td>
                  <td style={{ border: "none",color:"green" }}>Normal</td>
                  <td style={{ border: "none", cursor: "pointer" }}>
                    <SettingsIcon />
                  </td>
                </tr>
                <tr style={{ borderBottom: "1px solid black" }}>
                  <td style={{ border: "none" }}>Hidrometro</td>
                  <td style={{border:"none"}}>264</td>
                  <td style={{ border: "none" }}>Apartamentos</td>
                  <td style={{ border: "none", color:"red" }}>Falhas</td>
                  <td style={{ border: "none", cursor: "pointer" }}>
                    <SettingsIcon />
                  </td>
                </tr>
                <tr style={{borderBottom: "1px solid black"}}>
                  <td style={{ border: "none" }}>Sensor de CO</td>
                  <td style={{border:"none"}}>122</td>
                  <td style={{ border: "none" }}>Área Comun</td>
                  <td style={{ border: "none",color:"green" }}>Normal</td>
                  <td style={{ border: "none", cursor: "pointer" }}>
                    <SettingsIcon />
                  </td>
                </tr>
                <tr style={{borderBottom: "1px solid black"}}>
                    <td style={{border:"none"}}>DINQuadro</td>
                    <td style={{border:"none"}}>90</td>
                    <td style={{border:"none"}}>Área Comun</td>
                    <td style={{border:"none",color:"green"}}>Normal</td>
                    <td style={{border:"none", cursor:"pointer"}}>
                    <SettingsIcon />    
                     </td>
                </tr>
                <tr style={{borderBottom: "1px solid black"}}>
                <td style={{border:"none"}}>Sensor Perimetral</td>
                <td style={{border:"none"}}>70</td>
                <td style={{border:"none"}}>Área Comun</td>
                <td style={{border:"none",color:"green"}}>Normal</td>
                <td style={{border:"none", cursor:"pointer"}}>
                    <SettingsIcon/>
                </td> 
                </tr>
                <tr> 
                    <td style={{border:"none"}}>Sensor de Nível de água </td>
                    <td style={{border:"none"}}>457 </td>
                    <td style={{border:"none"}}>Área Comun </td>
                    <td style={{border:"none", color:"green"}}>Normal </td>
                    <td style={{border:"none",cursor:"pointer"}}>
                        <SettingsIcon/>
                    </td>
                </tr>
              </TablePesquisa>
            </DivDispositivos>
          </div>
        </div>
      );
    
}
export default ConfigDispositivos;