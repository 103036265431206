import React, { useState } from "react";
import DispositivosApartamentos from "./DispositivosApartamentos";
import Menu from "../Menu";
import HeaderHome from "../Header/HeaderHome";
import DashboardApartamentos from "./DashboardsApartamentos";
import MenuIcon from "@mui/icons-material/Menu";

function HomeApartamentos() {
  return (
    <div className="Main" style={{ display: "flex", flexDirection: "column", flex: 1 }}>
      <HeaderHome />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "2rem",
          flexBasis: "100%",
          gap: "1rem",
        }}
      >
        <DispositivosApartamentos />
        <DashboardApartamentos />
      </div>
    </div>
  );
}

export default HomeApartamentos;
