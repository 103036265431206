import React, { useState } from "react";
import HeaderAddDispositivo from "../../Header/HeaderAddDispositivo";
import Menu from "../../Menu";
import MenuIcon from "@mui/icons-material/Menu";
import {
  DeviceCard,
  DevicesArea,
  Title,
  StatusCard,
  StatusDot,
  StatusCard2,
  StatusDot2,
  Chart,
  ChartWrapper,
  ReportTitle,
  ReportText1,
  ReportText2,
  ReportText3,
  Card3,
  AlertCount,
  GraphTitle,
  Card4,
  SwitchContainer,
  SwitchInput,
  SwitchSlider,
  SwitchCard,
  SwitchText,
  ChartContainer,
  RowDot,
  DotWrapper,
  DotTitle,
  RowDot2,
  DotWrapper2,
  DotTitle2,
} from "./styles";
import dataTeclado from "./dispositivos";
import ReactApexChart from "react-apexcharts";
import reportData from "./reportData.json";

const TecladoPorta = () => {
  const [hoveredMonth, setHoveredMonth] = useState(null);
  const [alert, setAlert] = useState(false);

  const handleAlertSignal = () => {
    setAlert(!alert);
    // Aqui você pode adicionar lógica adicional, como exibir uma mensagem de alerta ou executar outras ações necessárias
  };

  const [selectedDevice, setSelectedDevice] = useState(null);

  // Lógica para definir as cores com base no estado de alerta
  const alertColor = alert ? "#FF0000" : "#d74747";
  const normalColor = alert ? "#58d958" : "#8ef78e";

  const handleDeviceClick = (sensor) => {
    setSelectedDevice(sensor);
  };

  const series = [
    {
      name: "Alarmes",
      data: [16, 18, 20, 22, 19, 17, 25],
      color: "#DB3030",
    },
  ];

  const options = {
    chart: {
      height: 245,
      type: "line",
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0.5,
      },
    },
    markers: {
      size: 1,
    },
    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
    },
    yaxis: {
      min: 5,
      max: 40,
    },
  };

  return (
    <div
      className="main custom-scroll"
      style={{ display: "flex", flex: 1, flexDirection: "column" }}
    >
      <HeaderAddDispositivo />
      <div
        style={{
          display: "flex",
          flex: "1",
          gap: "2rem",
          padding: "2rem",
          overflow: "auto"
        }}
      >
        <DevicesArea>
          {dataTeclado.map((sensor) => (
            <DeviceCard
              className={selectedDevice === sensor ? "selected" : ""}
              onClick={() => handleDeviceClick(sensor)}
            >
              <Title>{sensor.name}</Title>
              <img
                src={sensor.image}
                alt={sensor.name}
                style={{
                  maxWidth: "65px",
                  maxHeight: "65px",
                  minHeight: "65px",
                  minWidth: "65px",
                  padding: ".5rem",
                }}
              />
            </DeviceCard>
          ))}
        </DevicesArea>
        
        <div style={{ display: "flex", flexDirection: "column", flex: 1, gap: "2rem" }}>
          <div style={{ display: "flex", flexDirection: "row", gap: "2rem" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                justifyContent: "space-between",
              }}
            >
              <StatusCard>
                <DotWrapper>
                  <p className="color--grey">Interrupções</p>
                  <StatusDot color={alertColor} label="Alerta" />
                </DotWrapper>
                <DotWrapper
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <p className="color--grey">Normal</p>
                  <StatusDot color={normalColor} label="Normal" />
                </DotWrapper>
              </StatusCard>
              <StatusCard>
                <DotWrapper
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <p className="color--grey">Desativado</p>
                  <StatusDot color={alertColor} label="Alerta" />
                </DotWrapper>
                <DotWrapper
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <p className="color--grey">Ativado</p>
                  <StatusDot color={normalColor} label="Normal" />
                </DotWrapper>
              </StatusCard>
              <SwitchCard>
                <SwitchContainer>
                  <SwitchInput
                    type="checkbox"
                    checked={alert}
                    onChange={handleAlertSignal}
                  />
                  <SwitchSlider />
                </SwitchContainer>
              </SwitchCard>
            </div>
            <ChartWrapper>
                <ReactApexChart
                    options={options}
                    series={series}
                    type="line"
                    height={400}
                    width={"100%"}
                  />
            </ChartWrapper>
          </div>
          <Card3>
            <ReportTitle>
              <h3>Relatório:</h3>
              <AlertCount>{reportData.alertCount}</AlertCount>
            </ReportTitle>
            <div className="flex column basis--25">
              <p>{reportData.reportText1}</p>
              <p className="color--red">{reportData.reportText2}</p>
              <p className="color--red">{reportData.reportText3}</p>
            </div>
          </Card3>
        </div>
      </div>
    </div>
  );
};

export default TecladoPorta;
