import React, { useState } from "react";
import {
  Title,
  HoverableH4,
  Nav,
  Notification,
  NotificationCard,
  NotificationItem,
  AddDispositivos,
} from "./style";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import notificationsData from "../notifications.json";
import { Link } from "react-router-dom";

const HeaderAddDispositivo = () => {
  const [showNotifications, setShowNotifications] = useState(false);

  const handleNotificationClick = () => {
    setShowNotifications(!showNotifications);
  };

  return (
    <Nav>
      <div style={{ display: "flex", alignItems: "center", gap: ".25rem"  }}>
      <h4 style={{fontWeight:"500", lineHeight: 0}}>Adicionar Dispositivo</h4>
        <AddDispositivos>
          <Link
            to={"/adddevices"}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textDecoration: "none",
              color: "white",
            }}
          >
            <AddCircleIcon
              style={{ fontSize: "22px", padding: ".4rem", cursor: "pointer", }}
            />
          </Link>
        </AddDispositivos>
      </div>

      <Link to={"/notificacoes"} style={{ textDecoration: "none" }}>
        <Notification onClick={handleNotificationClick}>
          <NotificationsIcon
            style={{
              fontSize: "20px",
              color: "white",
              position: "unset",
            }}
          />
          {showNotifications && (
            <NotificationCard>
              {notificationsData.map((notification) => (
                <NotificationItem key={notification.title}>
                  <h3>{notification.title}</h3>
                  <p>{notification.description}</p>
                </NotificationItem>
              ))}
              <HoverableH4>Ver mais...</HoverableH4>
            </NotificationCard>
          )}
        </Notification>
      </Link>
    </Nav>
  );
};

export default HeaderAddDispositivo;
