import React from "react";
import ReactApexChart from "react-apexcharts";
import { CardContainer, ChartContainer } from "./styles";

class ConsumoEletrico extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          name: "Residencial",
          data: [28, 29, 33, 36, 32, 32, 33],
          color: "#023E8A",
        },
        {
          name: "Geradores",
          data: [12, 11, 14, 18, 17, 13, 13],
          color: "#00A386",
        },
        {
          name: "Sensores",
          data: [16, 18, 20, 22, 19, 17, 16],
          color: "#48CAE4",
        },
        {
          name: "Total",
          data: [8, 10, 12, 14, 11, 9, 8],
          color: "#0CA6E8",
        },
      ],
      options: {
        chart: {
          height: 350,
          type: "line",
          dropShadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          toolbar: {
            show: false,
          },
        },
        colors: ["#023E8A", "#00A386", "#48CAE4", "#0CA6E8"],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: "smooth",
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["#f3f3f3", "transparent"],
            opacity: 0.5,
          },
        },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
          title: {
            text: "Mês",
          },
        },
        yaxis: {
          title: {
            text: "Consumo",
          },
          min: 5,
          max: 40,
        },
        legend: {
          position: "right",
          horizontalAlign: "right",
          style: {
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "15px",
            lineHeight: "40px",
          },
          markers: {
            fillColors: ["#023E8A", "#00A386", "#48CAE4", "#0CA6E8"],
          },
        },
      },
    };
  }

  render() {
    return (
      <CardContainer
        style={{
          textAlign: "left",
          alignItems: "center",
          justifyContent: "center",
          fontFamily: "'Righteous', cursive",
        }}
      >
        <h2
          style={{
            fontFamily: 'Roboto, sans-serif',
            fontStyle: "normal",
            fontWeight:500,
            fontSize: "23px",
            lineHeight: "32px",
            paddingLeft:'30px',
          }}
        >
          Consumo de Energia Elétrica
        </h2>
        <ChartContainer>
          <div
            style={{
              marginTop: "20%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <ReactApexChart
              options={this.state.options}
              series={this.state.series}
              type="line"
              height={463}
              width={600}
            />
          </div>
        </ChartContainer>
      </CardContainer>
    );
  }
}

export default ConsumoEletrico;