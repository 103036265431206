import { } from "@mui/material";
import styled from "styled-components";
import LogoNewin from "../../assets/img/logo/logonewincinza.png";

export const DivRegra = styled.div`
display: flex;
background-color: #FAFAFA ;
width: 234px;
height: 87px;
box-shadow: 0 1px 11px -7px #404040;
border-radius: 10px ;
justify-content: space-around;
margin: 20px;
align-items: center;
justify-content: center;
`;
export const PageWrapper = styled.div`
  display: flex;
  flex-basis: 100%;
  background-image: url(${LogoNewin});
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
 
  

 
`;
