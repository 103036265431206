import React, { useState } from "react";
import Menu from "../../../Menu";
import HeaderTitle from "../../../Header/HeaderTitulo";
import ReactApexChart from "react-apexcharts";
import StatusData from "./statusReport.json";
import percentData from "./percentsData.json";
import MenuIcon from "@mui/icons-material/Menu";

import {
  Card1,
  Card2,
  Card3,
  CircleTemp,
  CircleUmi,
  CircleOx,
  CircleNumber,
  DotWrapper,
  DotTitle,
  StatusDot,
  StatusText,
  Title1,
  TitleGraphs,
} from "./style";

const QuadroPressurizacao = () => {
  const [hoveredMonth, setHoveredMonth] = useState(null);
  const [alert, setAlert] = useState(false);

  const handleAlertSignal = () => {
    setAlert(!alert);
    // Aqui você pode adicionar lógica adicional, como exibir uma mensagem de alerta ou executar outras ações necessárias
  };
  const [selectedDevice, setSelectedDevice] = useState(null);
  // Lógica para definir as cores com base no estado de alerta
  const alertColor = alert ? "#FF0000" : "#d74747";
  const normalColor = alert ? "#58d958" : "#8ef78e";

  const handleDeviceClick = (sensor) => {
    setSelectedDevice(sensor);
  };

  const series2 = [
    {
      name: "Ventoinha 1",
      data: [40, 33, 35, 25, 28, 31, 28],
      color: "#00B4D8",
    },
    {
      name: "Ventoinha 2",
      data: [75, 46, 22, 73, 76, 23, 44],
      color: "#006556",
    },
    {
      name: "Ventoinha 3",
      data: [45, 56, 76, 64, 67, 45, 42],
      color: "#00A386",
    },
  ];

  const options2 = {
    chart: {
      height: 245,
      type: "line",
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0.5,
      },
    },
    markers: {
      size: 1,
    },
    xaxis: {
      categories: ["06h", "10h", "12h", "16h", "18h", "20h", "24h"],
      title: {
        text: "Horário",
        style: {
          //   fontSize: "13px",
          fontFamily: "Roboto",
        },
      },
    },
    yaxis: {
      min: 10,
      max: 80,
      title: {
        text: "Velocidade Média(Km)",
        style: {
          //   fontSize: "13px",
          fontFamily: "Roboto",
        },
      },
    },
  };

  const series = [
    {
      name: "Pressão",
      data: [5, 3, 4, 4, 3.5, 4.76, 5],
      color: "#023E8A",
    },
  ];

  const options = {
    chart: {
      height: 245,
      type: "line",
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0.5,
      },
    },
    markers: {
      size: 1,
    },
    xaxis: {
      categories: ["06h", "10h", "12h", "16h", "18h", "20h", "24h"],
    },
    yaxis: {
      min: 1,
      max: 7,
    },
  };

  

  const CloseMenu = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        height: 80,
      }}
    >
      <button
      
        style={{
          display: "flex",
          width: "100%",
          border: "none",
          boxShadow: "0px 10px 10px -5px rgba(0, 0, 0, 0.15)",
          alignItems: "center",
          backgroundColor: "transparent",
          cursor: "pointer",
        }}
      >
        <MenuIcon />
      </button>
    </div>
  );

  return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          gap: "2rem",
        }}
      >
        <HeaderTitle title="Quadro de Pressurização das Escadas" />
        <div
          style={{
            display: "flex",
            gap: "1rem",
            flexDirection: "row",
            padding: "1rem",
          }}
        >
          <Card1>
            <Title1>Pressão nas Escadas</Title1>
            <div style={{ display: "flex", padding: "1rem" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <ReactApexChart
                  options={options}
                  series={series}
                  type="line"
                  height={200}
                  width={600}
                />
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <DotWrapper
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "2rem",
                    }}
                  >
                    <DotTitle>Pressão Alterada</DotTitle>
                    <StatusDot color={alertColor} label="Alerta" />
                  </DotWrapper>
                  <DotWrapper
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "2rem",
                    }}
                  >
                    <DotTitle>Presão Normal</DotTitle>
                    <StatusDot color={normalColor} label="Normal" />
                  </DotWrapper>
                </div>
              </div>
            </div>
          </Card1>
          <Card2>
            <Title1>Funcionamento das Ventoinhas</Title1>
            <div style={{ display: "flex", padding: "1rem" }}>
              <div>
                <ReactApexChart
                  options={options2}
                  series={series2}
                  type="line"
                  height={300}
                  width={450}
                />
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", paddingLeft: "5rem" }}>
                  <DotWrapper
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "1rem",
                    }}
                  >
                    <DotTitle>Desativado</DotTitle>
                    <StatusDot color={alertColor} label="Alerta" />
                  </DotWrapper>
                  <DotWrapper
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "1rem",
                    }}
                  >
                    <DotTitle>Ativado</DotTitle>
                    <StatusDot color={normalColor} label="Normal" />
                  </DotWrapper>
                </div>
                <div style={{ paddingLeft: "4rem", paddingTop: "1rem" }}>
                  <StatusText>
                    {StatusData.ventoinhas.title}{" "}
                    <span style={{ color: StatusData.ventoinhas.color }}>
                      {StatusData.ventoinhas.status}
                    </span>
                  </StatusText>
                  <StatusText>
                    {StatusData.defeito.title}{" "}
                    <span style={{ color: StatusData.defeito.color }}>
                      {StatusData.defeito.status}
                    </span>
                  </StatusText>
                  <StatusText>
                    {StatusData.fluxo.title}{" "}
                    <span style={{ color: StatusData.fluxo.color }}>
                      {StatusData.fluxo.status}
                    </span>
                  </StatusText>
                  <StatusText>
                    {StatusData["resumo de falha"].title}{" "}
                    <span
                      style={{ color: StatusData["resumo de falha"].color }}
                    >
                      {StatusData["resumo de falha"].status}
                    </span>
                  </StatusText>
                </div>
              </div>
            </div>
          </Card2>
        </div>
        <div>
          <Card3>
            <Title1> Detecção de Incêndios</Title1>
            <div
              style={{
                display: "flex",
                alignItems: "flex",
                justifyContent: "space-around",
                padding: "1.5rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex" }}>
                  <DotWrapper
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "0.5rem",
                    }}
                  >
                    <DotTitle>Temperat. Alterada</DotTitle>
                    <StatusDot color={alertColor} label="Alerta" />
                  </DotWrapper>
                  <DotWrapper
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "0.5rem",
                    }}
                  >
                    <DotTitle>Temperat. Normal</DotTitle>
                    <StatusDot color={normalColor} label="Normal" />
                  </DotWrapper>
                </div>
                <div style={{ display: "flex" }}>
                  <DotWrapper
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "0.5rem",
                    }}
                  >
                    <DotTitle>Umidade Alterada</DotTitle>
                    <StatusDot color={alertColor} label="Alerta" />
                  </DotWrapper>
                  <DotWrapper
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "0.5rem",
                    }}
                  >
                    <DotTitle>Umidade Normal</DotTitle>
                    <StatusDot color={normalColor} label="Normal" />
                  </DotWrapper>
                </div>
                <div style={{ display: "flex" }}>
                  <DotWrapper
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "0.5rem",
                    }}
                  >
                    <DotTitle>Oxigênio Alterada</DotTitle>
                    <StatusDot color={alertColor} label="Alerta" />
                  </DotWrapper>
                  <DotWrapper
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "0.5rem",
                    }}
                  >
                    <DotTitle>Oxigênio Normal</DotTitle>
                    <StatusDot color={normalColor} label="Normal" />
                  </DotWrapper>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  flexBasis: "50%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <TitleGraphs>Temperatura</TitleGraphs>
                  <CircleTemp>
                    <CircleNumber>{percentData.temperatura}°C</CircleNumber>
                  </CircleTemp>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <TitleGraphs>Umidade</TitleGraphs>
                  <CircleUmi>
                    <CircleNumber>{percentData.umidade}%</CircleNumber>
                  </CircleUmi>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <TitleGraphs>Nível de Oxigênio</TitleGraphs>
                  <CircleOx>
                    <CircleNumber>{percentData.oxigenio}%</CircleNumber>
                  </CircleOx>
                </div>
              </div>
            </div>
          </Card3>
        </div>
      </div>
  );
};

export default QuadroPressurizacao;
