import React from "react";
import HeaderTitle from "../Header/HeaderTitulo/index";
import { Button, FormContainer, PageWrapper, TextInput,  } from "./styles";

const AddDevices = () => {
  return (
    <div style={{ display: "flex", flex:1 }}>
      <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <HeaderTitle title="Adicionar Dispositivos" />
        <div style={{ display: "flex", flexBasis: "100%", alignItems: "center", justifyContent: "center"}}>
          <PageWrapper>
            <FormContainer>
              <TextInput placeholder="Nome do Dispositivo" />
            </FormContainer>
            <FormContainer>
              <TextInput placeholder="Localidade do Dispositivo" />
            </FormContainer>
            <FormContainer>
              <TextInput placeholder="Área Específica" />
            </FormContainer>
            <Button>Adicionar</Button>
          </PageWrapper>
        </div>
      </div>
    </div>
  );
};

export default AddDevices;
