import React, { useState } from "react";
import Menu from "../Menu";
import HeaderHome from "../Header/HeaderHome";
import DispositivosAreas from "./DispositivosAreas";
import DashboardAreas from "./DashboardAreas";
import MenuIcon from "@mui/icons-material/Menu";

function HomeAreas() {





  return (
    <div style={{ display: 'flex', flexDirection: 'column', overflow: 'auto', flex: 1 }}>
      <HeaderHome />
      <div style={{ display: "flex", flexDirection: "column", padding: '1rem', flexBasis: '100%', gap: "1rem" }}>
        <DispositivosAreas />
        <DashboardAreas />
      </div>
    </div>
  );
}

export default HomeAreas;
