import React, { useState } from "react";
import Menu from "../../Menu";
import HeaderHome from "../../Header/HeaderHome";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Container, DivSearch, DivDispositivos, TablePesquisa } from "./style";
import SettingsIcon from "@mui/icons-material/Settings";
import { color } from "highcharts";
import { Collapse, none } from "@mui/material";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";

const QuadrosEletricos = () => {

  return (
    <div className="main" style={{ display: "flex", flex: 1, flexDirection: "column", }}>

      <HeaderHome />
      <div style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        alignItems: "center",
        justifyCenter: "center",
      }}>
        <Container>
          <FilterAltIcon
            style={{
              fontSize: "50px",
              color: "black",
              padding: "20px",
              cursor: "pointer",
            }}
          />
          <DivSearch>
            <SearchIcon
              style={{
                fontSize: "50px",
                color: "black",
                marginLeft: "90px",
                marginTop: "5px",
              }}
            />
          </DivSearch>
        </Container>
        <DivDispositivos>
          <TablePesquisa style={{ borderCollapse: "collapse", border: "none" }}>
            <tr>
              <th
                style={{
                  backgroundColor: "#EFEFEF",
                  border: "1px solid white",
                  borderRadius: "10px",
                }}
              >
                Dispositivos
              </th>
              <th
                style={{
                  backgroundColor: "#EFEFEF",
                  border: "1px solid white",
                  borderRadius: "10px",
                }}
              >
                Área Específica
              </th>
              <th
                style={{
                  backgroundColor: "#EFEFEF",
                  border: "1px solid white",
                  borderRadius: "10px",
                }}
              >
                Status
              </th>
              <th
                style={{
                  backgroundColor: "#EFEFEF",
                  border: "1px solid white",
                  borderRadius: "10px",
                }}
              >
                Operação
              </th>
            </tr>
            <tr style={{ borderBottom: "1px solid black" }}>
              <td style={{ border: "none" }}>Quadro de Iluminação</td>
              <td style={{ border: "none" }}>Geral</td>
              <td style={{ border: "none" }}>Normal</td>
              <td style={{ border: "none", cursor: "pointer" }}>
                <Link
                  to={"/quadroiluminacao"}
                  style={{ textDecoration: "none", color: "#B8B8B8" }}
                >
                  <SettingsIcon />
                </Link>
              </td>
            </tr>
            <tr style={{ borderBottom: "1px solid black" }}>
              <td style={{ border: "none" }}>Quadro de Bombas</td>
              <td style={{ border: "none" }}>Geral</td>
              <td style={{ border: "none" }}>Pendências</td>
              <td style={{ border: "none", cursor: "pointer" }}>
                <Link
                  to={"/quadrobomba"}
                  style={{ textDecoration: "none", color: "#B8B8B8" }}
                >
                  <SettingsIcon />
                </Link>
              </td>
            </tr>
            <tr style={{ borderBottom: "1px solid black" }}>
              <td style={{ border: "none" }}>
                Quadro de Pressurizaçãoda Escada
              </td>
              <td style={{ border: "none" }}>Apartamentos</td>
              <td style={{ border: "none" }}>Normal</td>
              <td style={{ border: "none", cursor: "pointer" }}>
                <Link
                  to={"/quadropressurizacao"}
                  style={{ textDecoration: "none", color: "#B8B8B8" }}
                >
                  <SettingsIcon />
                </Link>
              </td>
            </tr>
            <tr style={{ borderBottom: "1px solid black" }}>
              <td style={{ border: "none" }}>Quadro de Exaustão</td>
              <td style={{ border: "none" }}>Apartamentos</td>
              <td style={{ border: "none" }}>Falhas</td>
              <td style={{ border: "none", cursor: "pointer" }}>
                <Link
                  to={"/quadroexaustao"}
                  style={{ textDecoration: "none", color: "#B8B8B8" }}
                >
                  <SettingsIcon />
                </Link>
              </td>
            </tr>
            <tr>
              <td style={{ border: "none" }}>Quadro de Incêndio</td>
              <td style={{ border: "none" }}>Geral</td>
              <td style={{ border: "none" }}>Normal</td>
              <td style={{ border: "none", cursor: "pointer" }}>
                <Link
                  to={"/quadroincendio"}
                  style={{ textDecoration: "none", color: "#B8B8B8" }}
                >
                  <SettingsIcon />
                </Link>
              </td>
            </tr>
          </TablePesquisa>
        </DivDispositivos>

      </div>
    </div>
  );
};

export default QuadrosEletricos;
