import React, { useState } from "react";
import {
  HoverableH4,
  Nav,
  Notification,
  NotificationCard,
  NotificationItem,
} from "./style";
import { Title, cIcon } from "./style";
import NotificationsIcon from "@mui/icons-material/Notifications";
import notificationsData from "../notifications.json";
import { Link } from "react-router-dom";

const HeaderNotification = () => {
  const [showNotifications, setShowNotifications] = useState(false);

  const handleNotificationClick = () => {
    setShowNotifications(!showNotifications);
  };

  return (
    <Nav>
      <Title>Notificações</Title>

      <Notification>
        <cIcon />
        {showNotifications && (
          <NotificationCard>
            {notificationsData.map((notification) => (
              <NotificationItem key={notification.title}>
                <h3>{notification.title}</h3>
                <p>{notification.description}</p>
              </NotificationItem>
            ))}
            <HoverableH4>Ver mais...</HoverableH4>
          </NotificationCard>
        )}
      </Notification>
    </Nav>
  );
};

export default HeaderNotification;
