import styled from "styled-components";
import '@fontsource/righteous';


export const Card1 = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 55%;
  margin-left: 2rem;
  border-radius: 10px;
  background: white;
  box-shadow: 10px 10px 8px 0px rgba(219, 219, 219, 1);
`;

export const Title1 = styled.div`
  display: flex;
  padding: 1rem;
  color: #000;
  font-size: 23px;
  font-family: "Roboto";
  font-weight: bold;
  width:100%;
`;

export const Card2 = styled.div`
  display: flex;
  flex-direction:column;
  flex-basis: 50%;
  margin-left: 2rem;
  margin-bottom: 1rem;
  border-radius: 10px;
  background: white;
  box-shadow: 10px 10px 8px 0px rgba(219, 219, 219, 1);
`;

export const Card3 = styled.div`
  display: flex;
  flex-basis: 40%;
  border-radius: 10px;
  background: white;
  box-shadow: 10px 10px 8px 0px rgba(219, 219, 219, 1);
`;

export const Card4 = styled.div`
  display: flex;
  flex-direction:column;
  flex-basis: 50%;
  margin-right: 2rem;
  margin-bottom: 1rem;
  border-radius: 10px;
  background: white;
  box-shadow: 10px 10px 8px 0px rgba(219, 219, 219, 1);
`;

export const StatusDot2 = styled.span`
  font-family: "Roboto";
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  /* display: flex;
  flex-direction: column; */
  /* justify-content: center;
  align-items: center; */
  /* margin-right: 80px;
  margin-left: 80px; */
  border: 5px solid #353535;
  text-align: center;
  font-size: 30px;
  position: relative;
`;

export const DotTitle2 = styled.p`
  /* position: absolute;
    top: -30px; */
  font-size: 15px;
  font-weight: 400;
  margin: 0;
  align-self: center;
`;

export const DotWrapper2 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  margin:20px;
`;

export const RowDot2 = styled.div`
  display: flex;
  position: relative;
  justify-content: space-evenly;
`;

export const StatusText = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  text-align: left;
  /* color: white; */
`;

export const TitlePressao = styled.p`

`;

export const ChartPressao = styled.div`
  margin:10px;
`;


export const InnerCircle = styled.div`
  display: flex;
  width: 90px;
  height: 90px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const ThirdCircle = styled.div`
  display: flex;
  width: 70px;
  height: 70px;
  align-items: center;
  justify-content: center;
  background: #242424;
  border-radius: 50%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const TextWrapper = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 40px;
  text-align: center;
  color: white;
`;

export const Reports = styled.div`
text-align:left;
padding-left:1rem;
line-height:2rem;
font-size:20px;
color:red;
font-family: "Roboto";
`;

export const Circle = styled.div`
width:100px;
height:100px;
color: ${props => props.active ? 'red' : 'black'};
border-radius:50%;
border: 10px solid #004D93 ; 
display:flex;
align-items:center;
justify-content:center;
cursor: pointer;
`;

export const Circle2 = styled.div`
width:110px;
height:110px;
background-color: #242424;
border-radius:50%;
border: 10px solid #006556; 
display:flex;
align-items:center;
justify-content:center;
cursor: pointer;
`;

export const InnerCircle2 = styled.div`
  display: flex;
  width: 180px;
  height: 180px;
  align-items: center;
  justify-content: center;
  // background: #00b4d8;
  border-radius: 50%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const ThirdCircle2 = styled.div`
  display: flex;
  width: 147px;
  height: 147px;
  align-items: center;
  justify-content: center;
  background: #242424;
  border-radius: 50%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const TextWrapper2 = styled.div`
  width: 105px;
  height: 47px;
  font-family: 'Righteous';
  font-style: normal;
  font-weight: 400;
  font-size: 45px;
  line-height: 40px;
  text-align: center;
  color: white;
`;

export const ChartWrapper = styled.div`
  /* display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 200px;
  background-color: #242424;
  border-radius: 50%; */
  /* box-shadow:10px 10px 8px 0px rgba(219, 219, 219, 1); */
`;

export const Temp = styled.h2`
  color:white;
  font-size:30px;
  font-family: 'Righteous', sans-serif;
`;
