import React, { useRef, useState } from "react";
import {
  Devices,
  DeviceFrame,
  DeviceImage,
  DeviceName,
  DeviceDescription,
  DeviceInfo,
  CardDevice,
  ImgDevice,
  TitleDevice,
  InfoDevice,
} from "./styles";
import {dataDevices} from "./dispositivos";

const Dispositivos = () => {
  const devicesRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [dragStartX, setDragStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = (event) => {
    setIsDragging(true);
    setDragStartX(event.clientX - devicesRef.current.offsetLeft);
    setScrollLeft(devicesRef.current.scrollLeft);
  };

  const handleMouseMove = (event) => {
    if (!isDragging) return;
    const x = event.clientX - devicesRef.current.offsetLeft;
    const scrollX = x - dragStartX;
    devicesRef.current.scrollLeft = scrollLeft - scrollX;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
  };


  return (
    <Devices
      ref={devicesRef}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseLeave}
    >
      <CardDevice>
        <div style={{display:"flex", gap:"2rem", alignItems:"center",padding:".3rem .5rem"}}>
          <ImgDevice src={dataDevices.hid.imagem} />
          <TitleDevice>{dataDevices.hid.name}</TitleDevice>
        </div>
        <InfoDevice>Dispositivos: {dataDevices.hid.info}</InfoDevice>
      </CardDevice>
      <CardDevice>
        <div style={{display:"flex", gap:"2rem", alignItems:"center",padding:".3rem .5rem"}}>
          <ImgDevice src={dataDevices.sensorco.imagem} />
          <TitleDevice>{dataDevices.sensorco.name}</TitleDevice>
        </div>
        <InfoDevice>Dispositivos: {dataDevices.sensorco.info}</InfoDevice>
      </CardDevice>
      <CardDevice>
        <div style={{display:"flex", gap:"2rem", alignItems:"center",padding:".3rem .5rem"}}>
          <ImgDevice src={dataDevices.SensorGas.imagem} />
          <TitleDevice>{dataDevices.SensorGas.name}</TitleDevice>
        </div>
        <InfoDevice>Dispositivos: {dataDevices.SensorGas.info}</InfoDevice>
      </CardDevice>
      <CardDevice>
        <div style={{display:"flex", gap:"2rem", alignItems:"center",padding:".3rem .5rem"}}>
          <ImgDevice src={dataDevices.Hidrometro.imagem} />
          <TitleDevice>{dataDevices.Hidrometro.name}</TitleDevice>
        </div>
        <InfoDevice>Dispositivos: {dataDevices.Hidrometro.info}</InfoDevice>
      </CardDevice>
      <CardDevice>
        <div style={{display:"flex", gap:"2rem", alignItems:"center",padding:".3rem .5rem"}}>
          <ImgDevice src={dataDevices.SensorPerimetral.imagem} />
          <TitleDevice>{dataDevices.SensorPerimetral.name}</TitleDevice>
        </div>
        <InfoDevice>Dispositivos: {dataDevices.SensorPerimetral.info}</InfoDevice>
      </CardDevice> <CardDevice>
        <div style={{display:"flex", gap:"2rem", alignItems:"center",padding:".3rem .5rem"}}>
          <ImgDevice src={dataDevices.DIN.imagem} />
          <TitleDevice>{dataDevices.DIN.name}</TitleDevice>
        </div>
        <InfoDevice>Dispositivos: {dataDevices.DIN.info}</InfoDevice>
      </CardDevice> <CardDevice>
        <div style={{display:"flex", gap:"2rem", alignItems:"center",padding:".3rem .5rem"}}>
          <ImgDevice src={dataDevices.TecladoPorta.imagem} />
          <TitleDevice>{dataDevices.TecladoPorta.name}</TitleDevice>
        </div>
        <InfoDevice>Dispositivos: {dataDevices.TecladoPorta.info}</InfoDevice>
      </CardDevice> 
      <CardDevice>
        <div style={{display:"flex", gap:"2rem", alignItems:"center",padding:".3rem .5rem"}}>
          <ImgDevice src={dataDevices.Reservatorios.imagem} />
          <TitleDevice>{dataDevices.Reservatorios.name}</TitleDevice>
        </div>
        <InfoDevice>Dispositivos: {dataDevices.Reservatorios.info}</InfoDevice>
      </CardDevice> 
    </Devices>
  );
};

export default Dispositivos;
