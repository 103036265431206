import styled from "styled-components";
import "@fontsource/roboto";

export const ChartWrapper = styled.div`
  /* width: 90%; */
  /* margin-top: 11%; */
  box-shadow: 0px 1px 9px -7px rgb(49 49 49);
  border-radius: 10px;
  /* margin-left: 20px; */
  display: flex;
  flex-direction: column;
  width: 60%;
  position: relative;
  background: white;
  justify-content: center;
  padding: 1rem 2rem;
`;

export const ChartDispositivos = styled.div`
  display: flex;
  flex-direction: column;
  /* margin-top: 50px;
  margin-left: 260px; */
  /* width: 695px;
  height: 390px; */
  box-shadow: 0px 1px 9px -7px rgb(49 49 49);
  border-radius: 10px;
  flex-basis: 80%;
  background: white;
  padding: 1rem 2rem;
`;

export const ChartTitle = styled.h2`
  margin: 0;
  text-align: left;
  font-weight: 400;
`;

export const VerTodosContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #a4a2a2;
  cursor: pointer;
`;

export const VerTodosText = styled.h5`
  margin-right: 0;
`;

export const ArrowIcon = styled.span`
  color: #a4a2a2;
`;

export const StatusCard = styled.div`
  /* width: 655px;
  height: 150px; */
  /* margin-left: 260px;
  margin-top: 30px; */
  background: white;
  box-shadow: 0px 1px 9px -7px rgb(49 49 49);
  border-radius: 10px;
  padding: 1rem 2rem;
  flex-basis: 20%;
  gap: 1rem;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const StatusTitle = styled.h3`
  font-family: "Roboto";
  font-weight: 100;
  font-size: 23px;
  text-align: left;
  /* margin-top: -20px; */
  margin: 0;
`;

export const RowDot = styled.div`
  display: flex;
  position: relative;
  justify-content: space-evenly;
`;

export const StatusDot = styled.span`
  font-family: "Roboto";
  width: 72px;
  height: 72px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  box-shadow: 0 0 8px -5px black;
  text-align: center;
  font-size: 30px;
  position: relative;
  `;

export const DotTitle = styled.p`
    /* position: absolute;
    top: -30px; */
    font-size: 20px;
    font-weight: 400;
    margin: 0;
    align-self: center;
`;

export const DotWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: .25rem;
`;
