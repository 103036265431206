import React, { useState } from "react";
import reportData from "./reportData.json";
import dispositivos from "./dispositivos.json";
import Menu from "../../Menu";
import HeaderHome from "../../Header/HeaderHome";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Card,
  CardDispositivos,
  ReportTitle,
  ReportText1,
  ReportText2,
  AlertCount,
  Container1,
  Container2,
  Dispositivo,
  DivSuperior,
  DivInferior,
  ChartWrapper,
  Chart,
} from "./styles";

const Hidrometro = () => {
  const [hoveredMonth, setHoveredMonth] = useState(null);

  const data = [
    { month: "Jan", alerts: 37 },
    { month: "Fev", alerts: 31 },
    { month: "Mar", alerts: 27 },
    { month: "Abr", alerts: 22 },
    { month: "Mai", alerts: 21 },
    { month: "Jun", alerts: 21 },
    { month: "Jul", alerts: 21 },
    { month: "Ago", alerts: 1 },
    { month: "Set", alerts: 20 },
    { month: "Out", alerts: 19 },
    { month: "Nov", alerts: 16 },
    { month: "Dez", alerts: 16 },
  ];

  const monthNames = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  const optionsApartamentos = {
    chart: {
      type: "column",
      borderRadius: 8,
    },
    title: {
      text: "Gráfico de consumo de água fria do condomínio",
      style: {
        fontFamily: "Roboto, sans-serif",
        fontStyle: "normal",
        fontWeight: 550,
        fontSize: "23px",
      },
    },
    credits: {
      enabled: false, // Remover a marca "highcharts.com"
    },
    xAxis: {
      type: "category",
      lineWidth: 0,
      tickLength: 0,
      labels: {
        rotation: 0,
        style: {
          fontSize: "13px",
          fontFamily: "Roboto, sans-serif",
        },
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "Porcentagem de água",
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      formatter: function () {
        const { point } = this;
        const monthData = data[point.index];
        const monthName2 = monthNames[point.index];
        return `Alertas de vazamento de gás no mês de ${monthName2}: <b>${monthData.alerts}</b>`;
      },
    },
    plotOptions: {
      series: {
        point: {
          events: {
            mouseOver: function () {
              setHoveredMonth(monthNames[this.index]);
            },
            mouseOut: function () {
              setHoveredMonth(null);
            },
          },
        },
        borderRadius: 8,
      },
    },
    series: [
      {
        name: "Alertas Mensais",
        colors: data.map((item) =>
          item.alerts === Math.max(...data.map((item) => item.alerts))
            ? "#48CAE4"
            : "#48CAE4"
        ),
        colorByPoint: true,
        groupPadding: 0,
        data: data.map((item) => [item.month, item.alerts]),
        dataLabels: {
          enabled: true,
          rotation: -90,
          color: "#FFFFFF",
          align: "right",
          format: "{point.y:.0f}",
          y: 10,
          style: {
            fontSize: "13px",
            fontFamily: "Roboto, sans-serif",
          },
        },
      },
    ],
  };
  const data2 = [
    { month: "Jan", alerts: 30 },
    { month: "Fev", alerts: 33 },
    { month: "Mar", alerts: 15 },
    { month: "Abr", alerts: 18 },
    { month: "Mai", alerts: 20 },
    { month: "Jun", alerts: 19 },
    { month: "Jul", alerts: 17 },
    { month: "Ago", alerts: 5 },
    { month: "Set", alerts: 19 },
    { month: "Out", alerts: 23 },
    { month: "Nov", alerts: 27 },
    { month: "Dez", alerts: 16 },
  ];

  const monthNames2 = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  const optionsApartamentos2 = {
    chart: {
      type: "column",
      borderRadius: 8,
    },
    title: {
      text: "Gráfico de consumo de água quente",
      style: {
        fontFamily: "Roboto, sans-serif",
        fontStyle: "normal",
        fontWeight: 550,
        fontSize: "23px",
      },
    },
    credits: {
      enabled: false, // Remover a marca "highcharts.com"
    },
    xAxis: {
      type: "category",
      lineWidth: 0,
      tickLength: 0,
      labels: {
        rotation: 0,
        style: {
          fontSize: "13px",
          fontFamily: "Roboto, sans-serif",
        },
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "Porcentagem de água",
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      formatter: function () {
        const { point } = this;
        const monthData = data[point.index];
        const monthName2 = monthNames2[point.index];
        return `Consumo de água no mês de ${monthName2}: <b>${monthData.alerts}</b>`;
      },
    },
    plotOptions: {
      series: {
        point: {
          events: {
            mouseOver: function () {
              setHoveredMonth(monthNames2[this.index]);
            },
            mouseOut: function () {
              setHoveredMonth(null);
            },
          },
        },
        borderRadius: 8,
      },
    },
    series: [
      {
        name: "Alertas Mensais",
        colors: data2.map((item) =>
          item.alerts === Math.max(...data2.map((item) => item.alerts))
            ? "#023E8A"
            : "#023E8A"
        ),
        colorByPoint: true,
        groupPadding: 0,
        data: data2.map((item) => [item.month, item.alerts]),
        dataLabels: {
          enabled: true,
          rotation: -90,
          color: "#FFFFFF",
          align: "right",
          format: "{point.y:.0f}",
          y: 10,
          style: {
            fontSize: "13px",
            fontFamily: "Roboto, sans-serif",
          },
        },
      },
    ],
  };

  return (
    <div className="main" style={{ flex: 1 }}>
      <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <HeaderHome />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          gap: "1rem",
          padding: "2rem",
          height: "600px", // Defina uma altura fixa aqui
          overflow: "auto"
        }}
      >
        <div style={{ display: "flex", gap: "2rem" }}>
          <Card>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <ReportTitle>Relatório:</ReportTitle>
              <AlertCount>{reportData.alertCount}</AlertCount>
            </div>
            <ReportText1>{reportData.reportText1}</ReportText1>
            <ReportText2>{reportData.reportText2}</ReportText2>
          </Card>

          <CardDispositivos>
            <ReportTitle>Dispositivos Conectados:</ReportTitle>
            <Link
              to={"/dispositivosHidrometro"}
              style={{ textDecoration: "none" }}
            >
              <Dispositivo>{dispositivos.dispositivo1}</Dispositivo>
              <Dispositivo>{dispositivos.dispositivo2}</Dispositivo>
              <Dispositivo>{dispositivos.dispositivo3}</Dispositivo>
              <Dispositivo>{dispositivos.dispositivo4}</Dispositivo>
              <Dispositivo>{dispositivos.dispositivo5}</Dispositivo>
              <Dispositivo>{dispositivos.dispositivo6}</Dispositivo>
              <Dispositivo>{dispositivos.dispositivo7}</Dispositivo>
              <Dispositivo>{dispositivos.dispositivo8}</Dispositivo>
              <Dispositivo>{dispositivos.dispositivo9}</Dispositivo>
            </Link>
          </CardDispositivos>
        </div>

        <div style={{ display: "flex", gap: "2rem" }}>
          <ChartWrapper>
            <Chart>
              <HighchartsReact
                highcharts={Highcharts}
                options={optionsApartamentos}
              />
            </Chart>
          </ChartWrapper>
          <ChartWrapper>
            <Chart>
              <HighchartsReact
                highcharts={Highcharts}
                options={optionsApartamentos2}
              />
            </Chart>
          </ChartWrapper>
        </div>
      </div>
    </div>
  );
};

export default Hidrometro;
