import "@fontsource/roboto";
import { style } from "@mui/system";
import styled from "styled-components";

// Estilos para a área de dispositivos
export const DevicesArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: scroll;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    width: 0;
    background-color: transparent;
  }
`;

// Estilos para cada card de dispositivo
export const DeviceCard = styled.div`
  width: 171px;
  height: 165px;
  background: #fafafa;
  box-shadow: 0px 1px 9px -7px rgb(49 49 49);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;

  // Estilos da imagem
  img {
    width: 89.24px;
    height: 82px;
  }

  // Estilos quando o card é selecionado
  &.selected {
    border-color: #7cabe5;
  }
`;
export const Title = styled.h3`
  font-family: "Roboto";
  font-size: 15px;
  font-weight: 400;
`;

export const StatusCard = styled.div`
  background: white;
  border-radius: 10px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const StatusTitle = styled.h3`
  font-family: "Roboto";
  font-weight: 100;
  font-size: 23px;
  text-align: left;
  margin: 0;
`;

export const RowDot = styled.div`
  display: flex;
  position: relative;
  justify-content: space-evenly;
`;

export const StatusDot = styled.span`
  font-family: "Roboto";
  width: 72px;
  height: 72px;
  border-radius: 999px;
  background-color: ${(props) => props.color};
  text-align: center;
  font-size: 30px;
  position: relative;
`;

export const DotTitle = styled.p`
  font-size: 20px;
  font-weight: 400;
  margin: 0;
  align-self: center;
`;

export const DotWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const StatusCard2 = styled.div`
  background: white;
  border-radius: 10px;
  padding: 1rem;
  flex-basis: 20%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const StatusTitle2 = styled.h3`
  font-family: "Roboto";
  font-weight: 100;
  font-size: 23px;
  text-align: left;
  margin: 0;
`;

export const RowDot2 = styled.div`
  display: flex;
  position: relative;
  justify-content: space-evenly;
`;

export const StatusDot2 = styled.span`
  font-family: "Roboto";
  width: 72px;
  height: 72px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  text-align: center;
  font-size: 30px;
  position: relative;
`;

export const DotTitle2 = styled.p`
  font-size: 20px;
  font-weight: 400;
  margin: 0;
  align-self: center;
`;

export const DotWrapper2 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const SwitchCard = styled.div`
  display: flex;
  align-items: center;
  background: white;
  box-shadow: 0 1px 11px -7px #404040;
  border-radius: .5rem;
  justify-content: center;
  padding: 1rem;
`;

export const SwitchContainer = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  width: 82px;
  height: 36px;
`;

export const SwitchInput = styled.input`
  display: none;

  &:checked + span:before {
    transform: translateX(46px);
    background-color: #8ef78e;
  }
`;

export const SwitchSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #353535;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 36px;

  &:before {
    position: absolute;
    content: "";
    height: 28px;
    width: 28px;
    left: 4px;
    bottom: 4px;
    background-color: #d74747;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }

  &:hover:before {
    background-color: #d74747;
  }
`;

export const SwitchText = styled.span`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 15px;
  text-align: center;
  color: #000000;
  margin-left: -90px;
`;

// Card 1
export const Card1 = styled.div`
  flex: 1;
  padding: 20px;
  background: white;
  border-radius: 10px;
`;

export const LogText = styled.div`
  width: 5px;
  height: 27px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  font-size: 23px;
  line-height: 27px;
  text-align: center;
  color: #000000;
`;

export const StatusText = styled.div`
  width: 270px;
  height: 15px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 15px;
  text-align: left;
  color: #000000;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const AlarmText = styled.div`
  width: 212px;
  height: 15px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 15px;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const IntruderText = styled.div`
  width: 232px;
  height: 15px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 15px;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const FailureText = styled.div`
  width: 212px;
  height: 15px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 15px;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const Card2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: .6;
  border-radius: 10px;
`;

export const ReportTitle = styled.h1`
  width: 305px;
  height: 27px;
  font-family: "Roboto";
  font-weight: bold;
  font-size: 23px;
  color: #000000;
  text-align: center;
`;

export const InnerCircle = styled.div`
  display: flex;
  width: 300px;
  height: 300px;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
`;

export const ThirdCircle = styled.div`
  display: flex;
  width: 200px;
  height: 200px;
  align-items: center;
  justify-content: center;
  background: #242424;
  border-radius: 50%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const TextWrapper = styled.div`
  width: 105px;
  height: 47px;
  font-family: 'Righteous';
  font-style: normal;
  font-weight: 400;
  font-size: 45px;
  line-height: 40px;
  text-align: center;
  color: white;

`;
