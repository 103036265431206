import styled from "styled-components";

export const Nav = styled.div`
  min-height: 80px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #fff;
  padding: 0 1rem 0 0;
  border-bottom: 1px solid #eee;
  box-shadow: 0px 3px 9px -13px rgb(49 49 49);
`;

export const Notification = styled.div`
  border-radius: 999px;
  background-color: #004d93;
  width: 35px;
  height: 35px;
  margin: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const ContainerConta = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
