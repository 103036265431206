import React, { useState, useEffect, useRef } from "react";
import Menu from "../../../Menu";
import HeaderTitle from "../../../Header/HeaderTitulo";
import ReactApexChart from "react-apexcharts";
import statusData from "./statusData.json";
import pressaoData from "./pressãoData.json";
import reportData from "./reportData.json";
import temperaturaData from "./temperaturaData.json";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";

import {
  Card1,
  Card2,
  Card3,
  Card4,
  Title1,
  Title2,
  DotWrapper2,
  DotTitle2,
  StatusDot2,
  RowDot2,
  StatusText,
  TitlePressao,
  ChartPressao,
  InnerCircle,
  ThirdCircle,
  TextWrapper,
  Reports,
  Circle,
  Circle2,
  InnerCircle2,
  ThirdCircle2,
  TextWrapper2,
  Temp,
} from "./styles";
import { data } from "../../../Dispositivos/dispositivos";

const QuadroBomba = () => {
  const data = {
    temperaturas: ["99", "105", "133"],
    titles: ["Bomba n°1", "Bomba n°2", "Bomba n°3"],
  };

  const CircleWithNumber = () => {
    const getCircleColor = (temp) => {
      if (parseFloat(temp) > 120) {
        return "red"; // Cor de alerta
      } else if (parseFloat(temp) > 100) {
        return "orange"; // Cor secundária
      } else {
        return "lime"; // Cor normal
      }
    };

    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex" }}>
          {data.temperaturas.map((temp, index) => (
            <Circle2
              key={index}
              style={{
                borderColor: getCircleColor(temp),
                margin: "10px",
                flexBasis: "100%",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              }}
            >
              <Temp>{temp}°C</Temp>
            </Circle2>
          ))}
        </div>
        <div style={{ display: "flex" }}>
          {data.titles.map((title, index) => (
            <Title1
              key={index}
              style={{ flexBasis: "100%", justifyContent: "space-evenly" }}
            >
              {title}
            </Title1>
          ))}
        </div>
      </div>
    );
  };

  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(!isClicked);
  };

  const [alert, setAlert] = useState(false);

  const handleAlertSignal = () => {
    setAlert(!alert);
    // Aqui você pode adicionar lógica adicional, como exibir uma mensagem de alerta ou executar outras ações necessárias
  };

  // Lógica para definir as cores com base no estado de alerta

  const alertColor = alert ? "#FF0000" : "#d74747";
  const normalColor = alert ? "#58d958" : "#8ef78e";

  const IconComponent = () => {
    const [isActive, setIsActive] = useState(false);

    const handleClick = () => {
      setIsActive(!isActive);
    };

    // Certifique-se de que `data.titles` tenha apenas um elemento
    const title = data.titles[0];

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: "3rem",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row", gap: "3rem" }}>
          {data.titles.map((titles, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Circle active={isActive}>
                  <PowerSettingsNewIcon
                    style={{ fontSize: "50px" }}
                    onClick={handleClick}
                  />
                </Circle>
                <Title1>{titles}</Title1>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const series = [
    {
      name: "Bomba1",
      data: [160, 580, 506, 620, 690, 670, 550],
      color: "#00B4D8",
    },
    {
      name: "Bomba2",
      data: [670, 640, 530, 520, 650, 410, 650],
      color: "#006556",
    },
    {
      name: "Bomba3",
      data: [700, 320, 580, 600, 490, 370, 650],
      color: "#00A386",
    },
  ];

  const options = {
    chart: {
      height: 245,
      type: "line",
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0.5,
      },
    },
    markers: {
      size: 1,
    },
    xaxis: {
      categories: [10, 20, 30, 40, 50, 60, 70],
      title: {
        text: "Temperatura (ºC)",
        style: {
          //   fontSize: "13px",
          fontFamily: "Roboto",
        },
      },
    },
    yaxis: {
      min: 100,
      max: 700,
      title: {
        text: "Pressão",
        style: {
          //   fontSize: "13px",
          fontFamily: "Roboto",
        },
      },
    },
  };

  



  return ( 
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          gap: "1rem",
        }}
      >
        <HeaderTitle title="Quadro de Bombas" />
        <div
          style={{
            display: "flex",
            flexBasis: "100%",
            gap: "1rem",
            flexDirection: "row",
          }}
        >
          <Card1>
            <Title1>Funcionamento das Bombas d’água</Title1>
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                padding: "1rem",
              }}
            >
              <div style={{ marginRight: "100px", width: "450px" }}>
                <ReactApexChart
                  options={options}
                  series={series}
                  type="line"
                  height={"100%"}
                  width={"100%"}
                />
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div>
                  <RowDot2>
                    <DotWrapper2
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <DotTitle2>Desativado</DotTitle2>
                      <StatusDot2 color={alertColor} label="Alerta" />
                    </DotWrapper2>
                    <DotWrapper2
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <DotTitle2>Ativado</DotTitle2>
                      <StatusDot2 color={normalColor} label="Normal" />
                    </DotWrapper2>
                  </RowDot2>
                </div>
                <div>
                  <StatusText>
                    {statusData.bombas.title}{" "}
                    <span style={{ color: statusData.bombas.color }}>
                      {statusData.bombas.status}
                    </span>
                  </StatusText>
                  <StatusText>
                    {statusData.defeito.title}{" "}
                    <span style={{ color: statusData.defeito.color }}>
                      {statusData.defeito.status}
                    </span>
                  </StatusText>
                  <StatusText>
                    {statusData.abastecimento.title}{" "}
                    <span style={{ color: statusData.abastecimento.color }}>
                      {statusData.abastecimento.status}
                    </span>
                  </StatusText>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <h3
                    style={{
                      fontFamily: "Roboto",
                      marginBottom: "0.5px",
                      marginTop: "40px",
                    }}
                  >
                    Pressão das bombas
                  </h3>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <ChartPressao>
                      <TitlePressao>
                        {pressaoData.bomba1.title}
                        {""}
                      </TitlePressao>
                      <InnerCircle
                        style={{ backgroundColor: pressaoData.bomba1.color }}
                      >
                        <ThirdCircle>
                          <TextWrapper>{pressaoData.bomba1.number}</TextWrapper>
                        </ThirdCircle>
                      </InnerCircle>
                    </ChartPressao>
                    <ChartPressao>
                      <TitlePressao>
                        {pressaoData.bomba2.title}
                        {""}
                      </TitlePressao>
                      <InnerCircle
                        style={{ backgroundColor: pressaoData.bomba2.color }}
                      >
                        <ThirdCircle>
                          <TextWrapper>{pressaoData.bomba2.number}</TextWrapper>
                        </ThirdCircle>
                      </InnerCircle>
                    </ChartPressao>
                    <ChartPressao>
                      <TitlePressao>
                        {pressaoData.bomba3.title}
                        {""}
                      </TitlePressao>
                      <InnerCircle
                        style={{ backgroundColor: pressaoData.bomba3.color }}
                      >
                        <ThirdCircle>
                          <TextWrapper>{pressaoData.bomba3.number}</TextWrapper>
                        </ThirdCircle>
                      </InnerCircle>
                    </ChartPressao>
                  </div>
                </div>
              </div>
            </div>
          </Card1>
          <Card3>
            <div>
              <Title1>Relatório:</Title1>
              {reportData.reports.map((reports, index) => (
                <Reports key={index}>{reports}</Reports>
              ))}
            </div>
          </Card3>
        </div>
        <div
          style={{
            display: "flex",
            flexBasis: "100%",
            gap: "1rem",
            flexDirection: "row",
          }}
        >
          <Card2>
            <div>
              <Title1>Controle de acionamento e Velocidade das Bombas:</Title1>
              <div>
                <IconComponent />
              </div>
            </div>
          </Card2>
          <Card4>
            <Title1>Controle de Temperatura das Bombas:</Title1>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginTop: "30px",
              }}
            >
              <CircleWithNumber />
            </div>
            "
          </Card4>
        </div>
      </div>
  );
};

export default QuadroBomba;
