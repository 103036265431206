import React, { useState } from "react";
import Menu from "../../../Menu";
import HeaderDispositivoHidrometro from "../../../Header/HeaderDipositivosHidrometro";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import reportData from "./reportData.json";
import ReactApexChart from "react-apexcharts";
import ApexCharts from "apexcharts";
import {
    DivInferior,
    DivSuperior,
    Chart,
    ChartWrapper,
    Card,
    ReportTitle,
    ReportText1,
    ReportText2,
    ReportText3,
    DivPai,
    ChartContainer,
    ChartTitle
} from "./styles";

const DispositivosHidrometro = () => {

    const [hoveredMonth, setHoveredMonth] = useState(null);

    const data = [
        { month: "Jan", alerts: 37 },
        { month: "Fev", alerts: 31 },
        { month: "Mar", alerts: 27 },
        { month: "Abr", alerts: 22 },
        { month: "Mai", alerts: 21 },
        { month: "Jun", alerts: 21 },
        { month: "Jul", alerts: 21 },
        { month: "Ago", alerts: 3 },
        { month: "Set", alerts: 20 },
        { month: "Out", alerts: 19 },
        { month: "Nov", alerts: 16 },
        { month: "Dez", alerts: 16 },
    ];

    const monthNames = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
    ];

    const optionsApartamentos = {
        chart: {
            type: "column",
            borderRadius: 8,
        },
        title: {
            text: "Gráfico de consumo de água fria do condomínio",
            style: {
                fontFamily: "Roboto, sans-serif",
                fontStyle: "normal",
                fontWeight: 550,
                fontSize: "23px",
            },
        },
        credits: {
            enabled: false, // Remover a marca "highcharts.com"
        },
        xAxis: {
            type: "category",
            lineWidth: 0,
            tickLength: 0,
            labels: {
                rotation: 0,
                style: {
                    fontSize: "13px",
                    fontFamily: "Roboto, sans-serif",
                },
            },
        },
        yAxis: {
            min: 0,
            title: {
                text: "Porcentagem de água",
            },
        },
        legend: {
            enabled: false,
        },
        tooltip: {
            formatter: function () {
                const { point } = this;
                const monthData = data[point.index];
                const monthName2 = monthNames[point.index];
                return `Alertas de vazamento de gás no mês de ${monthName2}: <b>${monthData.alerts}</b>`;
            },
        },
        plotOptions: {
            series: {
                point: {
                    events: {
                        mouseOver: function () {
                            setHoveredMonth(monthNames[this.index]);
                        },
                        mouseOut: function () {
                            setHoveredMonth(null);
                        },
                    },
                },
                borderRadius: 8,
            },
        },
        series: [
            {
                name: "Alertas Mensais",
                colors: data.map((item) =>
                    item.alerts === Math.max(...data.map((item) => item.alerts))
                        ? "#48CAE4"
                        : "#E9E9E9"
                ),
                colorByPoint: true,
                groupPadding: 0,
                data: data.map((item) => [item.month, item.alerts]),
                dataLabels: {
                    enabled: true,
                    rotation: -90,
                    color: "#FFFFFF",
                    align: "right",
                    format: "{point.y:.0f}",
                    y: 10,
                    style: {
                        fontSize: "13px",
                        fontFamily: "Roboto, sans-serif",
                    },
                },
            },
        ],
    };
    const data2 = [
        { month: "Jan", alerts: 30 },
        { month: "Fev", alerts: 33 },
        { month: "Mar", alerts: 15 },
        { month: "Abr", alerts: 18 },
        { month: "Mai", alerts: 20 },
        { month: "Jun", alerts: 19 },
        { month: "Jul", alerts: 17 },
        { month: "Ago", alerts: 5 },
        { month: "Set", alerts: 19 },
        { month: "Out", alerts: 23 },
        { month: "Nov", alerts: 27 },
        { month: "Dez", alerts: 16 },
    ];

    const monthNames2 = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
    ];

    const optionsApartamentos2 = {
        chart: {
            type: "column",
            borderRadius: 8,
        },
        title: {
            text: "Gráfico de consumo de água quente",
            style: {
                fontFamily: "Roboto, sans-serif",
                fontStyle: "normal",
                fontWeight: 550,
                fontSize: "23px",
            },
        },
        credits: {
            enabled: false, // Remover a marca "highcharts.com"
        },
        xAxis: {
            type: "category",
            lineWidth: 0,
            tickLength: 0,
            labels: {
                rotation: 0,
                style: {
                    fontSize: "13px",
                    fontFamily: "Roboto, sans-serif",
                },
            },
        },
        yAxis: {
            min: 0,
            title: {
                text: "Porcentagem de água",
            },
        },
        legend: {
            enabled: false,
        },
        tooltip: {
            formatter: function () {
                const { point } = this;
                const monthData = data[point.index];
                const monthName2 = monthNames2[point.index];
                return `Consumo de água no mês de ${monthName2}: <b>${monthData.alerts}</b>`;
            },
        },
        plotOptions: {
            series: {
                point: {
                    events: {
                        mouseOver: function () {
                            setHoveredMonth(monthNames2[this.index]);
                        },
                        mouseOut: function () {
                            setHoveredMonth(null);
                        },
                    },
                },
                borderRadius: 8,
            },
        },
        series: [
            {
                name: "Alertas Mensais",
                colors: data2.map((item) =>
                    item.alerts === Math.max(...data2.map((item) => item.alerts))
                        ? "#0077B6"
                        : "#E9E9E9"
                ),
                colorByPoint: true,
                groupPadding: 0,
                data: data2.map((item) => [item.month, item.alerts]),
                dataLabels: {
                    enabled: true,
                    rotation: -90,
                    color: "#FFFFFF",
                    align: "right",
                    format: "{point.y:.0f}",
                    y: 10,
                    style: {
                        fontSize: "13px",
                        fontFamily: "Roboto, sans-serif",
                    },
                },
            },
        ],
    };


    const series = [
        {
            name: "Consumo",
            data: [16, 18, 20, 22, 19, 17, 25],
            color: "#DB3030",
        },
    ];

    const options = {
        chart: {
            height: 245,
            type: "line",
            dropShadow: {
                enabled: true,
                color: "#000",
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2,
            },
            toolbar: {
                show: false,
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: "smooth",
        },
        grid: {
            borderColor: "#e7e7e7",
            row: {
                colors: ["#f3f3f3", "transparent"],
                opacity: 0.5,
            },
        },
        markers: {
            size: 1,
        },
        xaxis: {
            categories: ["08h", "10h", "13h", "15h", "18h", "20h", "24h"],
        },
        yaxis: {
            min: 5,
            max: 40,
        },
    };
   

    return (

        <div className="main">
            <Menu />
            <HeaderDispositivoHidrometro title={"Dispositivo Selecionado"} />
            <DivPai>
                <DivSuperior>
                    <ChartWrapper>
                        <Chart>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={optionsApartamentos}
                            />
                        </Chart>
                    </ChartWrapper>
                    <Card>
                        <ReportTitle>Relatório:</ReportTitle>
                        <ReportText1>{reportData.reportText1}</ReportText1>
                        <ReportText2>{reportData.reportText2}</ReportText2>
                        <ReportText3>{reportData.reportText3}</ReportText3>
                    </Card>
                </DivSuperior>
                <DivInferior>
                    <ChartWrapper>
                        <Chart>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={optionsApartamentos2}
                            />
                        </Chart>
                    </ChartWrapper>
                    <ChartContainer>
                        <ChartTitle>Gráfico de consumo de água por hora</ChartTitle>
                        <ReactApexChart
                            options={options}
                            series={series}
                            type="line"
                            height={323}
                            width={350}
                        />
                    </ChartContainer>
                    <ChartContainer>
                        <ChartTitle>Gráfico de consumo de água por dia</ChartTitle>
                        <ReactApexChart
                            options={options}
                            series={series}
                            type="line"
                            height={323}
                            width={350}
                        />
                    </ChartContainer>
                </DivInferior>
            </DivPai>
        </div>
    )
}

export default DispositivosHidrometro;