import { } from "@mui/material";
import styled from "styled-components";
import '@fontsource/roboto';
import NotificationsIcon from "@mui/icons-material/Notifications";

export const Title = styled.h3`
font-family: 'Roboto', sans-serif;
`

export const Nav = styled.div`
  // border-bottom: 1px solid #d2d2d2;
  box-shadow: 0px 1px 9px -7px rgb(49 49 49);
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-left: 240px; */
  padding-left:30px;
  background-color: white;
`;
export const cIcon = styled(NotificationsIcon).attrs({
    size : 35,
    color : "white",
    padding : 10,

})`

`;

export const Notification = styled.div`
  border-radius: 50%;
  background-color: #004d93;
  width: 6vh;
  margin: 15px;
  position: relative;
  cursor: pointer;
`;

export const NotificationCard = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  width: 300px;
  padding: 10px;
  margin-right: 15px;
  margin-top: 5px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  overflow-y: auto;
  max-height: 300px;
  z-index: 1000;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    background-color: transparent;
  }
`;

export const NotificationItem = styled.div`
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  cursor: pointer;
  font-family:" Roboto", sans-serif;

  &:hover {
    background-color: #f0f0f0;
  }
`;

export const HoverableH4 = styled.h4`
  font-family: 'Roboto', sans-serif;
  color: #0056A3;
  height: 60px;
  align-items: center;
  justify-content: center;
  display: flex;
  line-height: 60px;

  &:hover {
    cursor: pointer;
    border-radius: 7px;
    background-color: rgba(0, 86, 163, 0.2);
    backdrop-filter: blur(5px);
  }
`;

