import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "../Home";
import LoginScreen from "../LoginScreen";
import HomeApartamentos from "../HomeApartamentos";
import Notification from "../Notifications";
import HomeAreas from "../HomeAreasComuns";
import HID from "../Apartamentos/HIDPorta";
import SensorGas from "../Apartamentos/SensorGas/index";
import TecladoPorta from "../Apartamentos/TecladoPorta";
import SensorPerimetro from "../AreasComuns/SensorPerimetral";
import SensorNivelAgua from "../AreasComuns/SensorNivelAgua";
import SensorCo from "../AreasComuns/SensoresCO";
import BotaoPNE from "../AreasComuns/BotaoPNE/index";
import Hidrometro from "../Apartamentos/Hidrometro";
import AddDevices from "../AdicionarDispositivos";
import QuadrosEletricos from "../AreasComuns/DINQuadro";
import DispositivosHidrometro from "../Apartamentos/Hidrometro/DispositivosHidrometro";
import QuadroIluminacao from "../AreasComuns/DINQuadro/QuadroIluminacao";
import QuadroIncendio from "../AreasComuns/DINQuadro/QuadroIncendio";
import QuadroBomba from "../AreasComuns/DINQuadro/QuadroBomba";
import QuadroPressurizacao from "../AreasComuns/DINQuadro/QuadroPressurizacao";
import Configuracao from "../Configuracao";
import ConfigDispositivos from "../Configuracao/Dispositivos";
import QuadroExaustao from "../AreasComuns/DINQuadro/QuadroExaustao";

const RoutesApp = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<LoginScreen />} />
      <Route path="/apartamentos" element={<HomeApartamentos />} />
      <Route path="/notificacoes" element={<Notification />} />
      <Route path="/areascomuns" element={<HomeAreas />} />
      <Route path="/hid" element={<HID />} />
      <Route path="/sensorgas" element={<SensorGas />} />
      <Route path="/tecladoporta" element={<TecladoPorta />} />
      <Route path="/sensorperimetro" element={<SensorPerimetro />} />
      <Route path="/sensornivelagua" element={<SensorNivelAgua />} />
      <Route path="/sensorco" element={<SensorCo />} />
      <Route path="/botaopne" element={<BotaoPNE />} />
      <Route path="/hidrometro" element={<Hidrometro />} />
      <Route path="/adddevices" element={<AddDevices />} />
      <Route path="/quadroeletrico" element={<QuadrosEletricos />} />
      <Route
        path="/dispositivosHidrometro"
        element={<DispositivosHidrometro />}
      />
      <Route path="/quadroiluminacao" element={<QuadroIluminacao />} />
      <Route path="/quadroincendio" element={<QuadroIncendio />} />
      <Route
        path="/dispositivosHidrometro"
        element={<DispositivosHidrometro />}
      />
      <Route path="/quadrobomba" element={<QuadroBomba />} />
      <Route path="/quadropressurizacao" element={<QuadroPressurizacao />} />
      <Route path="/quadroexaustao" element={<QuadroExaustao />} />
      <Route path="/Configuracao" element={<Configuracao />} />
      <Route path="/ConfigDispositivos" element={<ConfigDispositivos />} />
    </Routes>
  );
};

export default RoutesApp;
